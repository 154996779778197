import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AccordionCard } from '@sobrus-com/sobrus-design-system-v2';
import { format, parseISO } from 'date-fns';

import EventCard from '../../components/eventCard/EventCard';

function EventAccordion({ eventsComing, eventsReported, eventsVisited }) {
	const [showMore, setShowMore] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<>
			<AccordionCard
				onClick={() => setShowMore(false)}
				headerTitle={
					<div>
						<span style={{ color: 'gray', fontWeight: '400', width: 25, display: 'inline-block' }}>
							{eventsComing?.length || 0}
						</span>{' '}
						{t('Scheduled')}
					</div>
				}
				style={{ marginBottom: 10 }}
				// tag={eventsComing?.length || 0}
			>
				{eventsComing?.map((ele, i) =>
					showMore ? (
						<EventCard
							key={i}
							startTime={format(parseISO(ele.startDate), 'HH:mm')}
							endTime={format(parseISO(ele.endDate), 'HH:mm')}
							eventType={ele.status}
							eventTitle={ele.name}
							onClick={() => navigate(`/calendar/details/${ele.id}`)}
						/>
					) : i < 3 ? (
						<EventCard
							key={i}
							startTime={format(parseISO(ele.startDate), 'HH:mm')}
							endTime={format(parseISO(ele.endDate), 'HH:mm')}
							eventType={ele.status}
							eventTitle={ele.name}
							onClick={() => navigate(`/calendar/details/${ele.id}`)}
						/>
					) : null
				)}
				{!showMore && eventsComing?.length > 3 && (
					<span
						className="event-show-more"
						onClick={(e) => {
							e.stopPropagation();
							setShowMore(true);
						}}
						role="presentation"
					>
						+ {t('Show more')} ({eventsComing.length - 3})
					</span>
				)}
			</AccordionCard>
			<AccordionCard
				headerTitle={
					<div>
						<span style={{ color: 'gray', fontWeight: '400', width: 25, display: 'inline-block' }}>
							{eventsVisited?.length || 0}
						</span>{' '}
						{t('Achieved')}
					</div>
				}
				style={{ marginBottom: 10 }}
			>
				{eventsVisited?.map((ele, i) =>
					showMore ? (
						<EventCard
							key={i}
							startTime={format(parseISO(ele.startDate), 'HH:mm')}
							endTime={format(parseISO(ele.endDate), 'HH:mm')}
							eventType={ele.status}
							eventTitle={ele.name}
							onClick={() => navigate(`/calendar/details/${ele.id}`)}
						/>
					) : i < 3 ? (
						<EventCard
							key={i}
							startTime={format(parseISO(ele.startDate), 'HH:mm')}
							endTime={format(parseISO(ele.endDate), 'HH:mm')}
							eventType={ele.status}
							eventTitle={ele.name}
							onClick={() => navigate(`/calendar/details/${ele.id}`)}
						/>
					) : null
				)}
				{!showMore && eventsVisited?.length > 3 && (
					<span
						className="event-show-more"
						onClick={(e) => {
							e.stopPropagation();
							setShowMore(true);
						}}
						role="presentation"
					>
						+ {t('Show more')} ({eventsVisited.length - 3})
					</span>
				)}
			</AccordionCard>
			<AccordionCard
				headerTitle={
					<div>
						<span style={{ color: 'gray', fontWeight: '400', width: 25, display: 'inline-block' }}>
							{eventsReported?.length || 0}
						</span>{' '}
						{t('Postponed')}
					</div>
				}
			>
				{eventsReported?.map((ele, i) =>
					showMore ? (
						<EventCard
							key={i}
							startTime={format(parseISO(ele.startDate), 'HH:mm')}
							endTime={format(parseISO(ele.endDate), 'HH:mm')}
							eventType={ele.status}
							eventTitle={ele.name}
							onClick={() => navigate(`/calendar/details/${ele.id}`)}
						/>
					) : i < 3 ? (
						<EventCard
							key={i}
							startTime={format(parseISO(ele.startDate), 'HH:mm')}
							endTime={format(parseISO(ele.endDate), 'HH:mm')}
							eventType={ele.status}
							eventTitle={ele.name}
							onClick={() => navigate(`/calendar/details/${ele.id}`)}
						/>
					) : null
				)}
				{!showMore && eventsReported?.length > 3 && (
					<span
						className="event-show-more"
						onClick={(e) => {
							e.stopPropagation();
							setShowMore(true);
						}}
						role="presentation"
					>
						+ {t('Show more')} ({eventsReported.length - 3})
					</span>
				)}
			</AccordionCard>
		</>
	);
}

export default EventAccordion;
