export const Navigation = (acl, t) => [
	{
		to: '/',
		itemTitle: t('Nav.Home'),
		does: true,
	},
	{
		to: '/products',
		itemTitle: t('Nav.Products'),
		does: acl?.product.list,
	},
	{
		to: ['/ressources/pharmacies', '/ressources/centers', '/ressources/associations'],
		itemTitle: t('Nav.Resources'),
		acls: [
			acl?.resource.list_pharmacies,
			acl?.resource.list_associations,
			acl?.resource.list_healthcareestablishments,
		],
		does:
			acl?.resource.list_pharmacies ||
			acl?.resource.list_associations ||
			acl?.resource.list_healthcareestablishments,
		dropDown: [
			{
				to: '/ressources/pharmacies',
				itemTitle: t('Nav.Points of sale'),
				does: acl?.resource.list_pharmacies,
			},
			{
				to: '/ressources/centers',
				itemTitle: t('Nav.Healthcare facilities'),
				does: acl?.resource.list_associations,
			},
			{
				to: '/ressources/associations',
				itemTitle: t('Nav.Associations'),
				does: acl?.resource.list_healthcareestablishments,
			},
		],
	},
	{
		to: '/accounts',
		itemTitle: t('Nav.Accounts'),
		does: acl?.account.list,
	},
	{
		to: '/contacts',
		itemTitle: t('Nav.Contacts'),
		does: acl?.contact.list,
	},
	{
		to: '/requests',
		itemTitle: t('Nav.Requests'),
		does: acl?.demand?.list,
	},
	{
		to: ['/loyalty/programs', '/loyalty/memberships'],
		itemTitle: t('Nav.Loyalty'),
		acls: [true, true],
		does: acl?.sales?.offers?.list || acl?.sales?.orders?.list || acl?.sales?.stores?.list,
		dropDown: [
			{
				to: '/loyalty/programs',
				itemTitle: t('Nav.Programs'),
				does: acl?.sales?.offers?.list,
			},
			{
				to: '/loyalty/memberships',
				itemTitle: t('Nav.Memberships'),
				does: acl?.sales?.orders?.list,
			},
		],
	},
	{
		to: '/campaigns',
		itemTitle: t('Nav.Publications'),
		does: acl?.publication?.list,
	},
	{
		to: ['/offers', '/orders', '/custom-stores'],
		itemTitle: t('Nav.Sales'),
		acls: [true, true],
		does: acl?.sales?.offers?.list || acl?.sales?.orders?.list || acl?.sales?.stores?.list,
		dropDown: [
			{
				to: '/offers',
				itemTitle: t('Nav.Offers'),
				does: acl?.sales?.offers?.list,
			},
			{
				to: '/orders',
				itemTitle: t('Nav.Purchase orders'),
				does: acl?.sales?.orders?.list,
			},
			{
				to: '/custom-stores',
				itemTitle: 'Ma boutique',
				does: acl?.sales?.stores?.list,
			},
		],
	},
	{
		to: '/planning',
		itemTitle: t('Nav.Planning'),
		does: acl?.planning?.list,
	},
	{
		to: '/gallery',
		itemTitle: t('Nav.Gallery'),
		does: acl?.gallery?.list,
	},
	{
		to: '/calendar',
		itemTitle: t('Nav.Calendar'),
		does: acl?.calender?.list,
	},
	{
		to: '/reports',
		itemTitle: t('Nav.Reports'),
		does: true,
	},
	{
		to: '/settings',
		itemTitle: t('Nav.Settings'),
		does: true,
	},
];
