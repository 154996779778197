import { Button, Card } from '@sobrus-com/sobrus-design-system-v2';
import React from 'react';
import { Link } from 'react-router-dom';

const NotAuthorised = () => {
	return (
		<section className="labs-container" style={{ padding: 24 }}>
			<Card style={{ paddingTop: 40, paddingBottom: 40 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						color: '#0D2339',
					}}
				>
					<svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M30.9534 28.209C30.9534 28.209 49.78 39.6268 73.5304 11.8185C94.6229 -12.8774 117.558 19.9282 119.495 36.6294C122.004 58.264 100.22 78.1816 113.232 91.1464C126.244 104.111 94.5352 131.349 73.8887 113.045C48.205 90.2762 44.2012 111.501 31.1159 112.935C21.7244 113.964 -0.0979531 92.5169 11.0325 73.5628C20.3987 57.6132 13.8952 53.3729 10.45 48.5766C5.48039 41.6594 13.235 20.5566 30.9534 28.209Z"
							fill="url(#paint0_linear_8600_85771)"
						/>
						<path
							d="M63.6289 41C82.9587 41 98.6289 56.67 98.6289 76C98.6289 95.3298 82.9587 111 63.6289 111C44.2989 111 28.6289 95.3298 28.6289 76C28.6289 56.67 44.2989 41 63.6289 41ZM86.4395 56.9008L44.5297 98.8105C49.6973 103.142 56.3586 105.75 63.6289 105.75C80.0593 105.75 93.3789 92.4304 93.3789 76C93.3789 68.7297 90.7711 62.0684 86.4395 56.9008ZM63.6289 46.25C47.1984 46.25 33.8789 59.5695 33.8789 76C33.8789 83.2702 36.4869 89.9314 40.8183 95.0991L82.7281 53.1894C77.5603 48.858 70.8991 46.25 63.6289 46.25Z"
							fill="#CC3244"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_8600_85771"
								x1="57.7909"
								y1="6.32851"
								x2="70.4044"
								y2="121.455"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#FCE8E7" />
								<stop offset="1" stopColor="#FCE8E7" stopOpacity="0" />
							</linearGradient>
						</defs>
					</svg>
					<h3 style={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>
						L&apos;adresse e-mail avec laquelle vous vous êtes connecté <br />
						n&apos;est pas autorisée à accéder
					</h3>
					<p style={{ textAlign: 'center', marginBottom: 20 }}>
						Si vous pensez que vous devriez avoir accès à Sobrus, veuillez
						<br />
						contacter notre équipe d&apos;assistance pour obtenir de l&apos;aide.
					</p>
					<Link to="/">
						<Button>se connecter avec autre compte</Button>
					</Link>
					<div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{ marginRight: 5 }}
							>
								<path
									d="M3 5.80273C3 4.69816 3.89543 3.80273 5 3.80273H8.27924C8.70967 3.80273 9.09181 4.07816 9.22792 4.48651L10.7257 8.97995C10.8831 9.45206 10.6694 9.96804 10.2243 10.1906L7.96701 11.3192C9.06925 13.764 11.0388 15.7335 13.4835 16.8357L14.6121 14.5785C14.8347 14.1334 15.3507 13.9196 15.8228 14.077L20.3162 15.5748C20.7246 15.7109 21 16.0931 21 16.5235V19.8027C21 20.9073 20.1046 21.8027 19 21.8027H18C9.71573 21.8027 3 15.087 3 6.80273V5.80273Z"
									stroke="#0D2339"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<p>05 30 500 500</p>
						</div>
						<div
							style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 40 }}
						>
							<svg
								width="20"
								height="16"
								viewBox="0 0 20 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{ marginRight: 5 }}
							>
								<path
									d="M1 3.80273L8.8906 9.06313C9.5624 9.511 10.4376 9.511 11.1094 9.06313L19 3.80273M3 14.8027H17C18.1046 14.8027 19 13.9073 19 12.8027V2.80273C19 1.69816 18.1046 0.802734 17 0.802734H3C1.89543 0.802734 1 1.69816 1 2.80273V12.8027C1 13.9073 1.89543 14.8027 3 14.8027Z"
									stroke="#0D2339"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<p>support@sobrus.com</p>
						</div>
					</div>
				</div>
			</Card>
		</section>
	);
};

export default NotAuthorised;
