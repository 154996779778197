import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';

import 'react-step-progress-bar/styles.css';
import './ProgressBar.scss';

function getStepFromQuantity(quantity, minQuantities, steps) {
	// Find the index of the first minQuantity that is greater than or equal to the given quantity
	const index = minQuantities.findIndex((q) => q >= quantity);

	// If the quantity is greater than or equal to the last minQuantity, return the last step
	if (index === -1) {
		return steps[steps.length - 1];
	}

	// Calculate the interval between the current and previous minQuantities and steps
	const minQuantityInterval = minQuantities[index] - minQuantities[index - 1];
	const stepInterval = steps[index] - steps[index - 1];

	// Calculate the step value based on the interval and the given quantity
	const stepFromInterval = ((quantity - minQuantities[index - 1]) * stepInterval) / minQuantityInterval;

	// Return the step value by adding the stepFromInterval to the previous step
	return steps[index - 1] + stepFromInterval;
}

function ProgressBarV2({ value = 0, steps = [0, 20, 50, 100], max = 100, isRemise = true, forToolTip = false }) {
	const transitionStyles = {
		entering: { transform: 'scale(1.2)' },
		entered: { transform: 'scale(1)' },
		exiting: { transform: 'scale(1.2)' },
		exited: { transform: 'scale(1)' },
	};
	// const productSteps = steps.map((st) => (((isRemise ? st?.minQuantity : st?.step) || st) * 100) / max);
	const productSteps = steps.map((st) => (isRemise ? st?.minQuantity : st?.step) || st);
	const productStepsLength = [0, ...productSteps].length; // get the length of the steps
	const STEPS = [0, 25, 50, 75, 100]
		.slice(0, productStepsLength)
		.map((ele, index, arr) => (ele * 100) / arr[arr?.length - 1]); // slice the bar STEPS to match the steps from offer
	value = getStepFromQuantity(value, [0, ...productSteps], STEPS);

	return (
		<div className={`PB-container${!isRemise ? ' purple-container' : ''}`}>
			<ProgressBar stepPositions={STEPS} percent={value}>
				<Step transition="scale">
					{({ accomplished, position, index }) => (
						<div className={`progress-marks ${accomplished ? 'filled' : ''}`}>
							<span className="top-value">
								{0}
								{isRemise ? (forToolTip ? '% D' : '%') : ''}
							</span>
							<span className="bottom-value">{forToolTip ? '0 U' : 0}</span>
						</div>
					)}
				</Step>
				{STEPS.slice(1).map((st, index) => (
					<Step key={index}>
						{({ accomplished, position, index, transitionState }) => (
							<div
								className={`progress-marks ${accomplished ? 'filled' : ''}`}
								style={transitionStyles[transitionState]}
							>
								<span className="top-value" style={steps.length === index ? { right: 0 } : null}>
									{(isRemise
										? `${steps[index - 1]?.discountValue}${forToolTip ? '% D' : '%'}`
										: `${steps[index - 1]?.units}${forToolTip ? ' UG' : ''}`) || position}
									{/* {isRemise ? (forToolTip ? '% Disc' : '%') : ''} */}
								</span>
								<span className="bottom-value" style={steps.length === index ? { right: 0 } : null}>
									{(isRemise
										? `${steps[index - 1]?.minQuantity}${forToolTip ? ' U' : ''}`
										: `${steps[index - 1]?.step}${forToolTip ? ' U' : ''}`) || position}
								</span>
								{steps.length === index && (
									<>
										<div className="top-text">{isRemise ? '(%)' : 'U.G'}</div>
										<div className="bottom-text">U</div>
									</>
								)}
							</div>
						)}
					</Step>
				))}
			</ProgressBar>
		</div>
	);
}

export default React.memo(ProgressBarV2);
