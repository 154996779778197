import {
	AppBar,
	Card,
	CardTitle,
	Container,
	Tab,
	TabPanel,
	Tabs,
	TabsContainer,
} from '@sobrus-com/sobrus-design-system-v2';
import React, { useEffect, useState } from 'react';
import './PubBalance.scss';
import PaymentMethod from './PaymentMethod';
import PubInvoices from './PubInvoices';
import { axiosGet } from 'api/API';

const PubSold = () => {
	const [tabValue, setTabValue] = useState(0);
	const [data, setData] = useState<any>(null);

	useEffect(() => {
		axiosGet('posts/invoices').then((res: any) => {
			setData(res?.data);
		});
	}, []);

	return (
		<>
			<CardTitle>Solde publicité</CardTitle>
			<div className="pub-sold-card">
				<div>
					<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M19.5 4.94238H4.5C3.90326 4.94238 3.33097 5.17944 2.90901 5.60139C2.48705 6.02335 2.25 6.59565 2.25 7.19238V17.6924C2.25 18.2891 2.48705 18.8614 2.90901 19.2834C3.33097 19.7053 3.90326 19.9424 4.5 19.9424H19.5C20.0967 19.9424 20.669 19.7053 21.091 19.2834C21.5129 18.8614 21.75 18.2891 21.75 17.6924V7.19238C21.75 6.59565 21.5129 6.02335 21.091 5.60139C20.669 5.17944 20.0967 4.94238 19.5 4.94238ZM3.75 9.44238H20.25V10.9424H15C14.8011 10.9424 14.6103 11.0214 14.4697 11.1621C14.329 11.3027 14.25 11.4935 14.25 11.6924C14.25 12.2891 14.0129 12.8614 13.591 13.2834C13.169 13.7053 12.5967 13.9424 12 13.9424C11.4033 13.9424 10.831 13.7053 10.409 13.2834C9.98705 12.8614 9.75 12.2891 9.75 11.6924C9.75 11.4935 9.67098 11.3027 9.53033 11.1621C9.38968 11.0214 9.19891 10.9424 9 10.9424H3.75V9.44238ZM4.5 6.44238H19.5C19.6989 6.44238 19.8897 6.5214 20.0303 6.66205C20.171 6.80271 20.25 6.99347 20.25 7.19238V7.94238H3.75V7.19238C3.75 6.99347 3.82902 6.80271 3.96967 6.66205C4.11032 6.5214 4.30109 6.44238 4.5 6.44238ZM19.5 18.4424H4.5C4.30109 18.4424 4.11032 18.3634 3.96967 18.2227C3.82902 18.0821 3.75 17.8913 3.75 17.6924V12.4424H8.325C8.49714 13.2901 8.95705 14.0522 9.62681 14.5997C10.2966 15.1471 11.135 15.4461 12 15.4461C12.865 15.4461 13.7034 15.1471 14.3732 14.5997C15.043 14.0522 15.5029 13.2901 15.675 12.4424H20.25V17.6924C20.25 17.8913 20.171 18.0821 20.0303 18.2227C19.8897 18.3634 19.6989 18.4424 19.5 18.4424Z"
							fill="#198880"
						/>
					</svg>
					<h3>Solde actuel :</h3>
				</div>
				<h2>{data?.balanceAmount ? Number(data.balanceAmount).toFixed(2) : 0} MAD</h2>
			</div>
			<TabsContainer style={{ marginTop: 16 }}>
				<AppBar>
					<Tabs value={tabValue} onChange={(e, value) => setTabValue(value)}>
						<Tab label="Moyens de paiement" index={0} />
						<Tab label="Factures" index={1} />
					</Tabs>
				</AppBar>
				<TabPanel value={tabValue} index={0}>
					<PaymentMethod />
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<PubInvoices data={data?.invoices} />
				</TabPanel>
			</TabsContainer>
		</>
	);
};

export default PubSold;
