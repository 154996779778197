import {
	Button,
	CheckBox,
	Col,
	Container,
	FormFeedback,
	FormGroup,
	Input,
	InputSelect,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPatch, axiosPost } from 'api/API';
import CheckboxSelect, {
	CheckboxSelectListItem,
	CheckboxSelectListTitle,
	CheckboxSelectListUl,
} from 'components/checkboxSelect/CheckboxSelect';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface delegateInfo {
	userId: number | string;
	regionsIds: { value: number | string; label: string }[];
	subRegionsIds: number[];
	salesCommission: number | string;
}

const DelegateAddPopUp = ({
	setOpen,
	currentDelegate,
	setIsFetching,
	assignedUsers,
}: {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	currentDelegate: any;
	setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
	assignedUsers: number[];
}) => {
	const [users, setUsers] = useState<any[]>([]);
	const [regions, setRegions] = useState<any[]>([]);
	const [subRegions, setSubRegions] = useState<any[]>([]);
	const [combinedRegions, setCombinedRegions] = useState<any[]>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const {
		reset,
		getValues,
		setError,
		setValue,
		watch,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm<delegateInfo>({
		defaultValues: {
			userId: '',
			regionsIds: [],
			subRegionsIds: [],
			salesCommission: '',
		},
	});

	const regionsIds = watch('regionsIds');
	const subRegionsIds = watch('subRegionsIds');

	const onSubmit = (values: delegateInfo) => {
		console.log('values', values);
		if (!isSubmitting) {
			setIsSubmitting(true);

			const checkedRegions: number[] = [];
			combinedRegions.forEach((ele) => {
				if (ele.subRegions.some((sub: any) => subRegionsIds.includes(sub.id))) checkedRegions.push(ele?.id);
			});

			const data = {
				userId: values?.userId,
				regionsIds: checkedRegions,
				subRegionsIds: values?.subRegionsIds,
				salesCommission: values?.salesCommission ? +values?.salesCommission : undefined,
			};

			if (currentDelegate?.id) {
				axiosPatch(`delegates/${currentDelegate?.id}`, data).then((res: any) => {
					if (res?.data) {
						notify({
							position: 'top-center',
							type: 'success',
							msg: 'Le délégué a été enregistrée avec succès',
							delay: 5000,
						});
						setIsFetching(true);
						setOpen(false);
						reset();
					} else {
						notify({
							position: 'top-center',
							type: 'danger',
							msg: res?.response?.data?.message,
							delay: 5000,
						});
					}
					setIsSubmitting(false);
				});
			} else {
				axiosPost(`delegates`, data).then((res: any) => {
					if (res?.data) {
						notify({
							position: 'top-center',
							type: 'success',
							msg: 'Le délégué a été enregistrée avec succès',
							delay: 5000,
						});
						setIsFetching(true);
						setOpen(false);
						reset();
					} else {
						notify({
							position: 'top-center',
							type: 'danger',
							msg: res?.response?.data?.message,
							delay: 5000,
						});
					}
					setIsSubmitting(false);
				});
			}
		}
	};

	// useEffect(() => {
	// 	if (currentDelegate?.id)
	// 		axiosGet(`delegates/${currentDelegate?.id}`).then((res: any) => {
	// 			reset({
	// 				userId: res?.data?.delegate?.id,
	// 				regionsIds: res?.data?.regions?.map((ele: any) => ({ value: ele?.id, label: ele?.name })),
	// 				subRegionsIds: res?.data?.subRegions?.map((ele: any) => ({ value: ele?.id, label: ele?.name })),
	// 				salesCommission: currentDelegate?.salesCommission ? +currentDelegate?.salesCommission : '',
	// 			});
	// 		});
	// }, []);
	const handleSearch = (term: string) => {
		setSearchTerm(term.toLowerCase());
	};

	const filteredCombinedRegions = useMemo(
		() =>
			combinedRegions
				.map((region) => ({
					...region,
					subRegions: region.subRegions.filter(
						(sub: any) =>
							sub.name.toLowerCase().includes(searchTerm) ||
							region.name.toLowerCase().includes(searchTerm)
					),
				}))
				.filter((region) => region.subRegions.length > 0),
		[combinedRegions, searchTerm]
	);

	useEffect(() => {
		axiosGet(`users?limit=10000`).then((res: any) => {
			setUsers(
				res?.data?.users?.filter(
					(ele: any) =>
						!assignedUsers?.some(
							(userId: number) => userId === ele?.id && userId !== currentDelegate?.delegate?.id
						)
				)
			);
		});
	}, []);

	useEffect(() => {
		axiosGet(`regions`).then((res: any) => {
			setRegions(res?.data?.response);
		});
	}, []);

	useEffect(() => {
		if (regions.length > 0 && combinedRegions.length <= 0) {
			axiosGet(`sub-regions?limit=10000`).then((res: any) => {
				axiosGet(`sub-regions/already-assigned?limit=10000`).then(async (assignedRes: any) => {
					let assignedSubRegions: number[] = assignedRes?.data?.response;

					console.log('assignedSubRegions', assignedSubRegions);
					if (currentDelegate?.id) {
						await axiosGet(`delegates/${currentDelegate?.id}`).then((delegRes: any) => {
							reset({
								userId: delegRes?.data?.delegate?.id,
								regionsIds: delegRes?.data?.regions?.map((ele: any) => ({
									value: ele?.id,
									label: ele?.name,
								})),
								subRegionsIds: delegRes?.data?.subRegions?.map((ele: any) => ele?.id),
								salesCommission: currentDelegate?.salesCommission
									? +currentDelegate?.salesCommission
									: '',
							});
							assignedSubRegions = assignedSubRegions.filter(
								(ele) => !delegRes?.data?.subRegions.some((sub: any) => sub?.id === ele)
							);
							console.log('assignedSubRegions1', assignedSubRegions);
						});
					}
					setCombinedRegions(
						regions.map((ele) => ({
							...ele,
							subRegions: res?.data?.response
								?.filter((sub: any) => sub?.region?.id === ele?.id)
								.map((ele: any) =>
									assignedSubRegions?.some((assigned: number) => assigned === ele.id)
										? { ...ele, isAssigned: true }
										: ele
								),
						}))
					);
				});
			});
		}
	}, [regions]);

	console.log('combinedRegions', combinedRegions);

	return (
		<Modal setOpen={() => setOpen(false)} size={'md'}>
			<ModalHeader setOpen={() => setOpen(false)} title="Nouveau délégué" />
			<ModalBody>
				<Container>
					<Row>
						<Col>
							<FormGroup>
								<Label>Utilisateur</Label>
								<Controller
									control={control}
									name="userId"
									rules={{ required: true }}
									render={({ field: { value, onChange, name } }) => (
										<InputSelect
											invalid={errors?.userId !== undefined}
											placeholder="Choisissez un utilisateur"
											menuPosition="fixed"
											value={value}
											options={
												users
													? users.map((ele) => ({
															value: ele.id,
															label: `${ele?.firstName} ${ele?.lastName}`,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors?.userId !== undefined}>Ce champ est requis</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label>Secteurs</Label>
								<Controller
									control={control}
									name="subRegionsIds"
									rules={{ required: true }}
									render={() => (
										<CheckboxSelect
											placeholder="Secteurs"
											invalid={errors?.subRegionsIds !== undefined}
											value={combinedRegions
												.reduce((acc, region) => {
													return acc.concat(region.subRegions);
												}, [])
												.filter((ele: any) => subRegionsIds.some((sub) => sub === ele?.id))
												.map((ele: any) => ele.name)
												.join(', ')}
											onSearch={handleSearch}
										>
											{filteredCombinedRegions.map((ele, index) => (
												<React.Fragment key={ele.id}>
													<CheckboxSelectListTitle>
														<CheckBox
															label={ele?.name}
															indeterminate={
																ele?.subRegions.some((sub: any) =>
																	subRegionsIds.includes(sub?.id)
																) &&
																!ele?.subRegions?.every((sub: any) =>
																	subRegionsIds.includes(sub?.id)
																)
															}
															checked={ele?.subRegions.some((sub: any) =>
																subRegionsIds.includes(sub?.id)
															)}
															onChange={(e) =>
																e.target.checked
																	? setValue(`subRegionsIds`, [
																			...subRegionsIds,
																			...ele.subRegions
																				.filter((sub: any) => !sub?.isAssigned)
																				.map((sub: any) => sub?.id),
																		])
																	: setValue(
																			`subRegionsIds`,
																			subRegionsIds.filter(
																				(subId) =>
																					!ele.subRegions.some(
																						(sub: any) => subId === sub?.id
																					)
																			)
																		)
															}
														/>
													</CheckboxSelectListTitle>
													<CheckboxSelectListUl>
														{ele?.subRegions?.map((sub: any) => (
															<CheckboxSelectListItem
																key={sub?.id}
																style={
																	sub?.isAssigned
																		? { opacity: 0.5, pointerEvents: 'none' }
																		: undefined
																}
															>
																<CheckBox
																	label={sub?.name}
																	checked={subRegionsIds.some(
																		(subId) => subId === sub?.id
																	)}
																	onChange={(e) =>
																		setValue(
																			`subRegionsIds`,
																			e.target.checked
																				? [...subRegionsIds, sub?.id]
																				: subRegionsIds.filter(
																						(subId) => subId !== sub?.id
																					)
																		)
																	}
																/>
															</CheckboxSelectListItem>
														))}
													</CheckboxSelectListUl>
												</React.Fragment>
											))}
										</CheckboxSelect>
									)}
								/>
								<FormFeedback invalid={errors?.subRegionsIds !== undefined}>
									Ce champ est requis
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label>Commission de vente (%)</Label>
								<Controller
									control={control}
									name="salesCommission"
									render={({ field: { value, onChange, name } }) => (
										<Input
											id={name}
											name={name}
											placeholder="Commission de vente (%)"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Container>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => handleSubmit(onSubmit)()} loading={isSubmitting}>
					Ajouter
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DelegateAddPopUp;
