import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
	Button,
	Card,
	CardTitle,
	CheckBox,
	Col,
	Container,
	FileInput,
	FormGroup,
	FormStep,
	FormSteps,
	Row,
	Tag,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosPost } from 'api/API';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import Papa from 'papaparse';

import { useImportContext } from './ImportAccounts';

const ImportAccountsStep1 = () => {
	const [fileInput, setFileInput] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const { headerKeys, setHeaderKeys } = useImportContext();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleParse = () => {
		Papa.parse(fileInput[0], {
			header: true,
			complete: (res: any) => {
				if (res?.data) {
					if (typeof res?.data?.[0] === 'object') setHeaderKeys(Object.keys(res?.data?.[0]));
				}
			},
		});
	};

	useEffect(() => {
		if (fileInput && fileInput?.length > 0) handleParse();
	}, [fileInput]);

	const handleNext = () => {
		if (headerKeys.length > 0 && !submitting) {
			setSubmitting(true);
			const formData = new FormData();
			formData.append('file', fileInput[0]);
			// formData.append('updateExistAccounts', updateExistAccounts.toString());
			axiosPost('accounts/imports', formData).then((res: any) => {
				if (res?.data) {
					navigate(`/accounts/import/step2/${res?.data?.id}`, { state: { data: res?.data } });
					setSubmitting(false);
				} else {
					setSubmitting(false);
				}
			});
		}
	};

	return (
		<>
			<div className="labs-header">
				<BackwardTitle title={t('Importer')} subTitle={t('Accounts')} to="/accounts" isBackward />
				<div className="labs-header-btns">
					<Button
						color="tertiary"
						onClick={() => {
							setHeaderKeys([]);
							setFileInput([]);
							navigate('/accounts/import/step1');
						}}
					>
						Réinitialiser
					</Button>
					<Button color="primary" onClick={() => handleNext()} loading={submitting}>
						Suivant
					</Button>
				</div>
			</div>
			<Card>
				<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
					<FormSteps style={{ width: 500 }}>
						<FormStep text="Upload" number={1} active />
						<FormStep text="Mapping" number={2} />
						<FormStep text="Vérification" number={3} last />
					</FormSteps>
				</div>
				<Container>
					<Row>
						<Col>
							<FormGroup>
								<FileInput
									accept=".csv"
									maxSize={2225000000}
									// progressValue={0}
									type="file"
									multiple
									selectedFiles={fileInput}
									onFileUpload={(e: any) => setFileInput(e)}
									text={
										<div>
											<h4 style={{ margin: 0, fontSize: 14, fontWeight: 600 }}>
												Déposer votre fichier ici
											</h4>
											<p>Format supporté: csv</p>
										</div>
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					{headerKeys?.length > 0 && (
						<>
							<hr style={{ margin: '16px -0.5rem 24px', opacity: 0.5 }} />
							<Row>
								<Col style={{ display: 'flex', flexWrap: 'wrap' }}>
									<CardTitle style={{ fontSize: '16px', fontWeight: 500, width: '100%' }}>
										Colonnes détectées
									</CardTitle>
									<div>
										{headerKeys?.map((ele, index) => (
											<Tag
												key={index}
												size="sm"
												minWidth={30}
												textColor="#2B4660"
												bgColor="#EEF2F7"
												style={{ margin: '5px' }}
											>
												{ele}
											</Tag>
										))}
									</div>
								</Col>
							</Row>
						</>
					)}
					{/* <hr style={{ margin: '28px -0.5rem', opacity: 0.5 }} />
					<Row>
						<Col>
							<CheckBox
								checked={updateExistAccounts}
								onChange={(e) => setUpdateExistAccounts(e.target.checked)}
								label="Importer et mettre à jour les comptes déjà existants"
							/>
						</Col>
					</Row> */}
				</Container>
			</Card>
		</>
	);
};

export default ImportAccountsStep1;
