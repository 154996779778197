import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
	Button,
	FormFeedback,
	FormGroup,
	Input,
	InputSelect,
	Label,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';

import { axiosGet, axiosPatch, axiosPost, axiosPut } from '../../../../api/API';
import BackwardTitle from '../../../../components/backwardTitle/BackwardTitle';
import TextArea from '../../../../components/textArea/TextArea';

function ModelCreation() {
	// const [emailModel, setEmailModel] = useState(null);
	const [nom, setNom] = useState(null);
	const [status, setStatus] = useState({ value: true, label: 'Actif' });
	const [desc, setDesc] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);
	const navigate = useNavigate();
	const { id } = useParams();
	const { t } = useTranslation();

	const addModel = () => {
		setSubmitted(true);
		if (nom && desc && !disableBtn) {
			setDisableBtn(true);
			if (id) {
				axiosPatch(`demands/responses/models/${id}`, { content: desc, name: nom, status: status?.value }).then(
					(res) => {
						if (res?.data) {
							notify({
								position: 'top-center',
								type: 'success',
								msg: 'Le modèle a bien été enregistré',
								delay: 5000,
							});
							navigate('/settings/emails-out');
						} else {
							notify({
								position: 'top-center',
								type: 'danger',
								msg: "Le modèle n'a pas été enregistré",
								delay: 5000,
							});
							setDisableBtn(false);
						}
					}
				);
			} else {
				axiosPost('demands/responses/models', { content: desc, name: nom, status: status?.value }).then(
					(res) => {
						if (res?.data) {
							notify({
								position: 'top-center',
								type: 'success',
								msg: 'Le modèle a bien été enregistré',
								delay: 5000,
							});
							navigate('/settings/emails-out');
						} else {
							notify({
								position: 'top-center',
								type: 'danger',
								msg: "Le modèle n'a pas été enregistré",
								delay: 5000,
							});
							setDisableBtn(false);
						}
					}
				);
			}
		}
	};

	useEffect(() => {
		if (id) {
			axiosGet(`demands/responses/models/${id}`).then((res) => {
				// setEmailModel(res?.data?.response);
				setNom(res?.data?.name);
				setStatus(res?.data?.status ? { value: true, label: 'Actif' } : { value: false, label: 'Inactif' });
				setDesc(res?.data?.content);
			});
		}
	}, []);

	return (
		<div className="fade">
			<div className="labs-settings-btns">
				<Link to="/settings/emails-out/modele-creation">
					<Button onClick={addModel}>{t('Save')}</Button>
				</Link>
			</div>
			<BackwardTitle
				title={id ? t('Edit email template') : t('Create email template')}
				to="/settings/emails-out"
			/>
			<FormGroup>
				<Label>{t('Name')}*</Label>
				<Input
					invalid={submitted && !nom}
					id="Nom"
					name="Nom"
					placeholder={t('Name')}
					type="text"
					value={nom}
					onChange={(e) => setNom(e.target.value)}
				/>
				<FormFeedback invalid={submitted && !nom}>{t('This field is required')}</FormFeedback>
			</FormGroup>
			<FormGroup>
				<Label>{t('Status')}</Label>
				<InputSelect
					id="Statut"
					name="Statut"
					placeholder={t('Status')}
					value={status}
					options={[
						{ value: true, label: t('Active') },
						{ value: false, label: t('Inactive') },
					]}
					onChange={(e) => setStatus(e || null)}
				/>
			</FormGroup>
			<FormGroup>
				<Label>{t('Content')}*</Label>
				<Input
					type="textarea"
					invalid={submitted && !desc}
					style={{ minHeight: 150 }}
					value={desc}
					onChange={(e) => setDesc(e.target.value)}
				/>
				<FormFeedback invalid={submitted && !desc}>{t('This field is required')}</FormFeedback>
			</FormGroup>
		</div>
	);
}

export default ModelCreation;
