import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	EmptyData,
	Table,
	TableContainer,
	TableHeader,
	TableHeaderTitle,
	TableLoader,
	Th,
} from '@sobrus-com/sobrus-design-system-v2';

import { axiosGet } from '../../../api/API';
import usePagination from '../../../utils/usePagination';

function Methodes() {
	const [isFetchingMethodes, setIsFetchingMethodes] = useState(true);
	const [methodes, setMethodes] = useState(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (isFetchingMethodes) {
			axiosGet(`actions`).then((res) => {
				setMethodes(res?.data?.actions);
				setIsFetchingMethodes(false);
			});
		}
	}, [isFetchingMethodes]);

	return (
		<TableContainer className="fade">
			<TableHeader>
				<TableHeaderTitle>Liste des permissions</TableHeaderTitle>
			</TableHeader>
			<Table>
				<thead>
					<tr>
						<Th hideOrder>{t('Methods')}</Th>
						<Th hideOrder>{t('Resource')}</Th>
						<Th hideOrder>Action</Th>
						<Th hideOrder>Route</Th>
						<th aria-label="empty" width="75px" />
					</tr>
				</thead>
				{isFetchingMethodes && (
					<TableLoader
						loading
						columns={[{ type: 'large' }, { type: 'large' }, { type: 'large' }, { type: 'large' }]}
					/>
				)}
				<tbody>
					{!isFetchingMethodes &&
						methodes &&
						methodes.map((ele) => (
							<tr key={`${ele.id}gammes`}>
								<td>{ele.label}</td>
								<td>{ele.resource}</td>
								<td style={{ wordBreak: 'break-all' }}>{ele.name}</td>
								<td style={{ wordBreak: 'break-all' }}>{ele.route}</td>
								<td width="75px" />
							</tr>
						))}

					{/* {methodes?.length > 0 && (
                        <tr>
                            <td colSpan={7}>
                                <div className="sob-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange("prev")} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage
                                        onClick={() => pageChange("next")}
                                        disabled={methodes?.length < 20}
                                    />
                                </div>
                            </td>
                        </tr>
                    )} */}
				</tbody>
			</Table>
			{!isFetchingMethodes && methodes?.length < 1 && <EmptyData title={t('No results match your search')} />}
		</TableContainer>
	);
}

export default Methodes;
