import { useEffect } from 'react';

export const useOutSideClick = (ref: any, callback: () => void) => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		};
		const handleClickEsc = (e: KeyboardEvent) => {
			if (e?.key === 'Escape') {
				callback();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleClickEsc);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleClickEsc);
		};
	}, [ref, callback]);
};
