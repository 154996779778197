import React, { useState } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
} from 'react-accessible-accordion';

function AccordionMenu({ children, title, leftIcon, disabled = false }) {
	const [selected, setSelected] = useState(false);

	return (
		<Accordion
			className="accord-links"
			allowMultipleExpanded
			allowZeroExpanded
			aria-selected={selected}
			onClick={() => setSelected(!selected)}
			aria-disabled={disabled}
		>
			<AccordionItem>
				<AccordionItemHeading>
					<AccordionItemButton>
						<div className="accord-leftIcon">{leftIcon}</div>
						<div className="accord-title">{title}</div>
						{selected && (
							<svg
								width="31"
								height="30"
								viewBox="0 0 31 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="30.6666"
									width="30"
									height="30"
									rx="9"
									transform="rotate(90 30.6666 0)"
									fill="#5CB4E5"
								/>
								<path
									d="M19.4166 15.75L15.6666 19.5M15.6666 19.5L11.9166 15.75M15.6666 19.5L15.6666 10.5"
									stroke="white"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
						{!selected && (
							<svg
								width="31"
								height="30"
								viewBox="0 0 31 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="0.666626"
									width="30"
									height="30"
									rx="9"
									fill={disabled ? '#EEF2F7' : '#5CB4E5'}
								/>
								<path
									d="M16.4166 11.25L20.1666 15M20.1666 15L16.4166 18.75M20.1666 15L11.1666 15"
									stroke={disabled ? '#C4CDD6' : 'white'}
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						)}
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<ul>{children}</ul>
				</AccordionItemPanel>
			</AccordionItem>
		</Accordion>
	);
}

export default AccordionMenu;
