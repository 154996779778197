import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@sobrus-com/sobrus-design-system-v2';

function AccessDenied() {
	const navigate = useNavigate();

	return (
		<div className="accessDenied-container fade">
			<div className="accessDenied-content">
				<img src={require('../assets/logo.svg').default} alt="" />
				<h1>Ooops !</h1>
				<h4>
					Vous n&apos;avez pas le droit
					<br />
					d&apos;accéder à cette page
				</h4>
				<Button onClick={() => navigate('/')}>Quitter</Button>
			</div>
			<div className="accessDenied-img">
				<img src={require('../assets/accessDenied.svg').default} alt="" />
			</div>
		</div>
	);
}

export default AccessDenied;
