import {
	Avatar,
	Button,
	Col,
	Container,
	FormFeedback,
	FormGroup,
	Input,
	InputSelect,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	RadioButton,
	Row,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';
import React, { useState } from 'react';
import './SendEmailPopUp.scss';
import { Controller, useForm } from 'react-hook-form';
import { axiosPost } from 'api/API';

interface props {
	setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
	users: any[];
	isMarketPlace?: boolean;
}

type defaultValues = {
	email: string;
	userId: string;
	type: string;
};

const SendEmailPopUp: React.FC<props> = ({ setModelOpen, users, isMarketPlace }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { register, reset, control, handleSubmit, watch } = useForm<defaultValues>({
		defaultValues: {
			email: '',
			userId: '',
			type: 'user',
		},
	});

	const type = watch('type');

	const onSubmit = (values: defaultValues) => {
		setIsSubmitting(true);
		let data: defaultValues | object = {};
		if (values.type === 'user') data = { userId: values?.userId };
		else data = { email: values?.email };
		axiosPost(isMarketPlace ? 'reports/marketplace' : 'reports/posts', data).then((res: any) => {
			res?.data
				? notify({
						position: 'top-center',
						type: 'success',
						msg: 'Le rapport a été envoyé avec succes',
						delay: 5000,
					})
				: notify({
						position: 'top-center',
						type: 'danger',
						msg: "Le rapport n'a pas été envoyé",
						delay: 5000,
					});

			setIsSubmitting(false);
			setModelOpen(false);
		});
	};

	return (
		<Modal setOpen={() => setModelOpen(false)} size={'md'} className="sendEmail-popup-container">
			<ModalHeader
				setOpen={() => setModelOpen(false)}
				onClick={() => console.log('goback click')}
				title="Envoyer un e-mail de test"
			/>
			<ModalBody>
				<Container>
					<Row>
						<Col>
							<div className="sendEmail-popup-card">
								<Container>
									<Row>
										<Col>
											<FormGroup>
												<Label style={{ marginBottom: 12 }}>
													<RadioButton
														{...register('type')}
														name="type"
														text="Utilisateur déjà existant"
														value="user"
													/>
												</Label>
												<Controller
													control={control}
													name="userId"
													rules={{ required: type === 'user' }}
													render={({ field: { value, onChange }, fieldState: { error } }) => (
														<>
															<InputSelect
																invalid={error !== undefined}
																noOptionsMessage="Aucun résultat"
																placeholder="Utilisateur déjà existant"
																menuPosition="fixed"
																isSearchable
																value={value}
																options={
																	users
																		? users.map((ele) => ({
																				value: ele.id,
																				label: (
																					<div>
																						<Avatar
																							className=""
																							image=""
																							name={`${ele?.firstName} ${ele?.lastName}`}
																							size="sm"
																						>
																							Statut
																						</Avatar>{' '}
																						{`${ele?.firstName} ${ele?.lastName}`}
																					</div>
																				),
																			}))
																		: []
																}
																onChange={(e: any) => onChange(e?.value)}
															/>
															<FormFeedback invalid={error !== undefined}>
																Ce champ est requis
															</FormFeedback>
														</>
													)}
												/>
											</FormGroup>
										</Col>
									</Row>
								</Container>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="sendEmail-popup-card">
								<Container>
									<Row>
										<Col>
											<FormGroup>
												<Label style={{ marginBottom: 12 }}>
													<RadioButton
														{...register('type')}
														name="type"
														text="Email"
														value="email"
													/>
												</Label>
												<Controller
													control={control}
													name="email"
													rules={{
														pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
														required: type === 'email',
													}}
													render={({ field: { value, onChange }, fieldState: { error } }) => (
														<>
															<Input
																invalid={error !== undefined}
																placeholder="Email"
																type="email"
																value={value}
																onChange={(e: any) => onChange(e.target.value)}
															/>
															<FormFeedback invalid={error !== undefined}>
																{error?.type === 'pattern'
																	? 'Veuillez entrer un e-mail valid'
																	: 'Ce champ est requis'}
															</FormFeedback>
														</>
													)}
												/>
											</FormGroup>
										</Col>
									</Row>
								</Container>
							</div>
						</Col>
					</Row>
				</Container>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => handleSubmit(onSubmit)()} loading={isSubmitting}>
					Envoyer
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default SendEmailPopUp;
