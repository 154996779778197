import * as Sentry from '@sentry/react';
import React from 'react';
import MainNavigator from './navigation/MainNavigator';
import { hotjar } from 'react-hotjar';

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://f50a98be231046228fb68b3785ffd41b@o4505092585816064.ingest.sentry.io/4505245206118400',
		integrations: [new Sentry.BrowserTracing()],
		// Performance Monitoring
		tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

	hotjar.initialize({ id: 3919198, sv: 6 });
}

function App() {
	if (process.env.NODE_ENV !== 'development') {
		console.log = () => {};
		console.debug = () => {};
		console.info = () => {};
		console.warn = () => {};
		console.error = () => {};
	}

	return <MainNavigator />;
}

export default App;
