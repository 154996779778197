import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { Input, InputSelect, Table, TableContainer, Th } from '@sobrus-com/sobrus-design-system-v2';

import { defaultValues } from './ImportAccountsStep3';

interface props {
	control: Control<defaultValues, any>;
	contactsFields: {
		index: number;
		ref: string;
		firstName: string;
		lastName: string;
		title: string;
		email: string;
		mobile: string;
		position: string;
		service: string;
		categoryId: number | null;
		id: string;
	}[];
	contactCategories: any[] | null;
}

const ContactsTable: React.FC<props> = ({ control, contactsFields, contactCategories }) => {
	const { t } = useTranslation();

	// handle pagination stats
	const [itemOffset, setItemOffset] = useState(0);

	const endOffset = itemOffset + 8;
	const currentItems = contactsFields.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(contactsFields.length / 8);

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		const newOffset = (event.selected * 8) % contactsFields.length;
		setItemOffset(newOffset);
	};

	return (
		<TableContainer>
			<Table size="md">
				<thead>
					<tr>
						<Th hideOrder>Titre</Th>
						<Th hideOrder>Prénom</Th>
						<Th hideOrder>Nom</Th>
						<Th hideOrder>Catégorie</Th>
						<Th hideOrder>Email</Th>
						<Th hideOrder>Portable</Th>
						<Th hideOrder>Position</Th>
						<Th hideOrder>Service</Th>
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((ele) => (
						<tr key={ele.id}>
							<td>
								<Controller
									control={control}
									name={`contacts.${ele.index}.title`}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											size="sm"
											menuPosition="fixed"
											placeholder="Type de client"
											value={value}
											options={[
												{ value: 'monsieur', label: 'Monsieur' },
												{ value: 'madame', label: 'Madame' },
												{ value: 'docteur', label: 'Docteur' },
												{ value: 'professeur', label: 'Professeur' },
											]}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`contacts.${ele?.index}.firstName`}
									rules={{ required: true }}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Input
											invalid={error !== undefined}
											placeholder="Prénom"
											size="sm"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`contacts.${ele?.index}.lastName`}
									rules={{ required: true }}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Input
											invalid={error !== undefined}
											placeholder="Nom"
											size="sm"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`contacts.${ele.index}.categoryId`}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<InputSelect
											invalid={error !== undefined}
											size="sm"
											menuPosition="fixed"
											placeholder="Catégorie compte"
											value={value}
											options={
												contactCategories
													? contactCategories?.map((cat: any) => ({
															value: cat?.id,
															label: cat?.name,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`contacts.${ele?.index}.email`}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Input
											invalid={error !== undefined}
											placeholder="Email"
											size="sm"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`contacts.${ele?.index}.mobile`}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Input
											invalid={error !== undefined}
											placeholder="Portable"
											size="sm"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`contacts.${ele?.index}.position`}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Input
											invalid={error !== undefined}
											placeholder="Position"
											size="sm"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`contacts.${ele.index}.service`}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Input
											invalid={error !== undefined}
											placeholder="Service"
											size="sm"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</td>
						</tr>
					))}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={10}>
							<div className="sob-v2-table-pagination">
								<ReactPaginate
									breakLabel=""
									nextLabel=">"
									onPageChange={handlePageClick}
									pageRangeDisplayed={0}
									marginPagesDisplayed={0}
									pageCount={pageCount}
									// forcePage={pageNumber}
									previousLabel="<"
									renderOnZeroPageCount={null}
									activeClassName="sob-v2-TablePage"
									previousClassName="sob-v2-TablePage__btn"
									nextClassName="sob-v2-TablePage__btn"
									disabledClassName="sob-v2-TablePage__disabled"
									containerClassName="orderPropos-pagination-container sob-v2-table-pagination"
								/>
							</div>
						</td>
					</tr>
				</tfoot>
			</Table>
		</TableContainer>
	);
};

export default ContactsTable;
