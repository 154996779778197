import React, { useContext, useEffect, useState } from 'react';
import { Font } from '@react-pdf/renderer';
import { SpinnerLoading, Toast } from '@sobrus-com/sobrus-design-system-v2';
import { Review } from '@sobrus-com/sobrus-surveys-package';
// import { Review } from '@sobrus-com/sobrus-surveys-package';
import { API_URL2 } from 'env';
import poppinsBold from 'fonts/Poppins-Bold.ttf';
import poppinsBoldItalic from 'fonts/Poppins-BoldItalic.ttf';
import poppinsItalic from 'fonts/Poppins-Italic.ttf';
import poppinsLight from 'fonts/Poppins-Light.ttf';
import poppinsLightItalic from 'fonts/Poppins-LightItalic.ttf';
import poppinsMedium from 'fonts/Poppins-Medium.ttf';
import poppinsMediumItalic from 'fonts/Poppins-MediumItalic.ttf';
import poppinsRegular from 'fonts/Poppins-Regular.ttf';
import poppinsSemiBold from 'fonts/Poppins-SemiBold.ttf';
import poppinsSemiBoldItalic from 'fonts/Poppins-SemiBoldItalic.ttf';

import { useAcl } from '../ACL/acl';
import { axiosGet } from '../api/API';
import { UserContext } from '../context/userContext';

import '../styles/labs.scss';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Root from './Root';
import Nav from 'pages/Nav';

Font.register({
	family: 'Poppins',
	fonts: [
		{ src: poppinsRegular }, // font-style: normal, font-weight: normal
		{ src: poppinsItalic, fontStyle: 'italic' },
		{ src: poppinsLight, fontStyle: 'normal', fontWeight: 300 },
		{ src: poppinsLightItalic, fontStyle: 'italic', fontWeight: 300 },
		{ src: poppinsMedium, fontStyle: 'normal', fontWeight: 500 },
		{ src: poppinsMediumItalic, fontStyle: 'italic', fontWeight: 500 },
		{ src: poppinsSemiBold, fontStyle: 'normal', fontWeight: 600 },
		{ src: poppinsSemiBoldItalic, fontStyle: 'italic', fontWeight: 600 },
		{ src: poppinsBold, fontStyle: 'normal', fontWeight: 700 },
		{ src: poppinsBoldItalic, fontStyle: 'italic', fontWeight: 700 },
	],
});

function HomeNavigator() {
	const {
		isLogin,
		setIsLogin,
		user,
		setUser,
		setNotifs,
		isFetchingNotifs,
		setIsFetchingNotifs,
		numberNotifs,
		setNumbetNotifs,
		isFetching,
		setIsFetching,
	} = useContext(UserContext);
	const [aclNotif, setAclNotif] = useState(null);
	const acl = useAcl(user);

	// function getCookie(name) {
	//     var match = document.cookie.match(RegExp("(?:^|;\\s*)" + name + "=([^;]*)"));
	//     return match ? match[1] : null;
	// }

	useEffect(() => {
		if (localStorage.getItem('isLogin')) {
			setIsLogin(localStorage.getItem('isLogin'));
			if (isLogin && isFetching) {
				axiosGet('users/authenticated-user').then((res) => {
					if (res?.data) {
						setUser(res?.data);
						// acl.setCurrUser(res?.data);
						setAclNotif(acl?.notification?.list);
						setIsFetching(false);
					}
				});
			}
		} else if (window.location.pathname === '/auth/success' && !isLogin) {
			axiosGet('users/authenticated-user').then((res) => {
				setIsLogin(true);
				localStorage.setItem('isLogin', true);
				setUser(res?.data);
				// acl.setCurrUser(res?.data);
				setAclNotif(acl?.notification?.list);
				setIsFetching(false);
			});
		} else {
			setIsFetching(false);
		}
	}, [isLogin]);

	useEffect(() => {
		setAclNotif(acl?.notification?.list);
	}, [acl]);

	useEffect(() => {
		if (isFetchingNotifs && isLogin && aclNotif) {
			axiosGet('notifications/get').then((res) => {
				if (res) {
					setNotifs(res?.data?.notifications);
					setNumbetNotifs(res?.data?.notifications?.length);
					setIsFetchingNotifs(false);
				}
			});
		}
		const interval = setInterval(() => {
			if (isLogin && aclNotif) {
				axiosGet('notifications/get').then((res) => {
					if (res) {
						setNotifs(res?.data?.notifications);
						setNumbetNotifs(res?.data?.notifications?.length);
					}
				});
			}
		}, 15000);
		return () => clearInterval(interval);
	}, [isFetchingNotifs, isLogin, aclNotif]);

	const router = createBrowserRouter([
		{
			element: (
				<>
					{isLogin && <Nav user={user} numberNotifs={numberNotifs} />}
					<Outlet />
				</>
			),
			loader: Loader,
			children: [
				{
					path: '*',
					element: <Root />,
				},
			],
		},
	]);

	if (!isFetching)
		return (
			<div className="labs">
				<Toast />
				{isLogin && <Review baseUrl={API_URL2} />}
				{/* {isLogin && <Nav user={user} numberNotifs={numberNotifs} />} */}
				{/* <TableauDeBord /> */}
				<RouterProvider router={router} />
			</div>
		);
	return <Loader />;
}

function Loader() {
	return (
		<div className="labs">
			<div className="loader">
				<SpinnerLoading color="#DF4751" loading />
			</div>
		</div>
	);
}

export default HomeNavigator;
