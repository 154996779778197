import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	Button,
	EmptyData,
	Table,
	TableContainer,
	TableHeader,
	TableHeaderTitle,
	Th,
} from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import { format, parseISO } from 'date-fns';

// import { Match, matchPath, useLocation } from "react-router";
import { useAcl } from '../../ACL/acl';
import { axiosPatch } from '../../api/API';
import { UserContext } from '../../context/userContext';

const linkmaker = (resourceData) => {
	switch (resourceData?.context) {
		case 'demands':
			return `/requests/details/${resourceData?.resourceId}`;
		case 'orders':
			return `/orders/details/${resourceData?.resourceId}`;

		default:
			return undefined;
	}
};

function Notifications() {
	// const [isFetchingPlannings, setIsFetchingPlannings] = useState(true);
	const { notifs, setIsFetchingNotifs } = useContext(UserContext);
	const navigate = useNavigate();
	const acl = useAcl();

	const handleNotifClick = (resourceData, id) => {
		setIsFetchingNotifs(true);
		if (resourceData?.resourceId) navigate(linkmaker(resourceData));
		if (acl?.notification?.put_notification_as_seen) axiosPatch(`notifications/${id}/mark-as-seen`);
	};

	const handleMarkAll = () => {
		axiosPatch(`notifications/mark-all-as-seen`).then((res) => setIsFetchingNotifs(true));
	};
	const formatContent = (content, createdBy) => {
		if (content.startsWith('(')) {
			return (
				<span>
					{'La demande '}
					<strong> {content}</strong>
					{' vous a été affectée par '}
					<strong>{`${createdBy.firstName} ${createdBy.lastName}`}</strong>
				</span>
			);
		}
		return content;
	};

	return (
		<section className="products-container labs-container">
			<div className="products-header">
				<BackwardTitle title="Liste des notifications" subTitle="Notifications" />
				<div className="labs-header-btns">
					{acl?.notification?.list_history && (
						<Link to="/notifications/history">
							<Button style={{ margin: 0 }} color="danger-tertiary">
								Historique
							</Button>
						</Link>
					)}
					{acl?.notification?.put_all_notifications_as_seen && (
						<Button onClick={handleMarkAll}>Marquer tout comme lu</Button>
					)}
				</div>
			</div>
			<TableContainer>
				<TableHeader>
					<TableHeaderTitle>Liste des notifications</TableHeaderTitle>
				</TableHeader>
				<Table>
					<thead>
						<tr>
							<td style={{ display: 'flex' }} />
							<Th hideOrder>Notification</Th>
							<Th hideOrder>Date</Th>
							<th aria-label="empty" width="75px" />
						</tr>
					</thead>
					<tbody>
						{notifs &&
							notifs.map((ele) => (
								<tr
									key={ele.id}
									onClick={() => {
										handleNotifClick(ele.resourceData, ele.id);
									}}
								>
									<td style={{ textAlign: 'center' }}>
										{ele?.resourceData?.context === 'demands' &&
											ele?.resourceData?.action === 'assignment' && (
												<img
													style={{ margin: '0 auto' }}
													src={require('../../assets/affected-demand.svg').default}
													alt=""
												/>
											)}
										{ele?.resourceData?.context === 'demands' &&
											ele?.resourceData?.action === 'creation' && (
												<img
													style={{ margin: '0 auto' }}
													src={require('../../assets/new-demand.svg').default}
													alt=""
												/>
											)}
										{ele?.resourceData?.context === 'demands' &&
											ele?.resourceData?.action === 'comment' && (
												<img
													style={{ margin: '0 auto' }}
													src={require('../../assets/name-mentioned.svg').default}
													alt=""
												/>
											)}
										{ele?.resourceData?.context === 'orders' &&
											ele?.resourceData?.action === 'creation' && (
												<svg
													width="30"
													height="31"
													viewBox="0 0 30 31"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g clipPath="url(#clip0_8829_446)">
														<rect
															y="0.441406"
															width="30"
															height="30"
															rx="9"
															fill="#F2F8FD"
														/>
														<path
															d="M8.25 8.69141H9.75L10.05 10.1914M11.25 16.1914H18.75L21.75 10.1914H10.05M11.25 16.1914L10.05 10.1914M11.25 16.1914L9.53033 17.9111C9.05786 18.3835 9.39248 19.1914 10.0607 19.1914H18.75M18.75 19.1914C17.9216 19.1914 17.25 19.863 17.25 20.6914C17.25 21.5198 17.9216 22.1914 18.75 22.1914C19.5784 22.1914 20.25 21.5198 20.25 20.6914C20.25 19.863 19.5784 19.1914 18.75 19.1914ZM12.75 20.6914C12.75 21.5198 12.0784 22.1914 11.25 22.1914C10.4216 22.1914 9.75 21.5198 9.75 20.6914C9.75 19.863 10.4216 19.1914 11.25 19.1914C12.0784 19.1914 12.75 19.863 12.75 20.6914Z"
															stroke="#2693D1"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</g>
													<defs>
														<clipPath id="clip0_8829_446">
															<rect
																y="0.441406"
																width="30"
																height="30"
																rx="9"
																fill="white"
															/>
														</clipPath>
													</defs>
												</svg>
											)}
										{ele?.resourceData?.context === 'orders' &&
											ele?.resourceData?.action === 'cancellation' && (
												<svg
													width="30"
													height="31"
													viewBox="0 0 30 31"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g clipPath="url(#clip0_8829_1460)">
														<rect
															y="0.441406"
															width="30"
															height="30"
															rx="9"
															fill="#F9D2D3"
														/>
														<path
															d="M8.25 8.69141H9.75L10.05 10.1914M11.25 16.1914H18.75L21.75 10.1914H10.05M11.25 16.1914L10.05 10.1914M11.25 16.1914L9.53033 17.9111C9.05786 18.3835 9.39248 19.1914 10.0607 19.1914H18.75M18.75 19.1914C17.9216 19.1914 17.25 19.863 17.25 20.6914C17.25 21.5198 17.9216 22.1914 18.75 22.1914C19.5784 22.1914 20.25 21.5198 20.25 20.6914C20.25 19.863 19.5784 19.1914 18.75 19.1914ZM12.75 20.6914C12.75 21.5198 12.0784 22.1914 11.25 22.1914C10.4216 22.1914 9.75 21.5198 9.75 20.6914C9.75 19.863 10.4216 19.1914 11.25 19.1914C12.0784 19.1914 12.75 19.863 12.75 20.6914Z"
															stroke="#CC3244"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</g>
													<defs>
														<clipPath id="clip0_8829_1460">
															<rect
																y="0.441406"
																width="30"
																height="30"
																rx="9"
																fill="white"
															/>
														</clipPath>
													</defs>
												</svg>
											)}
									</td>
									<td>{formatContent(ele.content, ele.createdBy)}</td>
									<td>{ele.createdAt && format(parseISO(ele.createdAt), 'yyyy-MM-dd à HH:mm')}</td>
									<td width="75px" />
								</tr>
							))}
					</tbody>
				</Table>
				{notifs?.length < 1 && (
					<EmptyData
						desc="Toutes les notifications à venir seront affichées ici"
						title="Vous n'avez aucune notification pour le moment"
					/>
				)}
			</TableContainer>
		</section>
	);
}

export default Notifications;
