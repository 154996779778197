import {
	Button,
	Card,
	CheckBox,
	Col,
	Container,
	DatePicker,
	FormFeedback,
	FormGroup,
	FormStep,
	FormSteps,
	Input,
	InputSelect,
	Label,
	Row,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import React, { useCallback, useState } from 'react';
import { useOffer } from './OrderAdd';
import TextArea from 'components/textArea/TextArea';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addDays, addHours, addMonths, addWeeks, format, isDate } from 'date-fns';
import { axiosPost } from 'api/API';
import OrderProducts from './OrderProducts';

const OrderAddStep2 = () => {
	const { offer, setOffer } = useOffer();
	const [submitted, setSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [comment, setComment] = useState(null);
	const [type, setType] = useState<any>(null);
	const [address, setAddress] = useState('');
	const [withReceiptOfInvoice, setWithReceiptOfInvoice] = useState(false);

	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const handleSubmit = () => {
		if (!searchParams.get('resourceId')) return false;
		if (!isSubmitting && type && address) {
			setIsSubmitting(true);
			const data: any = {
				offerId: searchParams.get('offerId'),
				resource: {
					id: searchParams.get('resourceId'),
					type: searchParams.get('resourceType'),
				},
				administratorId: searchParams.get('administratorId'),
				purchaseOrderDate: format(new Date(), 'yyyy-MM-dd HH:mm'),
				comment,
				paymentMethodId: type,
				address: address,
				withReceiptOfInvoice: withReceiptOfInvoice,
				packs: {},
			};
			data.packs = offer.offerPacks.map((pack: any) => ({
				packId: pack.id,
				products: pack.products
					.filter((prod: any) => Number(prod.quantity) > 0)
					.map((prod: any) => ({ productId: prod.product.id, quantity: prod.quantity })),
			}));
			// console.log('data', data);
			axiosPost('purchase-orders', data).then((res: any) => {
				if (res?.data?.id) {
					notify({
						position: 'top-center',
						type: 'success',
						msg: 'Le bon de commande est créé avec succes',
						delay: 5000,
					});
					navigate(`/orders/create/step3?orderId=${res?.data?.id}`);
				} else {
					notify({
						position: 'top-center',
						type: 'danger',
						msg:
							res?.response?.data?.code < 500
								? res?.response?.data?.message
								: "Le bon de commande n'est pas créé",
						delay: 5000,
					});
					setIsSubmitting(false);
				}
			});
		}
	};

	// const dateCalcul = useCallback(() => {
	// 	let resDate = new Date();
	// 	if (offer?.paymentTimeframe) {
	// 		if (typeof offer?.paymentTimeframeUnit === 'string') {
	// 			const timeFrameType = JSON.parse(offer?.paymentTimeframeUnit)?.value;
	// 			if (timeFrameType === 'hours') resDate = addHours(resDate, +offer?.paymentTimeframe);
	// 			else if (timeFrameType === 'days') resDate = addDays(resDate, +offer?.paymentTimeframe);
	// 			else if (timeFrameType === 'weeks') resDate = addWeeks(resDate, +offer?.paymentTimeframe);
	// 			else if (timeFrameType === 'months') resDate = addMonths(resDate, +offer?.paymentTimeframe);
	// 		}
	// 	}
	// 	if (offer?.deliveryTimeframe) {
	// 		if (typeof offer?.deliveryTimeframeUnit === 'string') {
	// 			const timeFrameType = JSON.parse(offer?.deliveryTimeframeUnit)?.value;
	// 			if (timeFrameType === 'hours') resDate = addHours(resDate, +offer?.deliveryTimeframe);
	// 			else if (timeFrameType === 'days') resDate = addDays(resDate, +offer?.deliveryTimeframe);
	// 			else if (timeFrameType === 'weeks') resDate = addWeeks(resDate, +offer?.deliveryTimeframe);
	// 			else if (timeFrameType === 'months') resDate = addMonths(resDate, +offer?.deliveryTimeframe);
	// 		}
	// 	}

	// 	return format(resDate, 'dd/MM/yyyy');
	// }, []);

	return (
		<>
			<div className="labs-header">
				<BackwardTitle to={-1} title="Confirmer la commande" subTitle="Ventes" isBackward />
			</div>
			<Container>
				<Row>
					<Col>
						<Card>
							<Container>
								<Row>
									<Col>
										<FormSteps style={{ maxWidth: 600, margin: '0 auto 20px' }}>
											<FormStep active number={1} succes text="Définition des quantités" />
											<FormStep active last number={2} text="Récapitulatif et finalisation" />
										</FormSteps>
									</Col>
								</Row>
								<Row>
									{/* <Col>
										<FormGroup>
											<Label>Date sur bon de commande*</Label>
											<DatePicker
												calendarMainColor="primary"
												placeholder="Date sur bon de commande"
												dateFormat="dd-MM-yyyy HH:mm"
												showYearDropdown
												showTimeSelect
												minDate={new Date()}
												selected={orderDate ?? new Date()}
												onChange={(date: Date) => setOrderDate(date)}
											/>
											<FormFeedback>Ce champ est requis</FormFeedback>
										</FormGroup>
									</Col> */}
									<Col>
										<FormGroup>
											<Label>Mode de paiement*</Label>
											<InputSelect
												invalid={!!(submitted && !type)}
												placeholder="Mode de paiement"
												value={type}
												options={
													offer?.acceptedPaymentMethods &&
													offer?.acceptedPaymentMethods.map((meth: any) => ({
														value: meth.id,
														label: meth.label,
													}))
												}
												onChange={(e: any) => setType(e?.value)}
											/>
											<FormFeedback invalid={!!(submitted && !type)}>
												Ce champ est requis
											</FormFeedback>
										</FormGroup>
									</Col>
								</Row>
								{(type === 44 || type === 42) &&
									((offer?.paymentTimeframe && !isNaN(+offer?.paymentTimeframe)) ||
										(offer?.deliveryTimeframe && !isNaN(+offer?.deliveryTimeframe))) && (
										<Row>
											<Col>
												<div
													style={{
														display: 'flex',
														padding: '8px 12px',
														backgroundColor: '#E2EFFC',
														borderRadius: 8,
														margin: '0 0 12px',
														fontSize: '12px',
													}}
												>
													<svg
														style={{ marginRight: 5 }}
														width="16"
														height="17"
														viewBox="0 0 16 17"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M8.66667 11.11H8V8.44336H7.33333M8 5.77669H8.00667M14 8.44336C14 11.7571 11.3137 14.4434 8 14.4434C4.68629 14.4434 2 11.7571 2 8.44336C2 5.12965 4.68629 2.44336 8 2.44336C11.3137 2.44336 14 5.12965 14 8.44336Z"
															stroke="#123E68"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
													Veillez à ce que votre {type === 42 ? 'chéque' : 'traite'} soit à
													l&apos;ordre de:
													<strong style={{ fontWeight: 500, marginLeft: 5 }}>
														{offer?.payableTo}
													</strong>
													{/* daté au plus tard du {dateCalcul()}. */}
												</div>
											</Col>
										</Row>
									)}
								<Row>
									<Col>
										<FormGroup>
											<Label>Adresse*</Label>
											<Input
												invalid={submitted && !address}
												placeholder="Adresse"
												value={address}
												onChange={(e: any) => setAddress(e.target.value)}
											/>
											<FormFeedback invalid={submitted && !address}>
												Ce champ est requis
											</FormFeedback>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col>
										<FormGroup style={{ paddingBottom: 0 }}>
											<Label>Commentaires</Label>
											<TextArea
												rows="2"
												value={comment}
												onChange={(e: any) => setComment(e.target.value)}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col>
										<FormGroup style={{ paddingTop: 12, paddingBottom: 0 }}>
											<CheckBox
												checked={withReceiptOfInvoice}
												onChange={(e) => setWithReceiptOfInvoice(e.target.checked)}
												label="Le client souhaite recevoir une facture papier avec sa commande"
											/>
										</FormGroup>
									</Col>
								</Row>
							</Container>
						</Card>
						<OrderProducts setOffer={setOffer} offer={offer} isView />
					</Col>
					<Col xs="3">
						<Card style={{ position: 'sticky', top: 10 }}>
							<Card className="order-totals-container">
								<div className="order-totals-card">
									<div className="order-totals-info">Quantité totale commandée</div>
									<div className="order-totals-value">{offer?.totalQuantity ?? 0}</div>
								</div>
								<div className="order-totals-card">
									<div className="order-totals-info">Total avant remise</div>
									<div className="order-totals-value">{offer?.totalPPH ?? 0}</div>
								</div>
								<div className="order-totals-card">
									<div className="order-totals-info">Total U.G</div>
									<div className="order-totals-value">{offer?.totalFu ?? 0}</div>
								</div>
								<div className="order-totals-card-special green">
									<div className="order-totals-info">Montant économisé</div>
									<div className="order-totals-value">
										{offer?.economie ?? 0} Dhs ({offer?.totalDiscount ?? 0}%)
									</div>
								</div>
								<div className="order-totals-card-special">
									<div className="order-totals-info">Total à payer</div>
									<div className="order-totals-value">{offer?.totalAmount ?? 0}</div>
								</div>
							</Card>
							<Button
								style={{ width: '100%', marginTop: 20 }}
								onClick={() => {
									setSubmitted(true);
									handleSubmit();
								}}
								loading={isSubmitting}
							>
								Terminer
							</Button>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default OrderAddStep2;
