import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router';
import { Button, Card, FormStep, FormSteps } from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';

import './ImportAccounts.scss';

type ContextType = {
	headerKeys: string[];
	setHeaderKeys: React.Dispatch<React.SetStateAction<string[]>>;
};

const ImportAccounts = () => {
	const [headerKeys, setHeaderKeys] = useState<string[]>([]);

	return (
		<section className="labs-container fade">
			<Outlet context={{ headerKeys, setHeaderKeys }} />
		</section>
	);
};

export function useImportContext() {
	return useOutletContext<ContextType>();
}

export default ImportAccounts;
