/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
// import { notify } from '@sobrus-com/sobrus-design-system-v2';
import { CancelTokenSource } from 'axios';
import { createDataStorageName, loadNewData, setData, setDataLoading } from 'redux/features/tableDataSlice';
import { useAppDispatch, useAppSelector } from 'redux/reduxHooks';
import { API } from 'services';

import { OrderTypes, ParamsState, UseTableDataTypes } from './types';

// import API from '@/services/API';
// import { openIndexedDB } from '@/services/openIndexedDB';
/**
 * * This custom hook provides a comprehensive solution for fetching data, storing it in Redux, implementing pagination, applying sorting, filters, and more.
 * * Using two generic parameters to define types for data and search input values
 * @constructor
 * @param  url : api url to fetch data
 * @param  initialQuery : Add additional parameters to the GET query , The parameters should be provided as an Object containing values
 * @param  nameStorage : The data will be stored in Redux under the designated name, and if the updateInDb prop is set to true, it will also be updated in the database storage
 * @param  name : If the specified name doesn't exist, the data will be extracted from res.data; otherwise, it will be extracted from res?.data[name].
 * @param  initialFormDataValues : Defining the initial state for search inputs.
 * @param  dbName : Defining the initial state for search inputs when the fetched data needs to be stored in a database storage as options.
 * @param  option : A function to provide value and label pairs intended for storage in a database.
 */

export function useTableData<DataType extends object, SearchInput extends object, Metadata = void>({
	url,
	initialQuery,
	nameStorage,
	name,
	initialFormDataValues = {} as SearchInput,
	option,
	dbName,
	metadataName,
}: UseTableDataTypes<SearchInput, DataType>) {
	/** getData from redux */
	const dispatch = useAppDispatch();
	const { dataTables } = useAppSelector((state: any) => state.dataTable);
	const data = dataTables[nameStorage]?.data as DataType[];
	const loading = dataTables[nameStorage]?.loading;
	const noAnimation = dataTables[nameStorage]?.noAnimation;
	const loadingNewData = dataTables[nameStorage]?.loadingNewData;
	/** getData from redux */
	/** Initial query params  */
	const initialQueryState = {
		page: initialQuery?.page ?? 1,
		limit: initialQuery?.limit ?? 15,
		...initialQuery,
	};
	/** Initial query params  */
	/** get data from localStorage  */
	const dStorage = localStorage.getItem(nameStorage);
	const dataFromLocalstorage: ParamsState<SearchInput> = dStorage && JSON.parse(dStorage);
	/** get data from localStorage  */

	/** set data from localStorage  */
	const [params, setParams] = useState<ParamsState<SearchInput>>({
		formData: { ...initialFormDataValues, ...dataFromLocalstorage?.formData },
		queryState: dataFromLocalstorage?.queryState || initialQueryState,
	});
	/** set data from localStorage  */
	/** States */
	const [toggleSearch, setToggleSearch] = useState(false);
	const [toggleAdvancedSearch, setToggleAdvancedSearch] = useState(false);
	/** States */

	/**  react form hooks to control all serach inputs */
	const { handleSubmit, control, watch, reset, getValues, setValue } = useForm<SearchInput>({
		defaultValues: params.formData as DefaultValues<SearchInput>,
	});
	/**  react form hooks to control all serach inputs */

	const [metadata, setMetadata] = useState<null | Metadata>(null);

	/** fetch Function */
	const FetchDataGetMethode = useCallback(
		async (signal?: AbortSignal, cancelTokenSource?: CancelTokenSource) => {
			try {
				!data ? dispatch(createDataStorageName(nameStorage)) : dispatch(loadNewData(nameStorage));
				type FormDataKeyType = keyof typeof params.formData;
				const filters = { ...params.formData } as any;
				console.log('filters', filters);
				Object.keys(filters).map(
					(key) => !filters[key as FormDataKeyType] && delete filters[key as FormDataKeyType]
				);
				console.log('filters1', filters);
				// for (const [k] of Object.entries(filters)) {
				//     filters[k as FormDataKeyType].q = filters[k as FormDataKeyType]?.q?.trim();
				// }
				for (const [key, value] of Object.entries(filters)) {
					// console.log(`keyValue: ${key}: ${value[0]}`);
					if ((value as any)?.length > 0 && typeof value !== 'string') {
						filters[key as FormDataKeyType] = filters[key as FormDataKeyType].map((ele: string) => ele);
					} else if (typeof value === 'object') {
						filters[key as FormDataKeyType] = filters[key as FormDataKeyType].value;
					} else filters[key as FormDataKeyType] = filters[key as FormDataKeyType];
				}
				console.log('filters2', filters);
				const res: any = await API.get(`${url}`, {
					params: { ...params.queryState, ...filters },
					signal,
					cancelToken: cancelTokenSource?.token,
				});

				const response: DataType[] = name ? res?.data[name] : res?.data;
				const metadataResponse: Metadata = metadataName ? res?.data[metadataName] : [];
				dispatch(
					setData({
						[nameStorage]: response,
					})
				);
				setMetadata(metadataResponse);
				dispatch(setDataLoading(nameStorage));
				Object.keys(params).length > 0 && localStorage.setItem(nameStorage, JSON.stringify(params));
			} catch (err) {
				// err instanceof AxiosError && Sentry.captureException(err as AxiosError);
				console.log('asddddd');
				dispatch(setDataLoading(nameStorage));
			}
		},

		[dispatch, url, params, nameStorage, name]
	);
	/** fetch Function */

	/** execute fetch Function */
	useLayoutEffect(() => {
		// const initialOperations = Object.values(initialFormDataValues)?.map((el) => el.operation);
		// const storageOperations = dataFromLocalstorage?.formData
		//     ? Object.values(dataFromLocalstorage?.formData)?.map((el) => el.operation)
		//     : [];
		// const initialValues = Object.values(initialFormDataValues)?.map((el) => el.q);
		// const storageValues = dataFromLocalstorage?.formData
		//     ? Object.values(dataFromLocalstorage?.formData)?.map((el) => el.q)
		//     : [];
		if (
			JSON.stringify(initialFormDataValues) !== JSON.stringify(dataFromLocalstorage?.formData) &&
			dataFromLocalstorage?.formData &&
			Object.keys(dataFromLocalstorage?.formData)?.length > 0
		) {
			setToggleSearch(true);
		}
		// if (JSON.stringify(initialOperations) !== JSON.stringify(storageOperations) && storageOperations?.length > 0) {
		//     setToggleAdvancedSearch(true);
		// }
		const controller = new AbortController();
		const { signal } = controller;
		FetchDataGetMethode(signal);
		return () => controller.abort();
	}, [FetchDataGetMethode]);
	/** execute fetch Function */

	/** toggle display searche */
	const toggleSearchHandler = (): void => setToggleSearch(!toggleSearch);
	const toggleAdvancedSearchHandler = (): void => setToggleAdvancedSearch(!toggleAdvancedSearch);
	/** toggle display searche */

	/** refrech data  */
	function refrechHandler(): void {
		reset(initialFormDataValues);
		setParams({
			formData: initialFormDataValues,
			queryState: initialQueryState,
		});
	}

	/** refrech data */

	/** filter handler */
	const newOrder: { ASC: OrderTypes; DESC: OrderTypes } = {
		ASC: 'DESC',
		DESC: 'ASC',
	};
	/** filter handler */

	/** order handler */
	const orderHandler = (orderBy: string): void => {
		try {
			if (data.length > 0) {
				setParams((prev) => ({
					...prev,
					queryState: {
						...prev.queryState,
						orderBy,
						order:
							prev.queryState.orderBy === orderBy && prev.queryState.order
								? newOrder[prev.queryState.order]
								: 'DESC',
					},
				}));
			}
		} catch (error) {
			console.error(error);
		}
	};

	/** order handler */

	/** pagination */
	const prevHandler = (): void => {
		try {
			setParams((prev) => ({
				...prev,
				queryState: {
					...prev.queryState,
					page: prev.queryState.page - 1,
				},
			}));
		} catch (error) {
			console.error(error);
		}
	};

	const nextHandler = (): void => {
		try {
			setParams((prev) => ({
				...prev,
				queryState: {
					...prev.queryState,
					page: prev.queryState.page + 1,
				},
			}));
		} catch (error) {
			console.error(error);
		}
	};
	/** pagination */

	/** SubmitHandler format data befor resend request  */
	const submitHandler = useCallback(
		(values: SearchInput): void => {
			try {
				setParams({
					queryState: Object.keys(data).length > 0 ? { ...params?.queryState, page: 1 } : params?.queryState,
					formData: values,
				});
			} catch (error) {
				console.error(error);
			}
		},
		[data, params.queryState]
	);
	/** SubmitHandler format data befor resend request  */

	const searchOnChange = () => submitHandler(getValues());

	/** SubmitHandler on KeyPress enter   */
	const theadRef = useRef<HTMLTableSectionElement>(null);
	useEffect(() => {
		function handlekeydownEvent(event: KeyboardEvent) {
			const { key } = event;
			if (key === 'Enter') {
				event?.preventDefault();
				event?.stopPropagation();
				handleSubmit(submitHandler)();
			}
		}
		if (theadRef.current) {
			theadRef.current.addEventListener('keydown', handlekeydownEvent);
		}
		return () => {
			theadRef.current && theadRef.current.removeEventListener('keydown', handlekeydownEvent);
		};
	}, [handleSubmit, submitHandler]);
	/** SubmitHandler on KeyPress enter   */

	const [formLoading, setFormLoading] = useState(false);

	/** store data in db storage if dbName is defined */
	return {
		data,
		loadingNewData,
		loading,
		toggleSearch,
		params,
		refrechHandler,
		toggleSearchHandler,
		handleSubmit,
		submitHandler,
		getValues,
		setValue,
		watch,
		control,
		orderHandler,
		prevHandler,
		nextHandler,
		noAnimation,
		setParams,
		formLoading,
		toggleAdvancedSearch,
		toggleAdvancedSearchHandler,
		theadRef,
		searchOnChange,
		FetchDataGetMethode,
		setToggleSearch,
		metadata,
	};
}
