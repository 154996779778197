import { Input } from '@sobrus-com/sobrus-design-system-v2';
import React from 'react';
import './CustomInputWithMax.scss';

type propsTypes = {
	maxValue?: number;
	[props: string]: any;
};

const CustomInputWithMax: React.FC<propsTypes> = ({ maxValue, ...props }) => {
	return (
		<div className="custom-input-maxValue">
			<Input {...props} />
			<div className="custom-input-maxValue__value">{maxValue ? '/' + maxValue : ''}</div>
		</div>
	);
};

export default CustomInputWithMax;
