import { AccordionCard, Button } from '@sobrus-com/sobrus-design-system-v2';
import React from 'react';

const PaymentMethod = () => {
	return (
		<>
			<AccordionCard
				headerTitle={
					<div className="pub-sold-accord-header">
						<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M2.25 10.1919H4.5V16.1919H3C2.80109 16.1919 2.61032 16.2709 2.46967 16.4116C2.32902 16.5522 2.25 16.743 2.25 16.9419C2.25 17.1408 2.32902 17.3316 2.46967 17.4722C2.61032 17.6129 2.80109 17.6919 3 17.6919H21C21.1989 17.6919 21.3897 17.6129 21.5303 17.4722C21.671 17.3316 21.75 17.1408 21.75 16.9419C21.75 16.743 21.671 16.5522 21.5303 16.4116C21.3897 16.2709 21.1989 16.1919 21 16.1919H19.5V10.1919H21.75C21.9132 10.1918 22.0719 10.1384 22.202 10.0399C22.3321 9.94136 22.4265 9.80311 22.4709 9.64609C22.5153 9.48907 22.5073 9.32184 22.4481 9.16978C22.3889 9.01771 22.2817 8.88911 22.1428 8.80348L12.3928 2.80348C12.2747 2.73084 12.1387 2.69238 12 2.69238C11.8613 2.69238 11.7253 2.73084 11.6072 2.80348L1.85719 8.80348C1.71828 8.88911 1.61108 9.01771 1.55187 9.16978C1.49266 9.32184 1.48466 9.48907 1.52908 9.64609C1.57351 9.80311 1.66793 9.94136 1.79803 10.0399C1.92814 10.1384 2.08681 10.1918 2.25 10.1919ZM6 10.1919H9V16.1919H6V10.1919ZM13.5 10.1919V16.1919H10.5V10.1919H13.5ZM18 16.1919H15V10.1919H18V16.1919ZM12 4.32223L19.1006 8.69192H4.89937L12 4.32223ZM23.25 19.9419C23.25 20.1408 23.171 20.3316 23.0303 20.4722C22.8897 20.6129 22.6989 20.6919 22.5 20.6919H1.5C1.30109 20.6919 1.11032 20.6129 0.96967 20.4722C0.829018 20.3316 0.75 20.1408 0.75 19.9419C0.75 19.743 0.829018 19.5522 0.96967 19.4116C1.11032 19.2709 1.30109 19.1919 1.5 19.1919H22.5C22.6989 19.1919 22.8897 19.2709 23.0303 19.4116C23.171 19.5522 23.25 19.743 23.25 19.9419Z"
								fill="#CC3244"
							/>
						</svg>
						Recharge par virement bancaire
					</div>
				}
			>
				<div>
					<div className="pub-accord-content">
						<div className="pub-accord-number">1</div>
						<div className="pub-accord-info">
							<p>
								Pour recharger votre solde publicitaire Sobrus Labs par virement bancaire, nous vous
								invitons à envoyer le montant souhaité à ajouter à votre solde sur le compte suivant :
							</p>
							<ul>
								<li>Banque : CFG BANK</li>
								<li>Nom : Sobrus SARL</li>
								<li>RIB : 050 810 0140109583392001 80</li>
							</ul>
						</div>
					</div>
					<div className="pub-accord-content">
						<div className="pub-accord-number">2</div>
						<div className="pub-accord-info">
							<p>
								Une fois le virement effectué, vous nous envoyez l&apos;accusé de paiement de votre
								banque par e-mail à l&apos;adresse suivante (support@sobrus.com) ou par Whatsapp sur le
								numéro suivant (06 61 39 85 46) afin de saisir les références du paiement et mettre à
								jour votre situation.
							</p>
						</div>
					</div>
				</div>
			</AccordionCard>
			<AccordionCard
				style={{ marginTop: 16 }}
				headerTitle={
					<div className="pub-sold-accord-header">
						<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M21 4.94238H3C2.60218 4.94238 2.22064 5.10042 1.93934 5.38172C1.65804 5.66303 1.5 6.04456 1.5 6.44238V18.4424C1.5 18.8402 1.65804 19.2217 1.93934 19.503C2.22064 19.7843 2.60218 19.9424 3 19.9424H21C21.3978 19.9424 21.7794 19.7843 22.0607 19.503C22.342 19.2217 22.5 18.8402 22.5 18.4424V6.44238C22.5 6.04456 22.342 5.66303 22.0607 5.38172C21.7794 5.10042 21.3978 4.94238 21 4.94238ZM21 6.44238V8.69238H3V6.44238H21ZM21 18.4424H3V10.1924H21V18.4424ZM19.5 16.1924C19.5 16.3913 19.421 16.5821 19.2803 16.7227C19.1397 16.8634 18.9489 16.9424 18.75 16.9424H15.75C15.5511 16.9424 15.3603 16.8634 15.2197 16.7227C15.079 16.5821 15 16.3913 15 16.1924C15 15.9935 15.079 15.8027 15.2197 15.6621C15.3603 15.5214 15.5511 15.4424 15.75 15.4424H18.75C18.9489 15.4424 19.1397 15.5214 19.2803 15.6621C19.421 15.8027 19.5 15.9935 19.5 16.1924ZM13.5 16.1924C13.5 16.3913 13.421 16.5821 13.2803 16.7227C13.1397 16.8634 12.9489 16.9424 12.75 16.9424H11.25C11.0511 16.9424 10.8603 16.8634 10.7197 16.7227C10.579 16.5821 10.5 16.3913 10.5 16.1924C10.5 15.9935 10.579 15.8027 10.7197 15.6621C10.8603 15.5214 11.0511 15.4424 11.25 15.4424H12.75C12.9489 15.4424 13.1397 15.5214 13.2803 15.6621C13.421 15.8027 13.5 15.9935 13.5 16.1924Z"
								fill="#CC3244"
							/>
						</svg>
						Recharge par carte bancaire
					</div>
				}
			>
				<div>
					<div className="pub-accord-content">
						<div className="pub-accord-number">1</div>
						<div className="pub-accord-info">
							<p>
								Pour recharger votre solde publicitaire Sobrus Labs par carte bancaire, nous vous
								invitons à cliquer sur le bouton ci-dessous et vous serez redirigé vers la page de
								paiement sécurisé.
							</p>
							<Button style={{ marginTop: 16 }} color="primary">
								Recharger mon solde
							</Button>
						</div>
					</div>
					<div className="pub-accord-content">
						<div className="pub-accord-number">2</div>
						<div className="pub-accord-info">
							<p>Tous les types de cartes bancaires sont acceptés (Visa, MasterCard, CIM...)</p>
							<div>
								<img src={require('./bank-cards.png')} alt="" />
							</div>
						</div>
					</div>
				</div>
			</AccordionCard>
		</>
	);
};

export default PaymentMethod;
