import { EmptyData, Table, TableContainer, Tag, Th } from '@sobrus-com/sobrus-design-system-v2';
import { format, parseISO } from 'date-fns';
import React from 'react';

const PubInvoices = ({ data }: { data: any[] }) => {
	return (
		<TableContainer>
			<Table>
				<thead>
					<tr>
						<Th hideOrder>N° de transaction</Th>
						<Th hideOrder>Date</Th>
						<Th hideOrder>Montant</Th>
						<Th hideOrder>Statut</Th>
						<Th hideOrder>Facture</Th>
						{/* <Th>Action</Th> */}
					</tr>
				</thead>
				<tbody>
					{data &&
						data?.length > 0 &&
						data?.map((ele) => (
							<tr key={ele?.id}>
								<td>{ele?.transactionNumber ?? '--'}</td>
								<td>{ele?.createdAt ? format(parseISO(ele?.createdAt), 'yyyy-MM-dd HH:mm') : '--'}</td>
								<td>{ele?.amount ?? '0'} MAD</td>
								<td>
									{ele?.status === 'pending' ? (
										<Tag bgColor="#FEEAD6" textColor="#E54911">
											En attente
										</Tag>
									) : (
										<Tag bgColor="#D9FCDF" textColor="#0EA45F">
											Payé
										</Tag>
									)}
								</td>
								<td>-</td>
							</tr>
						))}
				</tbody>
				<tfoot>
					{/* {data?.length > 0 && (
						<tr>
							<td colSpan={10}>
								<div className="sob-v2-table-pagination">
									<TablePreviousPage onClick={prevHandler} disabled={page <= 1} />
									<TablePage>{page}</TablePage>
									<TableNextPage onClick={nextHandler} disabled={data?.length < 15} />
								</div>
							</td>
						</tr>
					)} */}
					{(!data || data?.length <= 0) && (
						<tr>
							<td colSpan={20}>
								<EmptyData
									style={{
										minHeight: 300,
									}}
									title="Aucune facture pour l'instant"
									desc="La liste de vos futures factures sera affichée ici"
								/>
							</td>
						</tr>
					)}
				</tfoot>
			</Table>
		</TableContainer>
	);
};

export default PubInvoices;
