import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
	Button,
	FormGroup,
	IconButton,
	Input,
	Label,
	notify,
	TableContainer,
	TableHeader,
	TableHeaderTitle,
} from '@sobrus-com/sobrus-design-system-v2';

import { useAcl } from '../../../ACL/acl';
import { axiosGet, axiosPatch, axiosPost, axiosPut } from '../../../api/API';
import InputPopUp from '../../../components/inputPopUp/InputPopUp';
// import usePagination from "../../../utils/usePagination";

function Roles() {
	const [isFetchingRoles, setIsFetchingRoles] = useState(true);
	const [roles, setRoles] = useState(null);
	const [roleName, setRoleName] = useState(null);
	const [roleId, setRoleId] = useState(null);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const acl = useAcl();
	const { t } = useTranslation();

	// const { pageNumber, pageChange, setPageNumber } = usePagination(setIsFetchingRoles);

	useEffect(() => {
		if (isFetchingRoles) {
			axiosGet(`roles`).then((res) => {
				setRoles(res?.data?.roles);
				setIsFetchingRoles(false);
			});
			if (acl?.setting?.acl_roles?.getDefaultRoles) axiosGet(`rolepardefaut`);
		}
	}, [isFetchingRoles]);

	const editRole = () => {
		if (roleId) {
			axiosPatch(`roles/${roleId}`, { name: roleName }).then((res) => {
				if (res?.data) {
					notify({
						position: 'top-center',
						type: 'success',
						msg: 'Le rôle a été modifié avec succès',
						delay: 5000,
					});
					setIsFetchingRoles(true);
				} else {
					notify({ position: 'top-center', type: 'danger', msg: "Le rôle n'a pas été modifié", delay: 5000 });
				}
			});
		} else {
			axiosPost(`roles`, { name: roleName }).then((res) => {
				if (res?.data) {
					notify({
						position: 'top-center',
						type: 'success',
						msg: 'Le rôle a été créé avec succès',
						delay: 5000,
					});
					setIsFetchingRoles(true);
				} else {
					notify({ position: 'top-center', type: 'danger', msg: "Le rôle n'a pas été créé", delay: 5000 });
				}
			});
		}
	};

	return (
		<TableContainer className="fade">
			<div className="labs-settings-btns">
				{acl?.setting?.acl_roles?.add && (
					<Button
						onClick={() => {
							setOpen(true);
							setRoleId(null);
							setRoleName(null);
						}}
					>
						{t('New role')}
					</Button>
				)}
			</div>
			<TableHeader>
				<TableHeaderTitle>{t('Roles')}</TableHeaderTitle>
			</TableHeader>
			<div className="roles-cards">
				{!isFetchingRoles &&
					roles?.length > 0 &&
					roles?.map((ele) => (
						<div className="roles-card" key={`${ele.id}roles`}>
							<div>{ele.name}</div>
							<div className="roles-btns">
								{acl?.setting?.acl_roles?.update && (
									<IconButton
										onClick={() => {
											setOpen(true);
											setRoleName(ele?.name);
											setRoleId(ele?.id);
										}}
										color="tertiary"
									>
										<svg
											width="20"
											height="21"
											viewBox="0 0 20 21"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12.6935 4.8016L15.6398 7.74788M13.9435 3.5516C14.7571 2.73801 16.0762 2.73801 16.8898 3.5516C17.7034 4.36519 17.7034 5.68429 16.8898 6.49788L5.41667 17.971H2.5V14.9951L13.9435 3.5516Z"
												stroke="#292929"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</IconButton>
								)}
								{acl?.setting?.acl_roles?.affect_methods && (
									<IconButton onClick={() => navigate(`/settings/roles/${ele?.id}`)} color="tertiary">
										<svg
											width="20"
											height="21"
											viewBox="0 0 20 21"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M8.32463 2.75872C8.75103 1.0023 11.249 1.0023 11.6754 2.75872C11.9508 3.89334 13.2507 4.43178 14.2478 3.82426C15.7913 2.8838 17.5576 4.65011 16.6172 6.19359C16.0096 7.19066 16.5481 8.49058 17.6827 8.76603C19.4391 9.19243 19.4391 11.6904 17.6827 12.1168C16.5481 12.3922 16.0096 13.6922 16.6172 14.6892C17.5576 16.2327 15.7913 17.999 14.2478 17.0586C13.2507 16.451 11.9508 16.9895 11.6754 18.1241C11.249 19.8805 8.75103 19.8805 8.32463 18.1241C8.04918 16.9895 6.74926 16.451 5.75219 17.0586C4.2087 17.999 2.44239 16.2327 3.38285 14.6892C3.99038 13.6922 3.45193 12.3922 2.31731 12.1168C0.560897 11.6904 0.560897 9.19243 2.31731 8.76603C3.45193 8.49058 3.99037 7.19066 3.38285 6.19359C2.44239 4.65011 4.2087 2.8838 5.75219 3.82426C6.74926 4.43178 8.04918 3.89334 8.32463 2.75872Z"
												stroke="#292929"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M13 10.4414C13 12.0983 11.6569 13.4414 10 13.4414C8.34315 13.4414 7 12.0983 7 10.4414C7 8.78455 8.34315 7.44141 10 7.44141C11.6569 7.44141 13 8.78455 13 10.4414Z"
												stroke="#292929"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</IconButton>
								)}
							</div>
						</div>
					))}
				{open && (
					<InputPopUp
						title={roleId ? t('Edit role') : t('Create new role')}
						isOpen={open}
						setIsOpen={setOpen}
						answerCallback={editRole}
					>
						<FormGroup>
							<Label>{t('Name')}</Label>
							<Input
								id="rolename"
								name="rolename"
								placeholder={t('Name')}
								type="text"
								value={roleName}
								onChange={(e) => setRoleName(e.target.value)}
							/>
						</FormGroup>
					</InputPopUp>
				)}
			</div>
		</TableContainer>
	);
}

export default Roles;
