import React, { Suspense, lazy, useContext } from 'react';
import ImportAccounts from 'pages/comptes/importAccounts/ImportAccounts';
import ImportAccountsStep1 from 'pages/comptes/importAccounts/ImportAccountsStep1';
import ImportAccountsStep2 from 'pages/comptes/importAccounts/ImportAccountsStep2';
import ImportAccountsStep3 from 'pages/comptes/importAccounts/ImportAccountsStep3';
import ImportAccountsResults from 'pages/comptes/importAccounts/ImportAccountsResults';
import ImportsHistory from 'pages/comptes/importAccounts/ImportsHistory';
import Failed from 'pages/Failed';
import Satisfactions from 'pages/satisfactionPage/Satisfactions';
import SatisfactionsFailure from 'pages/satisfactionPage/SatisfactionsFailure';
import SatisfactionsSuccess from 'pages/satisfactionPage/SatisfactionsSuccess';
import AccountCategory from 'pages/settings/accountSettings/AccountCategory';
import APISettings from 'pages/settings/API&Integration/APISettings';
import ContactCategory from 'pages/settings/contactSettings/ContactCategory';
import MarketPlaceEmail from 'pages/settings/emailParams/MarketPlaceEmail';
import MarketPlaceHistory from 'pages/settings/emailParams/MarketPlaceHistory';
import PublicationsEmail from 'pages/settings/emailParams/PublicationsEmail';
import PublicationsHistory from 'pages/settings/emailParams/PublicationsHistory';
import CustomFields from 'pages/settings/eventsSettings/CustomFields';
import CustomReportdnd from 'pages/settings/eventsSettings/CustomReportdnd';
import AccessDenied from '../pages/AccessDenied';
import NotAuthorised from 'pages/NotAuthorised';
import TableauDeBord from '../pages/dashboard/TableauDeBord';
import Notifications from '../pages/notifications/Notifications';
import NotifsHistoric from '../pages/notifications/NotifsHistoric';
import Rapports from '../pages/rapports/Rapports';
import EmailIn from '../pages/settings/emailSettings/EmailIn';
import EmailOut from '../pages/settings/emailSettings/emailOut/EmailOut';
import ModelCreation from '../pages/settings/emailSettings/emailOut/ModelCreation';
import Gammes from '../pages/settings/gammes/Gammes';
import GammesCheck from '../pages/settings/gammes/GammesCheck';
import HistoricConnexion from '../pages/settings/HistoricConnexion';
import Methodes from '../pages/settings/methodes/Methodes';
import Profile from '../pages/settings/mySettings/Profile';
import Roles from '../pages/settings/roles/Roles';
import RolesEdit from '../pages/settings/roles/RolesEdit';
import Settings from '../pages/settings/Settings';
import EditUser from '../pages/settings/users/EditUser';
import UsersTable from '../pages/settings/users/UsersTable';
import Signin from '../pages/Signin';
import OrderAdd from 'pages/ventes/orders/orderAdd/OrderAdd';
import OrderAddStep1 from 'pages/ventes/orders/orderAdd/OrderAddStep1';
import OrderAddStep2 from 'pages/ventes/orders/orderAdd/OrderAddStep2';
import OrderAddStep3 from 'pages/ventes/orders/orderAdd/OrderAddStep3';

import { PrivateRoute } from './RouteType';
import { Navigate, Route, Routes } from 'react-router';
import { SpinnerLoading } from '@sobrus-com/sobrus-design-system-v2';
import { useAcl } from 'ACL/acl';
import { UserContext } from 'context/userContext';
import NotifsEmail from 'pages/settings/emailSettings/notifs/NotifsEmail';
import PubBalance from 'pages/settings/pubBalance/PubBalance';
import SupplierDetails from 'pages/settings/ventes/supplier/SupplierDetails';
import DelegateList from 'pages/settings/ventes/delegate/DelegateList';
// products
const Page404 = lazy(() => import('pages/404/404'));
// products
const Products = lazy(() => import('../pages/products/Products'));
const ProductDetail = lazy(() => import('../pages/products/ProductDetail'));
const ProductSuggDetails = lazy(() => import('../pages/products/ProductSuggDetails'));
const SuggestProduct = lazy(() => import('../pages/products/SuggestProduct'));
const HistoricProducts = lazy(() => import('../pages/products/HistoricProducts'));
// // pharmacies
const Pharmacies = lazy(() => import('../pages/ressources/pharmacies/Pharmacies'));
const PharmaciesDetail = lazy(() => import('../pages/ressources/pharmacies/PharmaciesDetail'));
const SuggestPharmacie = lazy(() => import('../pages/ressources/pharmacies/SuggestPharmacie'));
const HistoricPharmacie = lazy(() => import('../pages/ressources/pharmacies/HistoricPharmacie'));
const PharmacieSuggDetails = lazy(() => import('../pages/ressources/pharmacies/PharmacieSuggDetails'));
// // Associations
const Associations = lazy(() => import('../pages/ressources/associations/Associations'));
const AssociationsDetail = lazy(() => import('../pages/ressources/associations/AssociationsDetail'));
const SuggestAssociation = lazy(() => import('../pages/ressources/associations/SuggestAssociation'));
const HistoricAssociations = lazy(() => import('../pages/ressources/associations/HistoricAssociations'));
const AssocSuggDetails = lazy(() => import('../pages/ressources/associations/AssocSuggDetails'));
// // centers
const Centers = lazy(() => import('../pages/ressources/centers/Centers'));
const CentersDetail = lazy(() => import('../pages/ressources/centers/CentersDetail'));
const SuggestCenter = lazy(() => import('../pages/ressources/centers/SuggestCenter'));
const HistoricCenters = lazy(() => import('../pages/ressources/centers/HistoricCenters'));
const CentersSuggDetails = lazy(() => import('../pages/ressources/centers/CentersSuggDetails'));
// // Comptes
const Comptes = lazy(() => import('../pages/comptes/Comptes'));
const ComptesDetail = lazy(() => import('../pages/comptes/ComptesDetail'));
const CompteCreation = lazy(() => import('../pages/comptes/CompteCreation'));
const ComptesArchive = lazy(() => import('../pages/comptes/ComptesArchive'));
// // Contact
const Contacts = lazy(() => import('../pages/contacts/Contacts'));
const ContactsDetail = lazy(() => import('../pages/contacts/ContactsDetail'));
const ContactCreation = lazy(() => import('../pages/contacts/ContactCreation'));
const ContactsArchive = lazy(() => import('../pages/contacts/ContactsArchive'));
// // Plannings
const PlanningDetail = lazy(() => import('../pages/planning/PlanningDetail'));
const PlanningCreation = lazy(() => import('../pages/planning/PlanningCreation'));
const PlanningArchive = lazy(() => import('../pages/planning/PlanningArchive'));
const PlanningEventsAdd = lazy(() => import('../pages/planning/PlanningEventsAdd'));
const PlanningEventCreation = lazy(() => import('../pages/planning/PlanningEventCreation'));
const Planning = lazy(() => import('../pages/planning/Planning'));
const PlanningDuplicate = lazy(() => import('../pages/planning/PlanningDuplicate'));
// // Demandes
const Demandes = lazy(() => import('../pages/demandes/Demandes'));
const DemandeDetails = lazy(() => import('../pages/demandes/DemandeDetails'));
const DemandeCreation = lazy(() => import('../pages/demandes/DemandeCreation'));
// // Galerie
const Galerie = lazy(() => import('../pages/galerie/Galerie'));
const GalerieDetail = lazy(() => import('../pages/galerie/GalerieDetail'));
const GalerieArchive = lazy(() => import('../pages/galerie/GalerieArchive'));
const GalerieCreation = lazy(() => import('../pages/galerie/GalerieCreation'));
// Calender
const Calender = lazy(() => import('../pages/calender/Calender'));
const CalenderDetail = lazy(() => import('../pages/calender/CalenderDetail'));
const CalenderCreation = lazy(() => import('../pages/calender/CalenderCreation'));
const PostponeEvent = lazy(() => import('../pages/calender/PostponeEvent'));
const RapportCreation = lazy(() => import('../pages/calender/RapportCreation'));
// Loyalty programs
const Programs = lazy(() => import('pages/loyalty/loyaltyPrograms/Programs'));
const ProgramsDetails = lazy(() => import('pages/loyalty/loyaltyPrograms/ProgramsDetails'));
const ProgramsCreation = lazy(() => import('pages/loyalty/loyaltyPrograms/ProgramsCreation'));
// Loyalty memberships
const Memberships = lazy(() => import('pages/loyalty/loyaltyMemberships/Memberships'));
const MembershipsDetails = lazy(() => import('pages/loyalty/loyaltyMemberships/MembershipsDetails'));
const MembershipsCreation = lazy(() => import('pages/loyalty/loyaltyMemberships/MembershipsCreation'));
// // Publications
const Publications = lazy(() => import('../pages/publications/Publications'));
const PubCreation = lazy(() => import('../pages/publications/PubCreation'));
const PublicationDetails = lazy(() => import('../pages/publications/PublicationDetails'));
// // Offers
const Offers = lazy(() => import('../pages/ventes/Offers/Offers'));
const OfferDetails = lazy(() => import('../pages/ventes/Offers/offerDetails/OfferDetails'));
const OfferCreation = lazy(() => import('../pages/ventes/Offers/offerAdd/OfferCreation'));
const OffersView = lazy(() => import('../pages/ventes/Offers/OffersView'));
// // Orders
const Orders = lazy(() => import('../pages/ventes/orders/Orders'));
const OrderDetails = lazy(() => import('../pages/ventes/orders/orderDetails/OrderDetails'));
// Stores
const CustomStores = lazy(() => import('pages/ventes/stores/CustomStores'));
const CustomStoresCreation = lazy(() => import('pages/ventes/stores/CustomStoresCreation'));
// // Rapports
const REventPerCanal = lazy(() => import('../pages/rapports/RapportEvent/REventPerCanal'));
const REventPerObj = lazy(() => import('../pages/rapports/RapportEvent/REventPerObj'));
const REventPerCat = lazy(() => import('../pages/rapports/RapportEvent/REventPerCat'));
const REventGeneral = lazy(() => import('../pages/rapports/RapportEvent/REventGeneral'));
const RContactPerUser = lazy(() => import('../pages/rapports/rapportContact/RContactPerUser'));
const RPosts = lazy(() => import('../pages/rapports/rapportPosts/RPosts'));
const RMarketplace = lazy(() => import('../pages/rapports/rapportMarketplace/rapportMarketplace/RMarketplace'));
const CommercialPerformance = lazy(
	() => import('pages/rapports/rapportMarketplace/commercialPerformance/CommercialPerformance')
);
// import NewMembre from "../pages/groups/NewMembre";

const Root = () => {
	const { isLogin, user, numberNotifs } = useContext(UserContext);
	const acl = useAcl(user);

	return (
		<Routes>
			<Route path="/" element={<PrivateRoute component={<TableauDeBord />} acl=" " />} />
			{/* Notifications */}
			<Route
				path="/notifications"
				element={<PrivateRoute component={<Notifications />} acl={acl?.notification?.list} />}
			/>
			<Route
				path="/notifications/history"
				element={<PrivateRoute component={<NotifsHistoric />} acl={acl?.notification?.list_history} />}
			/>
			{/* Products */}
			<Route
				path="/products"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Products />
							</Suspense>
						}
						acl={acl?.product.list}
					/>
				}
			/>
			<Route
				path="/products/history"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<HistoricProducts />
							</Suspense>
						}
						acl={acl?.product?.suggestion?.list}
					/>
				}
			/>
			<Route
				path="/products/suggestion/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ProductSuggDetails />
							</Suspense>
						}
						acl={acl?.product?.suggestion?.details}
					/>
				}
			/>
			<Route
				path="/products/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ProductDetail />
							</Suspense>
						}
						acl={acl?.product?.details}
					/>
				}
			/>
			<Route
				path="/products/suggest"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<SuggestProduct />
							</Suspense>
						}
						acl={acl?.product?.suggestion?.add}
					/>
				}
			/>
			<Route
				path="/products/suggest/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<SuggestProduct />
							</Suspense>
						}
						acl={acl?.product?.suggestion?.add}
					/>
				}
			/>
			{/* Pharmacie */}
			<Route
				path="/ressources/pharmacies"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Pharmacies />
							</Suspense>
						}
						acl={acl?.resource?.list_pharmacies}
					/>
				}
			/>
			<Route
				path="/ressources/pharmacies/history"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<HistoricPharmacie />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.pharmacy?.list}
					/>
				}
			/>
			<Route
				path="/ressources/pharmacies/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PharmaciesDetail />
							</Suspense>
						}
						acl={acl?.resource?.pharmacy_details}
					/>
				}
			/>
			<Route
				path="/ressources/pharmacies/suggestion/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PharmacieSuggDetails />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.pharmacy?.details}
					/>
				}
			/>
			<Route
				path="/ressources/pharmacies/suggest"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<SuggestPharmacie />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.pharmacy?.add}
					/>
				}
			/>
			<Route
				path="/ressources/pharmacies/suggest/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<SuggestPharmacie />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.pharmacy?.add}
					/>
				}
			/>
			{/* Centres */}
			<Route
				path="/ressources/centers"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Centers />
							</Suspense>
						}
						acl={acl?.resource?.list_associations}
					/>
				}
			/>
			<Route
				path="/ressources/centers/history"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<HistoricCenters />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.healthcareestablishments?.list}
					/>
				}
			/>

			<Route
				path="/ressources/centers/suggest"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<SuggestCenter />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.healthcareestablishments?.add}
					/>
				}
			/>
			<Route
				path="/ressources/centers/suggest/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<SuggestCenter />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.healthcareestablishments?.add}
					/>
				}
			/>
			<Route
				path="/ressources/centers/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CentersDetail />
							</Suspense>
						}
						acl={acl?.resource?.healthcareestablishments_details}
					/>
				}
			/>
			<Route
				path="/ressources/centers/suggestion/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CentersSuggDetails />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.healthcareestablishments?.details}
					/>
				}
			/>
			{/* Associations */}
			<Route
				path="/ressources/associations"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Associations />
							</Suspense>
						}
						acl={acl?.resource?.list_associations}
					/>
				}
			/>
			<Route
				path="/ressources/associations/history"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<HistoricAssociations />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.associations?.list}
					/>
				}
			/>
			<Route
				path="/ressources/associations/suggest"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<SuggestAssociation />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.associations?.add}
					/>
				}
			/>
			<Route
				path="/ressources/associations/suggest/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<SuggestAssociation />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.associations?.add}
					/>
				}
			/>
			<Route
				path="/ressources/associations/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<AssociationsDetail />
							</Suspense>
						}
						acl={acl?.resource?.associations_details}
					/>
				}
			/>
			<Route
				path="/ressources/associations/suggestion/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<AssocSuggDetails />
							</Suspense>
						}
						acl={acl?.resource?.suggestion?.associations?.details}
					/>
				}
			/>
			{/* Comptes */}
			<Route
				path="/accounts"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Comptes />
							</Suspense>
						}
						acl={acl?.account?.list}
					/>
				}
			/>
			<Route
				path="/accounts/archive"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ComptesArchive />
							</Suspense>
						}
						acl={acl?.account?.list}
					/>
				}
			/>
			<Route
				path="/accounts/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CompteCreation />
							</Suspense>
						}
						acl={acl?.account?.add}
					/>
				}
			/>
			<Route
				path="/accounts/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CompteCreation />
							</Suspense>
						}
						acl={acl?.account?.update}
					/>
				}
			/>
			<Route
				path="/accounts/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ComptesDetail />
							</Suspense>
						}
						acl={acl?.account?.details}
					/>
				}
			/>
			<Route path="/accounts/import" element={<PrivateRoute component={<ImportAccounts />} acl={' '} />}>
				<Route index element={<ImportAccountsStep1 />} />
				<Route path="/accounts/import/step1" element={<ImportAccountsStep1 />} />
				<Route path="/accounts/import/step2/:id" element={<ImportAccountsStep2 />} />
				<Route path="/accounts/import/step3/:id" element={<ImportAccountsStep3 />} />
				<Route path="/accounts/import/results/:id" element={<ImportAccountsResults />} />
			</Route>
			<Route
				path="/accounts/import/history"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ImportsHistory />
							</Suspense>
						}
						acl={acl?.account?.details}
					/>
				}
			/>
			{/* Contacts */}
			<Route
				path="/contacts"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Contacts />
							</Suspense>
						}
						acl={acl?.contact?.list}
					/>
				}
			/>
			<Route
				path="/contacts/archive"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ContactsArchive />
							</Suspense>
						}
						acl={acl?.contact?.list}
					/>
				}
			/>
			<Route
				path="/contacts/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ContactCreation />
							</Suspense>
						}
						acl={acl?.contact?.add}
					/>
				}
			/>
			<Route
				path="/contacts/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ContactCreation />
							</Suspense>
						}
						acl={acl?.contact?.update}
					/>
				}
			/>
			<Route
				path="/contacts/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ContactsDetail />
							</Suspense>
						}
						acl={acl?.contact?.details}
					/>
				}
			/>
			{/* Loyalty -> Programs */}
			<Route
				path="/loyalty/programs"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Programs />
							</Suspense>
						}
						acl={acl?.publication?.list}
					/>
				}
			/>
			<Route
				path="/loyalty/programs/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ProgramsCreation />
							</Suspense>
						}
						acl={acl?.publication?.add}
					/>
				}
			/>
			<Route
				path="/loyalty/programs/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ProgramsCreation />
							</Suspense>
						}
						acl={acl?.publication?.update}
					/>
				}
			/>
			<Route
				path="/loyalty/programs/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<ProgramsDetails />
							</Suspense>
						}
						acl={acl?.publication?.single}
					/>
				}
			/>
			{/* Loyalty -> Memberships */}
			<Route
				path="/loyalty/memberships"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Memberships />
							</Suspense>
						}
						acl={acl?.publication?.list}
					/>
				}
			/>
			<Route
				path="/loyalty/memberships/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<MembershipsCreation />
							</Suspense>
						}
						acl={acl?.publication?.add}
					/>
				}
			/>
			<Route
				path="/loyalty/memberships/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<MembershipsCreation />
							</Suspense>
						}
						acl={acl?.publication?.update}
					/>
				}
			/>
			<Route
				path="/loyalty/memberships/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<MembershipsDetails />
							</Suspense>
						}
						acl={acl?.publication?.single}
					/>
				}
			/>
			{/* Publications */}
			<Route
				path="/campaigns"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Publications />
							</Suspense>
						}
						acl={acl?.publication?.list}
					/>
				}
			/>
			<Route
				path="/campaigns/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PubCreation />
							</Suspense>
						}
						acl={acl?.publication?.add}
					/>
				}
			/>
			<Route
				path="/campaigns/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PubCreation />
							</Suspense>
						}
						acl={acl?.publication?.update}
					/>
				}
			/>
			<Route
				path="/campaigns/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PublicationDetails />
							</Suspense>
						}
						acl={acl?.publication?.single}
					/>
				}
			/>
			{/* Planning */}
			<Route
				path="/planning"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Planning />
							</Suspense>
						}
						acl={acl?.planning?.list}
					/>
				}
			/>
			<Route
				path="/planning/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PlanningDetail />
							</Suspense>
						}
						acl={acl?.planning?.details}
					/>
				}
			/>
			<Route
				path="/planning/archive"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PlanningArchive />
							</Suspense>
						}
						acl={acl?.planning?.list}
					/>
				}
			/>
			<Route
				path="/planning/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PlanningCreation />
							</Suspense>
						}
						acl={acl?.planning?.add}
					/>
				}
			/>
			<Route
				path="/planning/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PlanningCreation />
							</Suspense>
						}
						acl={acl?.planning?.update}
					/>
				}
			/>
			<Route
				path="/planning/duplicate/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PlanningDuplicate />
							</Suspense>
						}
						acl={acl?.planning?.add}
					/>
				}
			/>
			<Route
				path="/planning/event/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PlanningEventsAdd />
							</Suspense>
						}
						acl={acl?.planning?.event_to_planning}
					/>
				}
			/>
			<Route
				path="/planning/new-event/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PlanningEventCreation />
							</Suspense>
						}
						acl={acl?.planning?.new_event_to_planning}
					/>
				}
			/>
			{/* Galerie */}
			<Route
				path="/gallery"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Galerie />
							</Suspense>
						}
						acl={acl?.gallery?.list}
					/>
				}
			/>
			<Route
				path="/gallery/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<GalerieDetail />
							</Suspense>
						}
						acl={acl?.gallery?.details}
					/>
				}
			/>
			<Route
				path="/gallery/archive"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<GalerieArchive />
							</Suspense>
						}
						acl={acl?.gallery?.list}
					/>
				}
			/>
			<Route
				path="/gallery/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<GalerieCreation />
							</Suspense>
						}
						acl={acl?.gallery?.add}
					/>
				}
			/>
			<Route
				path="/gallery/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<GalerieCreation />
							</Suspense>
						}
						acl={acl?.gallery?.update}
					/>
				}
			/>
			{/* Calender */}
			<Route
				path="/calendar"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Calender />
							</Suspense>
						}
						acl={acl?.calender?.list}
					/>
				}
			/>
			<Route
				path="/calendar/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CalenderDetail />
							</Suspense>
						}
						acl={acl?.calender?.details}
					/>
				}
			/>
			{/* <Route path="/calendar/archive" element={<PrivateRoute component={<GalerieArchive />} />} /> */}
			<Route
				path="/calendar/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CalenderCreation />
							</Suspense>
						}
						acl={acl?.calender?.add}
					/>
				}
			/>
			<Route
				path="/calendar/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CalenderCreation />
							</Suspense>
						}
						acl={acl?.calender?.update}
					/>
				}
			/>
			<Route
				path="/calendar/report"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<RapportCreation />
							</Suspense>
						}
						acl={acl?.crEvent?.add}
					/>
				}
			/>
			<Route
				path="/calendar/report/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<RapportCreation />
							</Suspense>
						}
						acl={acl?.crEvent?.update}
					/>
				}
			/>
			<Route
				path="/calendar/postpone-event/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<PostponeEvent />
							</Suspense>
						}
						acl={acl?.calender?.report}
					/>
				}
			/>
			{/* Demandes */}
			<Route
				path="/requests"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Demandes />
							</Suspense>
						}
						acl={acl?.demand?.list}
					/>
				}
			/>
			<Route
				path="/requests/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<DemandeDetails />
							</Suspense>
						}
						acl={acl?.demand?.details}
					/>
				}
			/>
			<Route
				path="/requests/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<DemandeCreation />
							</Suspense>
						}
						acl={acl?.demand?.add}
					/>
				}
			/>
			<Route
				path="/requests/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<DemandeCreation />
							</Suspense>
						}
						acl={acl?.demand?.update}
					/>
				}
			/>
			{/* Ventes */}
			{/* Ventes -> Offers */}
			<Route
				path="/offers"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Offers />
							</Suspense>
						}
						acl={acl?.sales?.offers?.list}
					/>
				}
			/>
			<Route
				path="/offers/marketplace"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<OffersView />
							</Suspense>
						}
						acl={acl?.sales?.offers?.list}
					/>
				}
			/>
			<Route
				path="/offers/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<OfferDetails />
							</Suspense>
						}
						acl={acl?.sales?.offers?.details}
					/>
				}
			/>
			<Route
				path="/offers/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<OfferCreation />
							</Suspense>
						}
						acl={acl?.sales?.offers?.add}
					/>
				}
			/>
			<Route
				path="/offers/create/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<OfferCreation />
							</Suspense>
						}
						acl={acl?.sales?.offers?.update}
					/>
				}
			/>
			{/* Ventes -> Orders */}
			<Route
				path="/orders"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<Orders />
							</Suspense>
						}
						acl={acl?.sales?.orders?.list}
					/>
				}
			/>
			<Route
				path="/orders/details/:id"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<OrderDetails />
							</Suspense>
						}
						acl={acl?.sales?.orders?.details}
					/>
				}
			/>
			<Route path="/orders/create" element={<PrivateRoute component={<OrderAdd />} acl={' '} />}>
				<Route index element={<OrderAddStep1 />} />
				<Route path="/orders/create/step1" element={<OrderAddStep1 />} />
				<Route path="/orders/create/step2" element={<OrderAddStep2 />} />
				<Route path="/orders/create/step3" element={<OrderAddStep3 />} />
			</Route>
			{/* Ventes -> custom stores */}
			<Route
				path="/custom-stores"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CustomStores />
							</Suspense>
						}
						acl={acl?.sales?.stores?.list}
					/>
				}
			/>
			<Route
				path="/custom-stores/create"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CustomStoresCreation />
							</Suspense>
						}
						acl={acl?.sales?.stores?.add}
					/>
				}
			/>
			{/* Rapports */}
			<Route path="/reports" element={<PrivateRoute component={<Rapports />} acl={' '} />} />
			<Route
				path="/reports/event-general"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<REventGeneral />
							</Suspense>
						}
						acl={acl?.report?.visits}
					/>
				}
			/>
			<Route
				path="/reports/event-per-canal"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<REventPerCanal />
							</Suspense>
						}
						acl={acl?.report?.visitor_by_channel}
					/>
				}
			/>
			<Route
				path="/reports/event-per-objectif"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<REventPerObj />
							</Suspense>
						}
						acl={acl?.report?.visitor_by_purpose}
					/>
				}
			/>
			<Route
				path="/reports/event-per-categorie"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<REventPerCat />
							</Suspense>
						}
						acl={acl?.report?.visitor_by_contact_category}
					/>
				}
			/>
			<Route
				path="/reports/contact-per-user"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<RContactPerUser />
							</Suspense>
						}
						acl={acl?.report?.contact_by_user}
					/>
				}
			/>
			<Route
				path="/reports/rapport-publication"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<RPosts />
							</Suspense>
						}
						acl={acl?.report?.posts}
					/>
				}
			/>
			<Route
				path="/reports/rapport-marketplace"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<RMarketplace />
							</Suspense>
						}
						acl={acl?.report?.marketplace}
					/>
				}
			/>
			<Route
				path="/reports/commercial-performance"
				element={
					<PrivateRoute
						component={
							<Suspense fallback={<Loader />}>
								<CommercialPerformance />
							</Suspense>
						}
						acl={acl?.report?.delegatePerformance}
					/>
				}
			/>
			{/* Settings */}
			<Route path="/settings" element={<PrivateRoute component={<Settings />} acl={' '} />}>
				<Route index element={<Profile />} />
				<Route path="/settings/profil" element={<Profile />} />
				{acl?.setting?.user?.list && <Route path="/settings/users" element={<UsersTable />} />}
				{acl?.setting?.user?.list && <Route path="/settings/balance" element={<PubBalance />} />}
				{acl?.setting?.user?.update && <Route path="/settings/users/modification/:id" element={<EditUser />} />}
				{acl?.setting?.user?.connection_history && (
					<Route path="/settings/connections-history" element={<HistoricConnexion />} />
				)}
				{acl?.demand?.listEmailAddress && <Route path="/settings/emails-in" element={<EmailIn />} />}
				{acl?.demand?.listEmailAddress && <Route path="/settings/notifs" element={<NotifsEmail />} />}
				{acl?.demand?.listModalEmailAddress && <Route path="/settings/emails-out" element={<EmailOut />} />}
				{acl?.demand?.addModel && (
					<Route path="/settings/emails-out/modele-creation" element={<ModelCreation />} />
				)}
				{acl?.demand?.updateModel && (
					<Route path="/settings/emails-out/modele-creation/:id" element={<ModelCreation />} />
				)}
				{acl?.setting?.product?.range?.list && <Route path="/settings/gammes" element={<Gammes />} />}
				{acl?.setting?.product?.range?.update && (
					<Route path="/settings/gammes/actives" element={<GammesCheck />} />
				)}
				{acl?.setting?.acl_roles?.list && <Route path="/settings/roles" element={<Roles />} />}
				{acl?.setting?.acl_roles?.affect_methods && (
					<Route path="/settings/roles/:id" element={<RolesEdit />} />
				)}
				{acl?.setting?.acl_methods?.list && <Route path="/settings/methodes" element={<Methodes />} />}
				<Route path="/settings/accountCategory" element={<AccountCategory />} />
				<Route path="/settings/contactCategory" element={<ContactCategory />} />
				<Route path="/settings/rapportPublication" element={<PublicationsEmail />} />
				<Route path="/settings/rapportPublication/history" element={<PublicationsHistory />} />
				<Route path="/settings/rapportMarketplace" element={<MarketPlaceEmail />} />
				<Route path="/settings/rapportMarketplace/history" element={<MarketPlaceHistory />} />
				<Route path="/settings/customFields" element={<CustomFields />} />
				<Route path="/settings/customReport" element={<CustomReportdnd />} />
				<Route path="/settings/supplier" element={<SupplierDetails />} />
				<Route path="/settings/delegate" element={<DelegateList />} />
				<Route path="/settings/api" element={<APISettings />} />
			</Route>
			{/* Sign In */}
			<Route path="/signin" element={<Signin />} />
			<Route path="/access-denied" element={<AccessDenied />} />
			<Route path="/failed" element={<Failed />} />
			<Route path="/satisfaction/:id" element={<Satisfactions />} />
			<Route path="/satisfaction/:id/success" element={<SatisfactionsSuccess />} />
			<Route path="/satisfaction/:id/failure" element={<SatisfactionsFailure />} />
			<Route path="/auth/success" element={<Navigate to="/" />} />
			<Route path="/auth/failed" element={<NotAuthorised />} />
			<Route
				path="*"
				element={
					<Suspense fallback={<Loader />}>
						<Page404 />
					</Suspense>
				}
			/>
		</Routes>
	);
};

export default Root;

function Loader() {
	return (
		<div className="labs">
			<div className="loader">
				<SpinnerLoading color="#DF4751" loading />
			</div>
		</div>
	);
}
