import {
	Button,
	ButtonGroup,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	SwitchButton,
	Tag,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPost } from 'api/API';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface PaymentTerm {
	immediate: string;
	days15: string;
	days30: string;
	days45: string;
	days60: string;
	days75: string;
	days90: string;
	days120: string;
}

interface SAPConfiguration {
	status: boolean;
	paymentMethodsMapping: any;
	paymentTimeFramesMapping: PaymentTerm;
}

const SAPConfig = ({ setOpen }: { setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const [payTypes, setPayTypes] = useState<any[]>([]);
	const {
		reset,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm<SAPConfiguration>({
		defaultValues: {
			status: false,
			paymentMethodsMapping: {},
			paymentTimeFramesMapping: {
				immediate: '',
				days15: '',
				days30: '',
				days45: '',
				days60: '',
				days75: '',
				days90: '',
				days120: '',
			},
		},
	});

	const onSubmit = (values: SAPConfiguration) => {
		if (!isSubmitting) {
			setIsSubmitting(true);

			const bodyData: any = {
				status: values.status,
				paymentMethodsMapping: {},
				paymentTimeFramesMapping: {
					0: values.paymentTimeFramesMapping.immediate,
					15: values.paymentTimeFramesMapping.days15,
					30: values.paymentTimeFramesMapping.days30,
					45: values.paymentTimeFramesMapping.days45,
					60: values.paymentTimeFramesMapping.days60,
					75: values.paymentTimeFramesMapping.days75,
					90: values.paymentTimeFramesMapping.days90,
					120: values.paymentTimeFramesMapping.days120,
				},
			};
			Object.keys(values.paymentMethodsMapping).forEach((ele: any) => {
				bodyData.paymentMethodsMapping[ele.split('-')[1]] = values.paymentMethodsMapping[ele];
			});

			axiosPost('apiIntegrations/sap', bodyData).then((res: any) => {
				if (res?.data) {
					setOpen(false);
					notify({
						position: 'top-center',
						type: 'success',
						msg: 'Les modifications sont enregistrées avec succès',
						delay: 5000,
					});
				} else {
					notify({
						position: 'top-center',
						type: 'danger',
						msg: `Les modifications ne sont pas enregistrées, ${res?.response?.data?.message}`,
						delay: 5000,
					});
				}
				setIsSubmitting(false);
			});
		}
	};

	useEffect(() => {
		if (payTypes.length <= 0) {
			axiosGet(`payment-methods`).then((res: any) => {
				const groupedPayTypes =
					res?.data?.payment_methods.length % 2 === 0
						? res?.data?.payment_methods.reduce((acc: any, curr: any, i: number) => {
								if (i % 2 === 0) {
									acc.push([curr, res?.data?.payment_methods[i + 1]]);
								}
								return acc;
							}, [])
						: res?.data?.payment_methods.reduce((acc: any, curr: any, i: number) => {
								if (i % 2 === 0) {
									acc.push([curr, res?.data?.payment_methods[i + 1] || '']);
								}
								return acc;
							}, []);
				setPayTypes(groupedPayTypes);
				axiosGet('apiIntegrations/sap').then((res: any) => {
					const resetData: any = {
						status: res?.data?.status,
						paymentMethodsMapping: {},
						paymentTimeFramesMapping: {
							immediate: '',
							days15: '',
							days30: '',
							days45: '',
							days60: '',
							days75: '',
							days90: '',
							days120: '',
						},
					};
					res?.data?.paymentMethodsMappings.forEach((ele: any) => {
						resetData.paymentMethodsMapping[`${ele?.name}-${ele?.id}`] = ele?.mapping;
					});
					res?.data?.paymentTimeFrames.forEach((ele: any) => {
						resetData.paymentTimeFramesMapping[`${ele?.name}`] = ele?.mapping;
					});
					reset(resetData);
				});
			});
		}
	}, []);

	console.log(payTypes);

	return (
		<Modal setOpen={() => setOpen(false)} size={'md'}>
			<ModalHeader setOpen={() => setOpen(false)} title="Configuration SAP" />
			<ModalBody>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '16px 0',
					}}
				>
					<Tag minWidth={90} size="sm" textColor="#1BBAAC" bgColor="#E3F7F5">
						Disponible
					</Tag>
					<Controller
						control={control}
						name={`status`}
						render={({ field: { value, onChange, name } }) => (
							<SwitchButton checked={value} labelDir="left" onChange={(e: any) => onChange(e)} />
						)}
					/>
				</div>
				<ButtonGroup
					size="md"
					style={{ width: '100%', backgroundColor: '#EEF2F7', padding: 3, marginBottom: 16 }}
				>
					<Button block color={tabValue === 0 ? 'tertiary' : 'ghost'} onClick={() => setTabValue(0)}>
						Moyens de paiement
					</Button>
					<Button block color={tabValue === 1 ? 'tertiary' : 'ghost'} onClick={() => setTabValue(1)}>
						Conditions de paiement
					</Button>
				</ButtonGroup>
				{tabValue === 0 ? (
					<Container key="tab0">
						<Row>
							<Col style={{ margin: '0 0 16px', fontSize: 14 }}>
								Veuillez saisir la référence SAP correspondante pour chacun des moyens de paiement :
							</Col>
						</Row>
						{payTypes?.length > 0 &&
							payTypes?.map(([ele1, ele2]) => (
								<Row key={`${ele1?.id}and${ele2?.id}`}>
									<Col>
										<FormGroup>
											<Label>{ele1?.label}</Label>
											<Controller
												control={control}
												name={`paymentMethodsMapping.${ele1?.name}-${ele1?.id}`}
												render={({ field: { value, onChange, name } }) => (
													<Input
														id={name}
														name={name}
														placeholder={ele1?.label}
														type="text"
														value={value}
														onChange={onChange}
													/>
												)}
											/>
										</FormGroup>
									</Col>
									{ele2?.id && (
										<Col>
											<FormGroup>
												<Label>{ele2?.label}</Label>
												<Controller
													control={control}
													name={`paymentMethodsMapping.${ele2?.name}-${ele2?.id}`}
													render={({ field: { value, onChange, name } }) => (
														<Input
															id={name}
															name={name}
															placeholder={ele2?.label}
															type="text"
															value={value}
															onChange={onChange}
														/>
													)}
												/>
											</FormGroup>
										</Col>
									)}
								</Row>
							))}
					</Container>
				) : (
					<Container key="tab1">
						<Row>
							<Col style={{ margin: '0 0 16px', fontSize: 14 }}>
								Veuillez saisir la référence SAP correspondante pour chacun des conditions de paiement :
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label>Paiement immédiat</Label>
									<Controller
										control={control}
										name="paymentTimeFramesMapping.immediate"
										render={({ field: { value, onChange, name } }) => (
											<Input
												id={name}
												name={name}
												placeholder="Paiement immédiat"
												type="text"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Délai de 15 jours</Label>
									<Controller
										control={control}
										name="paymentTimeFramesMapping.days15"
										render={({ field: { value, onChange, name } }) => (
											<Input
												id={name}
												name={name}
												placeholder="Délai de 15 jours"
												type="text"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label>Délai de 30 jours</Label>
									<Controller
										control={control}
										name="paymentTimeFramesMapping.days30"
										render={({ field: { value, onChange, name } }) => (
											<Input
												id={name}
												name={name}
												placeholder="Délai de 30 jours"
												type="text"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Délai de 45 jours</Label>
									<Controller
										control={control}
										name="paymentTimeFramesMapping.days45"
										render={({ field: { value, onChange, name } }) => (
											<Input
												id={name}
												name={name}
												placeholder="Délai de 45 jours"
												type="text"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label>Délai de 60 jours</Label>
									<Controller
										control={control}
										name="paymentTimeFramesMapping.days60"
										render={({ field: { value, onChange, name } }) => (
											<Input
												id={name}
												name={name}
												placeholder="Délai de 60 jours"
												type="text"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Délai de 75 jours</Label>
									<Controller
										control={control}
										name="paymentTimeFramesMapping.days75"
										render={({ field: { value, onChange, name } }) => (
											<Input
												id={name}
												name={name}
												placeholder="Délai de 75 jours"
												type="text"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label>Délai de 90 jours</Label>
									<Controller
										control={control}
										name="paymentTimeFramesMapping.days90"
										render={({ field: { value, onChange, name } }) => (
											<Input
												id={name}
												name={name}
												placeholder="Délai de 90 jours"
												type="text"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Délai de 120 jours</Label>
									<Controller
										control={control}
										name="paymentTimeFramesMapping.days120"
										render={({ field: { value, onChange, name } }) => (
											<Input
												id={name}
												name={name}
												placeholder="Délai de 120 jours"
												type="text"
												value={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							</Col>
						</Row>
					</Container>
				)}
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => handleSubmit(onSubmit)()} loading={isSubmitting}>
					Ajouter
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default SAPConfig;
