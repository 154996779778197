import React from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router';
import { Button } from '@sobrus-com/sobrus-design-system-v2';

function SatisfactionsSuccess({ success = true }) {
	const navigate = useNavigate();
	const { id } = useParams();

	return (
		<div className="satisfactions-wrapper">
			<div className="satisfactions-info-container ">
				<div className="satisfactions-info satisfactions-info-success">
					{success ? (
						<svg
							width="141"
							height="139"
							viewBox="0 0 141 139"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M35.5257 22.9342C35.5257 22.9342 56.6025 38.9286 88.6446 9.02194C117.101 -17.5374 140.336 24.3673 140.499 44.4347C140.709 70.4299 112.042 91.23 125.954 108.304C139.867 125.378 98.3647 153.573 75.9973 129.172C48.173 98.8184 40.6351 123.481 24.7754 123.481C13.3927 123.481 -9.97703 95.1978 5.80431 74.156C19.0841 56.4497 11.8416 50.5733 8.33424 44.4347C3.27487 35.5816 15.2899 11.5516 35.5257 22.9342Z"
								fill="#E3F7F5"
							/>
							<path
								d="M84.2688 60.4244C84.5012 60.6565 84.6856 60.9323 84.8114 61.2358C84.9372 61.5393 85.002 61.8646 85.002 62.1931C85.002 62.5217 84.9372 62.847 84.8114 63.1505C84.6856 63.454 84.5012 63.7297 84.2688 63.9619L66.7688 81.4619C66.5366 81.6943 66.2609 81.8787 65.9574 82.0045C65.6539 82.1303 65.3286 82.1951 65 82.1951C64.6715 82.1951 64.3462 82.1303 64.0427 82.0045C63.7392 81.8787 63.4634 81.6943 63.2313 81.4619L55.7313 73.9619C55.2622 73.4928 54.9986 72.8565 54.9986 72.1931C54.9986 71.5297 55.2622 70.8935 55.7313 70.4244C56.2004 69.9553 56.8366 69.6917 57.5 69.6917C58.1634 69.6917 58.7997 69.9553 59.2688 70.4244L65 76.1587L80.7313 60.4244C80.9634 60.1919 81.2392 60.0075 81.5427 59.8817C81.8462 59.7559 82.1715 59.6911 82.5 59.6911C82.8286 59.6911 83.1539 59.7559 83.4574 59.8817C83.7609 60.0075 84.0366 60.1919 84.2688 60.4244ZM102.5 69.6931C102.5 76.121 100.594 82.4045 97.0228 87.7491C93.4516 93.0937 88.3758 97.2593 82.4372 99.7192C76.4986 102.179 69.964 102.823 63.6596 101.569C57.3552 100.315 51.5643 97.2193 47.019 92.6741C42.4738 88.1289 39.3785 82.3379 38.1245 76.0336C36.8705 69.7292 37.5141 63.1945 39.9739 57.2559C42.4338 51.3173 46.5994 46.2415 51.944 42.6704C57.2886 39.0992 63.5721 37.1931 70 37.1931C78.6168 37.2022 86.878 40.6292 92.9709 46.7222C99.0639 52.8152 102.491 61.0764 102.5 69.6931ZM97.5 69.6931C97.5 64.2541 95.8872 58.9373 92.8654 54.4149C89.8437 49.8926 85.5488 46.3678 80.5238 44.2864C75.4988 42.205 69.9695 41.6604 64.635 42.7215C59.3006 43.7826 54.4005 46.4017 50.5546 50.2477C46.7086 54.0936 44.0895 58.9937 43.0284 64.3281C41.9673 69.6626 42.5119 75.1919 44.5933 80.2169C46.6747 85.2419 50.1995 89.5368 54.7218 92.5585C59.2442 95.5803 64.561 97.1931 70 97.1931C77.2909 97.1848 84.2809 94.2849 89.4363 89.1294C94.5918 83.974 97.4917 76.984 97.5 69.6931Z"
								fill="#1BBAAC"
							/>
						</svg>
					) : (
						<MdErrorOutline size={100} color="#DF4751" />
					)}
					<h1>{success ? 'Évaluation soumise avec succès !' : 'Erreur!'}</h1>
					{success ? (
						<p>
							Nous vous remercions d&apos;avoir consacré du temps à compléter notre enquête de
							satisfaction !
							<br />
							Vos commentaires sont précieux pour nous.
						</p>
					) : (
						<Button color="primary" onClick={() => navigate(`/satisfaction/${id}`)}>
							Essayer à nouveau
						</Button>
					)}
				</div>
			</div>
			<div className="satisfactions-img">
				<img src={require('assets/survey-img.svg').default} alt="" />
			</div>
		</div>
	);
}

export default SatisfactionsSuccess;
