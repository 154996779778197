import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { SpinnerLoading } from '@sobrus-com/sobrus-design-system-v2';

import { UserContext } from '../context/userContext';

// export const PrivateRoute = ({ component }) => {
//     const { isLogin } = useContext(UserContext);

//     return isLogin || localStorage.getItem("isLogin") ? component : <Navigate to="/signin" />;
// };

export function PrivateRoute({ component, acl }) {
	const { isLogin, isFetching } = useContext(UserContext);

	if (!isFetching) {
		if (isLogin) {
			if (acl) {
				return component;
			}
			return <Navigate to="/access-denied" />;
		}
		return <Navigate to="/signin" />;
	}
	return (
		<div className="labs">
			<div className="loader">
				<SpinnerLoading color="#DF4751" loading />
			</div>
		</div>
	);
}
