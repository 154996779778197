import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from '@sobrus-com/sobrus-design-system-v2';

import './ConfirmationPopUp.scss';

function ConfirmationPopUp({ answerCallback, isOpen, setIsOpen, hasTitle = true, desc = null }) {
	const { t } = useTranslation();

	return (
		<Modal className="confirmPopUp-container" size="sm" setOpen={() => setIsOpen(false)} open>
			<ModalBody style={{}}>
				<img src={require('../assets/warning-img.svg').default} alt="" />
				{hasTitle && <h4>{t('Are you sure')}?</h4>}
				{desc || `${t('Are you sure you want to perform this action')}?`}
			</ModalBody>
			<ModalFooter>
				<Button
					color="primary"
					onClick={() => {
						setIsOpen(false);
						answerCallback();
					}}
				>
					{t('Yes')}
				</Button>
				<Button onClick={() => setIsOpen(false)} color="tertiary" outline>
					{t('No')}
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default ConfirmationPopUp;
