export const openInNewTab = (urlParam: string) => {
	const changeUrl = () => {
		if (!urlParam.startsWith('https')) {
			if (urlParam.startsWith('http')) return urlParam;
			return `https://${urlParam}`;
		}
		return urlParam;
	};
	const newWindow = window.open(changeUrl(), '_blank', 'noopener,noreferrer');
	if (newWindow) newWindow.opener = null;
};
// thousands with space
export const numberThousandsFormat = (number: number | string | null | undefined) => {
	const numberValue = typeof number === 'string' ? parseFloat(number) : number;

	if (typeof numberValue === 'number' && !isNaN(numberValue)) {
		return numberValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}
	return '';
};

// thousands with comma
export const numberThousandsFormatComma = (number: number | string | null | undefined) => {
	const numberValue = typeof number === 'string' ? parseFloat(number) : number;

	if (typeof numberValue === 'number' && !isNaN(numberValue)) {
		return numberValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return '';
};
export const numberThousandsFormatCommaWithoutDecimal = (number: number | string | null | undefined) => {
	const numberValue = typeof number === 'string' ? parseFloat(number) : number;

	if (typeof numberValue === 'number' && !isNaN(numberValue)) {
		return Number(numberValue.toFixed(2))
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return '0';
};
export const formatNumberWithCommas = (num: number | string | null | undefined) => {
	const numberValue = typeof num === 'string' ? parseFloat(num) : num;

	if (typeof numberValue === 'number' && !isNaN(numberValue)) {
		return numberValue
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return '0.00';
};
