/* eslint-disable @typescript-eslint/no-var-requires */
import { Card, Col, Container, CustomButton, Row } from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet } from 'api/API';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import { UserContext } from 'context/userContext';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import PDFGenerator2 from '../orderDetails/PDFGenerator2';

const OrderAddStep3 = () => {
	const [order, setOrder] = useState(null);
	const { user }: any = useContext(UserContext);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!order) {
			axiosGet(`purchase-orders/${searchParams.get('orderId')}`).then((res: any) => {
				setOrder(res?.data);
			});
		}
	}, []);

	return (
		<>
			<div className="offers-detail-header">
				<BackwardTitle title="Commande terminée" subTitle="Ventes" to="/orders" isBackward />
			</div>
			<Card>
				<Row>
					<Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<div className="order-finished-header">
							<svg
								width="164"
								height="162"
								viewBox="0 0 164 162"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{ margin: '0 auto 20px', display: 'block' }}
							>
								<path
									d="M41.2318 26.1944C41.2318 26.1944 65.7998 44.8382 103.15 9.97766C136.319 -20.9811 163.404 27.8649 163.593 51.2564C163.839 81.5574 130.423 105.803 146.639 125.705C162.856 145.607 114.48 178.473 88.4073 150.03C55.974 114.648 47.1875 143.396 28.7008 143.396C15.4326 143.396 -11.8082 110.428 6.58724 85.9009C22.0668 65.2616 13.6245 58.4118 9.53623 51.2564C3.63881 40.9367 17.6441 12.9263 41.2318 26.1944Z"
									fill="#EBF6FC"
									fillOpacity="0.86"
								/>
								<path
									d="M69.5433 74.9044L65.2433 79.2044L79.0743 93.0434L109.818 62.2994L105.518 57.9994L79.0743 84.2814L69.5433 74.9044ZM106.743 80.7454C106.721 85.872 105.091 90.8625 102.082 95.0133C99.0726 99.1641 94.8368 102.266 89.9713 103.882C85.1058 105.497 79.856 105.545 74.9618 104.019C70.0675 102.492 65.7758 99.4683 62.6914 95.3732C59.6071 91.2781 57.8856 86.3183 57.7698 81.1928C57.6539 76.0674 59.1495 71.0349 62.0457 66.8046C64.9419 62.5743 69.0926 59.3595 73.9129 57.6135C78.7331 55.8675 83.9798 55.6782 88.9133 57.0724L93.6783 52.3064C90.0361 50.7477 86.1098 49.9628 82.1483 50.0014C76.0677 50.0014 70.1237 51.8045 65.0679 55.1827C60.012 58.5608 56.0715 63.3624 53.7446 68.9801C51.4176 74.5979 50.8088 80.7795 51.995 86.7432C53.1813 92.707 56.1094 98.185 60.409 102.485C64.7086 106.784 70.1867 109.712 76.1505 110.899C82.1142 112.085 88.2958 111.476 93.9135 109.149C99.5313 106.822 104.333 102.882 107.711 97.8258C111.089 92.77 112.892 86.8259 112.892 80.7454H106.743Z"
									fill="#5CB4E5"
								/>
							</svg>
							<div className="order-finished-text">Votre commande a été créée avec succès</div>
						</div>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Container>
							<Row>
								<Col>
									<CustomButton
										icon={
											<svg
												width="20"
												height="21"
												viewBox="0 0 20 21"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M12.5003 10.0658C12.5003 11.4465 11.381 12.5658 10.0003 12.5658C8.61957 12.5658 7.50029 11.4465 7.50029 10.0658C7.50029 8.68504 8.61957 7.56576 10.0003 7.56576C11.381 7.56576 12.5003 8.68504 12.5003 10.0658Z"
													stroke="#0D2339"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M2.04883 10.0657C3.11072 6.68482 6.26929 4.23242 10.0007 4.23242C13.732 4.23242 16.8906 6.68485 17.9525 10.0658C16.8906 13.4467 13.732 15.8991 10.0007 15.8991C6.26929 15.8991 3.11071 13.4467 2.04883 10.0657Z"
													stroke="#0D2339"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										}
										bgColor="#fff"
										label="Afficher la commande"
										onClick={() => navigate(`/orders/details/${searchParams.get('orderId')}`)}
									/>
								</Col>
								<Col>
									<CustomButton
										icon={
											<svg
												width="20"
												height="21"
												viewBox="0 0 20 21"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M10 7.56641V10.0664M10 10.0664V12.5664M10 10.0664H12.5M10 10.0664H7.5M17.5 10.0664C17.5 14.2085 14.1421 17.5664 10 17.5664C5.85786 17.5664 2.5 14.2085 2.5 10.0664C2.5 5.92427 5.85786 2.56641 10 2.56641C14.1421 2.56641 17.5 5.92427 17.5 10.0664Z"
													stroke="#0D2339"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										}
										bgColor="#fff"
										size="md"
										label="Créer une nouvelle commande"
										color="success"
										onClick={() => navigate(`/orders`)}
									/>
								</Col>
								<Col xs="2"></Col>
							</Row>
							<Row style={{ marginTop: 20 }}>
								<Col>
									<PDFGenerator2
										order={order}
										user={`${user?.firstName} ${user?.lastName}`}
										isCustomButton
									/>
								</Col>
								<Col>
									<CustomButton
										icon={
											<svg
												width="20"
												height="21"
												viewBox="0 0 20 21"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M7.49935 4.23307H5.83268C4.91221 4.23307 4.16602 4.97926 4.16602 5.89974V15.8997C4.16602 16.8202 4.91221 17.5664 5.83268 17.5664H14.166C15.0865 17.5664 15.8327 16.8202 15.8327 15.8997V5.89974C15.8327 4.97926 15.0865 4.23307 14.166 4.23307H12.4993M7.49935 4.23307C7.49935 5.15355 8.24554 5.89974 9.16602 5.89974H10.8327C11.7532 5.89974 12.4993 5.15355 12.4993 4.23307M7.49935 4.23307C7.49935 3.3126 8.24554 2.56641 9.16602 2.56641H10.8327C11.7532 2.56641 12.4993 3.3126 12.4993 4.23307M9.99935 10.0664H12.4993M9.99935 13.3997H12.4993M7.49935 10.0664H7.50768M7.49935 13.3997H7.50768"
													stroke="#0D2339"
													strokeWidth="1.5"
													strokeLinecap="round"
												/>
											</svg>
										}
										bgColor="#fff"
										label="Afficher toutes les commandes"
										color="danger"
										onClick={() => navigate(`/orders`)}
									/>
								</Col>
								<Col xs="2"></Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default OrderAddStep3;
