import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	EmptyData,
	Input,
	InputSelect,
	LinearProgress,
	SearchBtn,
	Table,
	TableContainer,
	TableHeader,
	TableHeaderActions,
	TableHeaderTitle,
	TableLoader,
	TableNextPage,
	TablePage,
	TablePreviousPage,
	TableRefreshBtn,
	TableSearchBtn,
	TableSearchItem,
	TableSearchRow,
	Th,
} from '@sobrus-com/sobrus-design-system-v2';

import { useAcl } from '../../../ACL/acl';
import { useTableData } from 'utils/hooks/useTableData';
import { Controller } from 'react-hook-form';

function UsersTable() {
	const navigate = useNavigate();
	const acl = useAcl();
	const { t } = useTranslation();

	// const handleSearch = () => {
	// 	const searchTerm = {};

	// 	if (nompInput !== '') {
	// 		searchTerm.lastName = nompInput;
	// 	}

	// 	if (prenompInput !== '') {
	// 		searchTerm.firstName = prenompInput;
	// 	}

	// 	if (estAdmin?.value) {
	// 		searchTerm.isAdmin = estAdmin?.value;
	// 	}

	// 	if (estSuper?.value) {
	// 		searchTerm.isSupervisor = estSuper?.value;
	// 	}

	// 	setIsFetchingContacts(true);

	// 	return searchTerm;
	// };

	// const refreshTable = () => {
	// 	setEstAdmin(null);
	// 	setEstSuper(null);
	// 	setNompInput('');
	// 	setPrenompInput('');
	// 	setStoredPage(1);
	// 	setIsFetchingContacts(true);
	// 	setOrderBy(null);
	// 	setDirec(null);
	// };

	// const handleOrderBy = (by) => {
	// 	setOrderBy(by);
	// 	setIsFetchingContacts(true);
	// 	setDirec((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
	// };

	// useEffect(() => {
	// 	if (isFetchingContacts) {
	// 		const searchTerm = { ...handleSearch(), orderBy, order: direc || 'DESC', page: pageNumber, limit: 15 };
	// 		axiosGet(`users`, searchTerm).then((res) => {
	// 			setUsers(res?.data?.users);
	// 			setIsFetchingContacts(false);
	// 		});
	// 	}
	// }, [isFetchingContacts]);

	const INITIAL_SEARCH_VALUES = {
		lastName: '',
		firstName: '',
		isAdmin: null,
		isSupervisor: null,
	};

	const {
		data,
		loading,
		loadingNewData,
		params: {
			queryState: { order, orderBy, page, limit },
		},
		orderHandler,
		toggleSearchHandler,
		toggleSearch,
		submitHandler,
		handleSubmit,
		control,
		refrechHandler,
		prevHandler,
		nextHandler,
	} = useTableData({
		url: 'users',
		initialQuery: {},
		nameStorage: 'users',
		initialFormDataValues: INITIAL_SEARCH_VALUES,
		name: 'users',
	});

	return (
		<TableContainer className="fade">
			<TableHeader>
				<TableHeaderTitle>{t('User List')}</TableHeaderTitle>
				{loadingNewData && (
					<div className="table-newData-loader">
						<LinearProgress />
					</div>
				)}
				<TableHeaderActions>
					<TableRefreshBtn onClick={() => refrechHandler()} />
					<TableSearchBtn onClick={() => toggleSearchHandler()} />
				</TableHeaderActions>
			</TableHeader>
			<Table>
				<thead>
					<tr>
						<Th
							onClick={() => orderHandler('lastName')}
							isActive={orderBy === 'lastName'}
							order={orderBy === 'lastName' ? (order === 'ASC' ? 'down' : 'up') : undefined}
						>
							{t('Name')}
						</Th>
						<Th
							onClick={() => orderHandler('firstName')}
							isActive={orderBy === 'firstName'}
							order={orderBy === 'firstName' ? (order === 'ASC' ? 'down' : 'up') : undefined}
						>
							{t('First Name')}
						</Th>
						<Th
							onClick={() => orderHandler('isAdmin')}
							isActive={orderBy === 'isAdmin'}
							order={orderBy === 'isAdmin' ? (order === 'ASC' ? 'down' : 'up') : undefined}
						>
							Est admin
						</Th>
						<Th
							onClick={() => orderHandler('isSupervisor')}
							isActive={orderBy === 'isSupervisor'}
							order={orderBy === 'isSupervisor' ? (order === 'ASC' ? 'down' : 'up') : undefined}
						>
							Est Superviseur
						</Th>
						{(acl?.setting?.user?.update || acl?.setting?.user?.update_access_type) && <Th>Actions</Th>}
					</tr>
					<TableSearchRow id="simple" open={toggleSearch}>
						<TableSearchItem>
							<Controller
								control={control}
								name="lastName"
								render={({ field: { onChange, value } }) => (
									<Input
										id="nomp"
										name="nomp"
										placeholder={t('Name')}
										type="text"
										onChange={(e) => onChange(e.target.value)}
										value={value}
									/>
								)}
							/>
						</TableSearchItem>
						<TableSearchItem>
							<Controller
								control={control}
								name="firstName"
								render={({ field: { onChange, value } }) => (
									<Input
										id="pnomp"
										name="pnomp"
										placeholder={t('First Name')}
										type="text"
										onChange={(e) => onChange(e.target.value)}
										value={value}
									/>
								)}
							/>
						</TableSearchItem>
						<TableSearchItem>
							<Controller
								control={control}
								name="isAdmin"
								render={({ field: { onChange, value } }) => (
									<InputSelect
										placeholder={t('Is Admin')}
										// invalid={city ? false : true}
										name="admin"
										value={value}
										options={[
											{ value: true, label: t('Yes') },
											{ value: false, label: t('No') },
										]}
										onChange={(e) => onChange(e || null)}
									/>
								)}
							/>
						</TableSearchItem>
						<TableSearchItem>
							<Controller
								control={control}
								name="isSupervisor"
								render={({ field: { onChange, value } }) => (
									<InputSelect
										placeholder={t('Is Supervisor')}
										name="super"
										value={value}
										options={[
											{ value: true, label: t('Yes') },
											{ value: false, label: t('No') },
										]}
										onChange={(e) => onChange(e || null)}
									/>
								)}
							/>
						</TableSearchItem>
						<TableSearchItem>
							<SearchBtn
								onClick={() => {
									handleSubmit(submitHandler)();
								}}
							/>
						</TableSearchItem>
					</TableSearchRow>
				</thead>
				{loading && (
					<TableLoader
						loading
						columns={[
							{ type: 'extra-small' },
							{ type: 'extra-small' },
							{ type: 'extra-small' },
							{ type: 'extra-small' },
							{ type: 'dot' },
						]}
					/>
				)}
				<tbody>
					{!loading &&
						data &&
						data.map((ele, index) => (
							<tr key={index}>
								<th>{ele.lastName}</th>
								<td>{ele.firstName}</td>
								<td>{ele.isAdmin ? 'Oui' : 'Non'}</td>
								<td>{ele.isSupervisor ? 'Oui' : 'Non'}</td>
								{acl?.setting?.user?.update && (
									<td>
										<img
											style={{ cursor: 'pointer' }}
											onClick={() => navigate(`/settings/users/modification/${ele.id}`)}
											src={require('../../../assets/edit-icon.svg').default}
											alt=""
											role="presentation"
										/>
									</td>
								)}
							</tr>
						))}
				</tbody>
				<tfoot>
					{data?.length > 0 && (
						<tr>
							<td colSpan={10}>
								<div className="sob-v2-table-pagination">
									<TablePreviousPage onClick={prevHandler} disabled={page <= 1} />
									<TablePage>{page}</TablePage>
									<TableNextPage onClick={nextHandler} disabled={data?.length < 15} />
								</div>
							</td>
						</tr>
					)}
					{!loading && data?.length < 1 && (
						<tr>
							<td colSpan={20}>
								<EmptyData title={t('No results match your search')} />
							</td>
						</tr>
					)}
				</tfoot>
			</Table>
		</TableContainer>
	);
}

export default UsersTable;
