import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
	AnimatedTr,
	Button,
	EmptyData,
	Table,
	TableContainer,
	TableHeader,
	TableHeaderTitle,
	TableLoader,
	TableNextPage,
	TablePage,
	TablePreviousPage,
	Tag,
	Th,
} from '@sobrus-com/sobrus-design-system-v2';
import { useAcl } from 'ACL/acl';
import { axiosGet, axiosPost } from 'api/API';
import { format, parseISO } from 'date-fns';
import usePagination from 'utils/usePagination';

function MarketPlaceHistory() {
	const [isFetchingDemandes, setIsFetchingDemandes] = useState(true);
	const [demandes, setDemandes] = useState(null);
	const navigate = useNavigate();
	const acl = useAcl();
	const { t } = useTranslation();

	const { pageNumber, pageChange, setStoredPage } = usePagination(setIsFetchingDemandes);

	useEffect(() => {
		if (isFetchingDemandes) {
			// const searchTerm = handleSearch();
			// searchTerm.orderby = { col: orderBy || 'id', dir: direc || 'DESC' };
			// searchTerm.disabled = { value: 0, op: "is" };
			axiosGet(`marketplace-params/histories`).then((res) => {
				setDemandes(res?.data);
				setIsFetchingDemandes(false);
			});
		}
	}, [isFetchingDemandes]);

	// const getComptes = async (inputValue = null, callback = () => {}) => {
	//     const res = await axiosPost(`comptes/1`, { nom: { value: inputValue, op: "smart" } });
	//     const data = res?.data?.ressources.map((item) => ({ value: item?.id ?? 0, label: item?.nom ?? "" }));
	//     callback(data);
	// };

	return (
		<TableContainer>
			<div className="labs-settings-btns">
				<Button type="button" onClick={() => navigate('/settings/rapportMarketplace')}>
					{t('New marketplace report')}
				</Button>
			</div>
			<TableHeader>
				<TableHeaderTitle>{t('Email history')}</TableHeaderTitle>
			</TableHeader>
			<Table>
				<thead>
					<tr>
						<Th hideOrder>{t('Recipient')}</Th>
						<Th hideOrder>{t('Date and time sent')}</Th>
						<Th width="150px" hideOrder>
							{t('Status')}
						</Th>
						<th aria-label="empty" width="75px" />
					</tr>
				</thead>
				{isFetchingDemandes && (
					<TableLoader
						loading
						columns={[
							{ type: 'extra-small' },
							{ type: 'extra-small' },
							{ type: 'extra-small' },
							{ type: 'dot' },
						]}
					/>
				)}
				<tbody>
					{!isFetchingDemandes &&
						demandes &&
						demandes.map((ele, index) => (
							<AnimatedTr
								key={index}
								duration={20}
								index={index}
								onClick={() => acl?.demand?.details && navigate(`/requests/details/${ele.id}`)}
							>
								<th scope="row">{ele.recipient?.name}</th>
								<td>{ele?.date && format(parseISO(ele?.date), 'yyyy-MM-dd')}</td>
								<td>
									{ele.status === 'Envoyé' ? (
										<Tag bgColor="#F0EFF4" textColor="#5D4BA7">
											{t('Envoyé')}
										</Tag>
									) : (
										<Tag bgColor="#FFF5F2" textColor="#F05A29">
											{t('Echec')}
										</Tag>
									)}
								</td>
								<td width="75px" />
							</AnimatedTr>
						))}
				</tbody>
				<tfoot>
					{demandes?.length > 0 && (
						<tr>
							<td colSpan={10}>
								<div className="sob-v2-table-pagination">
									<TablePreviousPage onClick={() => pageChange('prev')} disabled={pageNumber <= 1} />
									<TablePage>{pageNumber}</TablePage>
									<TableNextPage
										onClick={() => pageChange('next')}
										disabled={demandes?.length < 15}
									/>
								</div>
							</td>
						</tr>
					)}
					{!isFetchingDemandes && demandes?.length < 1 && (
						<tr>
							<td colSpan={20}>
								<EmptyData title={t('No results match your search')} />
							</td>
						</tr>
					)}
				</tfoot>
			</Table>
		</TableContainer>
	);
}

export default MarketPlaceHistory;
