import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
	SettingsAccordion,
	SettingsAccordionItem,
	SettingsAccordionItemButton,
	SettingsAccordionItemHeading,
	SettingsAccordionPanel,
	SettingsAccordionPanelItem,
} from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import useTitle from 'utils/useTitle';
import { useAcl } from '../../ACL/acl';

function Settings() {
	const acl = useAcl();
	const { t } = useTranslation();

	useTitle(t('Settings'));

	return (
		<section className="products-container labs-container fade">
			<div className="products-header">
				<BackwardTitle title={t('My Settings')} subTitle={t('Settings')} />
			</div>
			<div className="settings-container">
				<SettingsAccordion style={{ maxWidth: '300px' }}>
					<SettingsAccordionItem uuid="abcd">
						<SettingsAccordionItemHeading>
							<SettingsAccordionItemButton
								icon={<img src={require('assets/settings/MySettings.svg').default} alt="" />}
							>
								{t('My Settings')}
							</SettingsAccordionItemButton>
						</SettingsAccordionItemHeading>
						<SettingsAccordionPanel>
							<SettingsAccordionPanelItem>
								<NavLink
									className={(navData) =>
										navData.isActive
											? 'sob-accordion__panel_item active'
											: 'sob-accordion__panel_item'
									}
									to="/settings/profil"
								>
									{t('Profile')}
								</NavLink>
							</SettingsAccordionPanelItem>
						</SettingsAccordionPanel>
					</SettingsAccordionItem>
					{acl?.setting.user.list && acl?.setting.user.connection_history && (
						<SettingsAccordionItem>
							<SettingsAccordionItemHeading>
								<SettingsAccordionItemButton
									icon={<img src={require('assets/settings/Users.svg').default} alt="" />}
								>
									{t('Users')}
								</SettingsAccordionItemButton>
							</SettingsAccordionItemHeading>
							<SettingsAccordionPanel>
								{acl?.setting.user.list && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/users"
										>
											{t('User List')}
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
								{acl?.setting.acl_roles.list && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/roles"
										>
											{t('Roles')}
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
								{acl?.setting.acl_methods.list && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/methodes"
										>
											Permissions
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
								{acl?.setting.user.connection_history && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/connections-history"
										>
											{t('Login History')}
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
							</SettingsAccordionPanel>
						</SettingsAccordionItem>
					)}
					{acl?.setting?.product?.range?.list && (
						<SettingsAccordionItem>
							<SettingsAccordionItemHeading>
								<SettingsAccordionItemButton
									icon={<img src={require('assets/settings/Products.svg').default} alt="" />}
								>
									Produits
								</SettingsAccordionItemButton>
							</SettingsAccordionItemHeading>
							<SettingsAccordionPanel>
								<SettingsAccordionPanelItem>
									<NavLink
										className={(navData) =>
											navData.isActive
												? 'sob-accordion__panel_item active'
												: 'sob-accordion__panel_item'
										}
										to="/settings/gammes"
									>
										Gammes actives
									</NavLink>
								</SettingsAccordionPanelItem>
							</SettingsAccordionPanel>
						</SettingsAccordionItem>
					)}
					<SettingsAccordionItem>
						<SettingsAccordionItemHeading>
							<SettingsAccordionItemButton
								icon={<img src={require('assets/settings/Accounts.svg').default} alt="" />}
							>
								Comptes et contacts
							</SettingsAccordionItemButton>
						</SettingsAccordionItemHeading>
						<SettingsAccordionPanel>
							<SettingsAccordionPanelItem>
								<NavLink
									className={(navData) =>
										navData.isActive
											? 'sob-accordion__panel_item active'
											: 'sob-accordion__panel_item'
									}
									to="/settings/accountCategory"
								>
									{t('Account Categories')}
								</NavLink>
							</SettingsAccordionPanelItem>
							<SettingsAccordionPanelItem>
								<NavLink
									className={(navData) =>
										navData.isActive
											? 'sob-accordion__panel_item active'
											: 'sob-accordion__panel_item'
									}
									to="/settings/contactCategory"
								>
									{t('Contact Categories')}
								</NavLink>
							</SettingsAccordionPanelItem>
						</SettingsAccordionPanel>
					</SettingsAccordionItem>
					{acl?.demand?.listEmailAddress && acl?.demand?.listModalEmailAddress && (
						<SettingsAccordionItem>
							<SettingsAccordionItemHeading>
								<SettingsAccordionItemButton
									icon={<img src={require('assets/settings/Requests.svg').default} alt="" />}
								>
									Demandes
								</SettingsAccordionItemButton>
							</SettingsAccordionItemHeading>
							<SettingsAccordionPanel>
								{acl?.demand?.listEmailAddress && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/emails-in"
										>
											Email de réception
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
								{acl?.demand?.listModalEmailAddress && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/emails-out"
										>
											{t('Email Templates')}
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
								{acl?.demand?.listEmailAddress && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/notifs"
										>
											Notifications via email
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
							</SettingsAccordionPanel>
						</SettingsAccordionItem>
					)}
					{acl?.setting?.performance_report?.marketplace?.details &&
						acl?.setting?.performance_report?.publications?.details && (
							<SettingsAccordionItem>
								<SettingsAccordionItemHeading>
									<SettingsAccordionItemButton
										icon={<img src={require('assets/settings/Publications.svg').default} alt="" />}
									>
										Publications
									</SettingsAccordionItemButton>
								</SettingsAccordionItemHeading>
								<SettingsAccordionPanel>
									{acl?.setting.user.connection_history && (
										<SettingsAccordionPanelItem>
											<NavLink
												className={(navData) =>
													navData.isActive
														? 'sob-accordion__panel_item active'
														: 'sob-accordion__panel_item'
												}
												to="/settings/balance"
											>
												Solde publicité
											</NavLink>
										</SettingsAccordionPanelItem>
									)}
									{acl?.setting?.performance_report?.marketplace?.details && (
										<SettingsAccordionPanelItem>
											<NavLink
												className={(navData) =>
													navData.isActive
														? 'sob-accordion__panel_item active'
														: 'sob-accordion__panel_item'
												}
												to="/settings/rapportPublication"
											>
												Rapport via email
											</NavLink>
										</SettingsAccordionPanelItem>
									)}
								</SettingsAccordionPanel>
							</SettingsAccordionItem>
						)}
					{acl?.setting?.performance_report?.marketplace?.details &&
						acl?.setting?.performance_report?.publications?.details && (
							<SettingsAccordionItem>
								<SettingsAccordionItemHeading>
									<SettingsAccordionItemButton
										icon={<img src={require('assets/settings/Ventes.svg').default} alt="" />}
									>
										Ventes
									</SettingsAccordionItemButton>
								</SettingsAccordionItemHeading>
								<SettingsAccordionPanel>
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/supplier"
										>
											Détails fournisseur
										</NavLink>
									</SettingsAccordionPanelItem>
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/delegate"
										>
											Délégués
										</NavLink>
									</SettingsAccordionPanelItem>
									{acl?.setting?.performance_report?.publications?.details && (
										<SettingsAccordionPanelItem>
											<NavLink
												className={(navData) =>
													navData.isActive
														? 'sob-accordion__panel_item active'
														: 'sob-accordion__panel_item'
												}
												to="/settings/rapportMarketplace"
											>
												Rapport via email
											</NavLink>
										</SettingsAccordionPanelItem>
									)}
								</SettingsAccordionPanel>
							</SettingsAccordionItem>
						)}
					{acl?.setting?.custom_fields?.list && acl?.setting?.custom_fields?.event_fields && (
						<SettingsAccordionItem>
							<SettingsAccordionItemHeading>
								<SettingsAccordionItemButton
									icon={<img src={require('assets/settings/Calendar.svg').default} alt="" />}
								>
									Événements et comptes rendus
								</SettingsAccordionItemButton>
							</SettingsAccordionItemHeading>
							<SettingsAccordionPanel>
								{acl?.setting?.custom_fields?.list && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/customFields"
										>
											Champs de saisie
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
								{acl?.setting?.custom_fields?.event_fields && (
									<SettingsAccordionPanelItem>
										<NavLink
											className={(navData) =>
												navData.isActive
													? 'sob-accordion__panel_item active'
													: 'sob-accordion__panel_item'
											}
											to="/settings/customReport"
										>
											Composition compte rendu
										</NavLink>
									</SettingsAccordionPanelItem>
								)}
							</SettingsAccordionPanel>
						</SettingsAccordionItem>
					)}
					{acl?.setting?.custom_fields?.list && (
						<SettingsAccordionItem>
							<SettingsAccordionItemHeading>
								<SettingsAccordionItemButton
									icon={<img src={require('assets/settings/Api.svg').default} alt="" />}
								>
									{t('API and Integrations')}
								</SettingsAccordionItemButton>
							</SettingsAccordionItemHeading>
							<SettingsAccordionPanel>
								<SettingsAccordionPanelItem>
									<NavLink
										className={(navData) =>
											navData.isActive
												? 'sob-accordion__panel_item active'
												: 'sob-accordion__panel_item'
										}
										to="/settings/api"
									>
										API
									</NavLink>
								</SettingsAccordionPanelItem>
							</SettingsAccordionPanel>
						</SettingsAccordionItem>
					)}
				</SettingsAccordion>
				<div className="settings-content">
					<Outlet />
				</div>
			</div>
		</section>
	);
}

export default Settings;
