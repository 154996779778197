import {
	Card,
	Table,
	TableContainer,
	TableHeader,
	TableHeaderActions,
	TableHeaderTitle,
	Tag,
	Th,
	Tooltip,
} from '@sobrus-com/sobrus-design-system-v2';
import ProgressBarV2 from 'components/progressBar/ProgressBarV2';
import React, { useRef, useState } from 'react';
import ProductTr from './ProductTr';

const OrderProducts = ({ setOffer, offer, isView }: { setOffer: any; offer: any; isView?: boolean }) => {
	const [focusedInput, setFocusedInput] = useState(null);
	const inputRefs = useRef<any[]>([]);
	const handleInputRef = (ref: React.MutableRefObject<never[]>, index: number) => {
		inputRefs.current[index] = ref;
	};

	return (
		<Card cardTitle="Packs de produits" style={{ marginTop: 20 }}>
			{offer?.offerPacks?.map((pack: any, index: number) => (
				<TableContainer
					id={`pack-${pack?.id}`}
					key={pack.id}
					style={{ marginTop: index > 0 ? 20 : 0, border: '2px solid #5CB4E5' }}
					className="order-table-container"
				>
					<TableHeader>
						<TableHeaderTitle>
							Pack #{index + 1}
							{!isView && (
								<>
									{pack.minQuantity > 0 && (
										<Tag bgColor="#FFE7E9" textColor="#961B24" size="sm" minWidth={10}>
											{pack.minQuantity} Unités min
										</Tag>
									)}
									{pack.discountThresholds?.length > 0 && (
										<Tag bgColor="#EEF2F7" textColor="#2B4660" size="sm" minWidth={10}>
											{pack.discountThresholds?.length} Paliers
										</Tag>
									)}
								</>
							)}
						</TableHeaderTitle>
						<div className="pack-info">
							Unités commandées: <strong>{pack?.quantityPack ?? 0}</strong>
						</div>
						<div className="pack-info">
							Unités gratuites: <strong>{pack?.totalFuPack ?? 0}</strong>
						</div>
						<div className="pack-info">
							Total du pack:{' '}
							<strong>{pack?.totalAfterDiscount ? pack?.totalAfterDiscount.toFixed(2) : 0} Dhs</strong>
						</div>
						{!isView && (
							<TableHeaderActions style={{ minWidth: 300 }}>
								{pack?.discountThresholds?.length > 0 && (
									<ProgressBarV2
										value={pack?.quantityPack ?? 0}
										max={
											pack?.discountThresholds?.reduce((a: any, b: any) =>
												a.minQuantity > b.minQuantity ? a : b
											).minQuantity
										}
										steps={pack?.discountThresholds}
									/>
								)}
							</TableHeaderActions>
						)}
					</TableHeader>
					<Table>
						<thead>
							{pack?.errorPack && (
								<tr style={{ height: 20 }}>
									<td colSpan={10} style={{ padding: 0 }}>
										<div className="pack-error">
											<svg
												width="21"
												height="21"
												viewBox="0 0 21 21"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M10.5003 7.52539V9.19206M10.5003 12.5254H10.5086M4.72677 15.8587H16.2738C17.5568 15.8587 18.3587 14.4698 17.7172 13.3587L11.9436 3.35872C11.3021 2.24761 9.6984 2.24761 9.0569 3.35872L3.28339 13.3587C2.64189 14.4698 3.44377 15.8587 4.72677 15.8587Z"
													stroke="#C5353E"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
											Quantité minimum du pack non atteinte
										</div>
									</td>
								</tr>
							)}
							<tr>
								<Th hideOrder></Th>
								<Th hideOrder>Désignation</Th>
								<Th hideOrder>
									PPV
									<br />
									PPH
								</Th>
								{isView && <Th hideOrder>Remise cumulée</Th>}
								<Th hideOrder>Quantité</Th>
								{isView ? (
									<Th hideOrder>
										Unités
										<br />
										gratuites
									</Th>
								) : (
									<Th hideOrder>
										Remise
										<br />
										max
									</Th>
								)}
								<Th hideOrder>Total</Th>
								<Th hideOrder>Date de péremption</Th>
								<Th hideOrder style={{ maxWidth: 30, padding: 8 }}>
									<svg
										data-tooltip-id="tooltipId"
										data-tooltip-content=""
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8.66667 10.6667H8V8H7.33333M8 5.33333H8.00667M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
											stroke="#61788E"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</Th>
							</tr>
						</thead>
						<tbody>
							{pack.products.map((prod: any, prodIndex: number) => {
								let maxQuantity = 0;
								if (prod?.discountPerThreshold?.length > 0) {
									maxQuantity = prod?.discountPerThreshold?.reduce((a: any, b: any) =>
										a.minQuantity > b.minQuantity ? a : b
									).minQuantity;
								}
								if (prod?.freeUnits?.length > 0) {
									maxQuantity = prod?.freeUnits?.reduce((a: any, b: any) =>
										a.step > b.step ? a : b
									).step;
								}
								return (
									<ProductTr
										key={prod?.id}
										id={+`${index}${prodIndex}`}
										maxQuantity={maxQuantity}
										isView={isView}
										packIndex={index}
										prodIndex={prodIndex}
										setFocusedInput={setFocusedInput}
										focusedInput={focusedInput}
										{...{
											handleInputRef,
											prod,
											data: offer,
											setOffer,
										}}
									/>
								);
							})}
						</tbody>
					</Table>
				</TableContainer>
			))}
			<Tooltip
				id="tooltipId"
				place="bottom"
				content={
					<p>
						Total calculé sur la base de la
						<br />
						remise cumulée, plafonnée par la
						<br />
						remise maximale.
					</p>
				}
			/>
			<Tooltip id="tooltipTD" place="bottom" />
			<Tooltip
				id="expirationTooltip"
				place="top"
				render={({ content }) => (content ? <div>Date de péremption : {content}</div> : null)}
			/>
			{/* <Tooltip
				id="tooltipError"
				place="left"
				style={{ backgroundColor: '#fff', color: '#0D2339' }}
				render={({ content, activeAnchor }) =>
					activeAnchor?.getAttribute('data-Error') === '1' ? (
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<svg
								style={{ marginRight: 5 }}
								width="20"
								height="21"
								viewBox="0 0 20 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 7.16667V10.5M10 13.8333H10.0083M17.5 10.5C17.5 14.6421 14.1421 18 10 18C5.85786 18 2.5 14.6421 2.5 10.5C2.5 6.35786 5.85786 3 10 3C14.1421 3 17.5 6.35786 17.5 10.5Z"
									stroke="#E2515A"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							Quantité min non atteinte
						</span>
					) : activeAnchor?.getAttribute('data-Error') === '2' ? (
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<svg
								style={{ marginRight: 5 }}
								width="20"
								height="21"
								viewBox="0 0 20 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.0003 8V9.66667M10.0003 13H10.0086M4.22677 16.3333H15.7738C17.0568 16.3333 17.8587 14.9444 17.2172 13.8333L11.4436 3.83333C10.8021 2.72222 9.1984 2.72222 8.5569 3.83333L2.78339 13.8333C2.14189 14.9444 2.94377 16.3333 4.22677 16.3333Z"
									stroke="#E2515A"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							Produit obligatoire
						</span>
					) : null
				}
			/> */}
		</Card>
	);
};

export default OrderProducts;
