// labsv3
// const AUTH_CLIENT_ID = "10003";
// const AUTH_REDIRECT_URL = "https://api.labsv3.sobrus.ovh/auth/token";

// export const API_URL = "https://api.labsv3.sobrus.ovh/api/";
// export const API_URL2 = "https://api.labsv3.sobrus.ovh/";

// labs
// let AUTH_CLIENT_ID = '10065';
// let AUTH_REDIRECT_URL = 'https://api.labs.sobrus.ovh/auth/token';

// let API_URL = 'https://api.labs.sobrus.ovh/api/';
// let API_URL2 = 'https://api.labs.sobrus.ovh';
// let PASSWORD_URL = 'https://account.sobrus.ovh/change-password';
// let SOBRUS_URL = 'https://account.sobrus.ovh';

// let AUTH_DOMAIN = 'https://account.sobrus.ovh';

// let IMAGE_PATH = 'https://api.marketplace.sobrus.ovh/uploaded_files/offers/images/';
// let PRODUCT_IMG_PATH = 'https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/';
// let DEMAND_FILE = 'https://api.labs.sobrus.ovh/uploaded_files/demands/';

// if (process.env.REACT_APP_ENV === 'production') {
//     AUTH_REDIRECT_URL = 'https://api.labs.sobrus.com/auth/token';

//     API_URL = 'https://api.labs.sobrus.com/api/';
//     API_URL2 = 'https://api.labs.sobrus.com';
//     PASSWORD_URL = 'https://account.sobrus.com/change-password';
//     SOBRUS_URL = 'https://account.sobrus.com';
//     AUTH_DOMAIN = 'https://account.sobrus.com';
//     IMAGE_PATH = 'https://api.marketplace.sobrus.com/uploaded_files/offers/images/';
//     PRODUCT_IMG_PATH = 'https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/';
//     DEMAND_FILE = 'https://api.labs.sobrus.com/uploaded_files/demands/';
//     AUTH_CLIENT_ID = 10003;
// }

// export const AUTH_URL = `${AUTH_DOMAIN}/authorize?response_type=code&client_id=${AUTH_CLIENT_ID}&scope=openid%20offline_access%20profile%20email&redirect_uri=${AUTH_REDIRECT_URL}&prompt=login`;

// // https://account.sobrus.ovh/authorize?response_type=code&client_id=10061&scope=openid%20offline_access%20profile%20email&redirect_uri=https://api.fidny.sobrus.ovh/auth/token&prompt=login
// // https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fprompt%3Dlogin%26response_type%3Dcode%26client_id%3D10003%26scope%3Dopenid%2520offline_access%26redirect_uri%3Dhttps%3A%2F%2Fapi.labsv3.sobrus.ovh%2Fauth%2Ftoken

// export { API_URL, API_URL2, DEMAND_FILE, IMAGE_PATH, PASSWORD_URL, PRODUCT_IMG_PATH, SOBRUS_URL };

// "react-apexcharts": "^1.4.0",
// labsv3
// const AUTH_CLIENT_ID = "10003";
// const AUTH_REDIRECT_URL = "https://api.labsv3.sobrus.ovh/auth/token";

// export const API_URL = "https://api.labsv3.sobrus.ovh/api/";
// export const API_URL2 = "https://api.labsv3.sobrus.ovh/";

// labs
let AUTH_CLIENT_ID = '10065';
let AUTH_REDIRECT_URL = 'https://api.labs.sobrus.ovh/auth/token';

let API_URL = 'https://api.labs.sobrus.ovh/api/';
let API_URL2 = 'https://api.labs.sobrus.ovh';
let PASSWORD_URL = 'https://account.sobrus.ovh/auth/password-reset';
let SOBRUS_URL = 'https://account.sobrus.ovh';

let AUTH_DOMAIN = 'https://account.sobrus.ovh';

let IMAGE_PATH = 'https://api.marketplace.sobrus.ovh/uploaded_files/offers/images/';
let PRODUCT_IMG_PATH = 'https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/';
let DEMAND_FILE = 'https://api.labs.sobrus.ovh/uploaded_files/demands/';
let CDN_URL = 'https://cdn.sobrus.ovh/';

if (process.env.REACT_APP_ENV === 'production') {
	AUTH_REDIRECT_URL = 'https://api.labs.sobrus.com/auth/token';

	API_URL = 'https://api.labs.sobrus.com/api/';
	API_URL2 = 'https://api.labs.sobrus.com';
	PASSWORD_URL = 'https://account.sobrus.com/auth/password-reset';
	SOBRUS_URL = 'https://account.sobrus.com';
	AUTH_DOMAIN = 'https://account.sobrus.com';
	IMAGE_PATH = 'https://api.marketplace.sobrus.com/uploaded_files/offers/images/';
	PRODUCT_IMG_PATH = 'https://ma.pharma.sobrus.com/uploads/ma/products/watermarked/';
	DEMAND_FILE = 'https://api.labs.sobrus.com/uploaded_files/demands/';
	AUTH_CLIENT_ID = 10003;
	CDN_URL = 'https://sobruscdn.com/';
}

export const AUTH_URL = `${AUTH_DOMAIN}/authorize?response_type=code&client_id=${AUTH_CLIENT_ID}&scope=openid%20offline_access%20profile%20email&redirect_uri=${AUTH_REDIRECT_URL}&prompt=login`;

// https://account.sobrus.ovh/authorize?response_type=code&client_id=10061&scope=openid%20offline_access%20profile%20email&redirect_uri=https://api.fidny.sobrus.ovh/auth/token&prompt=login
// https://account.sobrus.ovh/login?redirectUri=https%3A%2F%2Fapi.account.sobrus.ovh%2Fauthorize%3Fprompt%3Dlogin%26response_type%3Dcode%26client_id%3D10003%26scope%3Dopenid%2520offline_access%26redirect_uri%3Dhttps%3A%2F%2Fapi.labsv3.sobrus.ovh%2Fauth%2Ftoken

export { API_URL, API_URL2, CDN_URL, DEMAND_FILE, IMAGE_PATH, PASSWORD_URL, PRODUCT_IMG_PATH, SOBRUS_URL };

// "react-apexcharts": "^1.4.0",
