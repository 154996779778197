import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from '@sobrus-com/sobrus-design-system-v2';

import './BackwardTitle.scss';

interface props {
	title: string;
	subTitle: string;
	to?: any;
	isBackward?: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const BackwardTitle: React.FC<props> = ({ title, subTitle, to = null, isBackward = false, onClick, ...att }) => {
	const navigate = useNavigate();

	return (
		<Breadcrumb
			title={title}
			subTitle={subTitle}
			isBackward={isBackward}
			onClick={onClick || (isBackward ? () => navigate(to, { replace: true }) : undefined)}
			{...att}
		/>
	);
};

export default BackwardTitle;
