import React, { useState } from 'react';
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import {
	AsyncSelect,
	IconButton,
	Input,
	InputSelect,
	Table,
	TableContainer,
	Th,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet } from 'api/API';

import { defaultValues } from './ImportAccountsStep3';

interface props {
	control: Control<defaultValues, any>;
	remove: UseFieldArrayRemove;
	accountsFields: {
		index: number;
		name: string;
		categoryId: number | null;
		administratorId: number | null;
		resourceId: {
			value: number;
			label: string;
		} | null;
		type: string;
		code: string;
		city: string | null;
		ref: string | number;
		id: string;
	}[];
	users: any[] | null;
	categories: any[] | null;
	ressources: any[] | null;
}

const AccountsTable: React.FC<props> = ({ control, remove, accountsFields, users, categories, ressources }) => {
	const { t } = useTranslation();

	// handle pagination stats
	const [itemOffset, setItemOffset] = useState(0);

	const endOffset = itemOffset + 8;
	const currentItems = accountsFields.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(accountsFields.length / 8);

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		const newOffset = (event.selected * 8) % accountsFields.length;
		setItemOffset(newOffset);
	};

	const getRessources = async (inputValue: string, callback: (data: any) => void) => {
		const res: any = await axiosGet(`pharmacies?limit=20&name=${inputValue}`);
		const data = res?.data?.response.map((item: any) => ({
			value: item?.id ?? 0,
			label: item?.name ?? '',
		}));
		callback(data);
	};

	console.log(currentItems);

	return (
		<TableContainer>
			<Table size="md">
				<thead>
					<tr>
						<Th hideOrder>Nom</Th>
						<Th hideOrder>Ville</Th>
						<Th hideOrder>Catégorie</Th>
						{/* <Th hideOrder>Type de ressource</Th> */}
						<Th hideOrder>Gestionnaire</Th>
						<Th hideOrder>Ressource</Th>
						<Th hideOrder>Type de client</Th>
						<Th hideOrder>Code client</Th>
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((ele, idx) => (
						<tr key={ele.id}>
							<td>
								<Controller
									control={control}
									name={`accounts.${ele?.index}.name`}
									rules={{ required: true }}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Input
											invalid={error !== undefined}
											placeholder="Nom"
											size="sm"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</td>
							<td>
								<InputSelect
									placeholder={t('City')}
									size="sm"
									menuPosition="fixed"
									options={[]}
									value={{
										value: ele?.city,
										label: ele?.city,
									}}
									disabled
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`accounts.${ele.index}.categoryId`}
									rules={{ required: true }}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<InputSelect
											invalid={error !== undefined}
											size="sm"
											menuPosition="fixed"
											placeholder="Catégorie compte"
											value={value}
											options={
												categories
													? categories?.map((cat: any) => ({
															value: cat?.id,
															label: cat?.name,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
							</td>
							{/* <td>
								<InputSelect
									placeholder={t('Relationship Type')}
									size="sm"
									menuPosition="fixed"
									options={[
										{
											value: 'Pharmacie',
											label: 'Pharmacie',
										},
										{
											value: 'Etablissement de santé',
											label: 'Etablissement de santé',
										},
										{
											value: 'Association',
											label: 'Association',
										},
									]}
									value={{
										value: 'Pharmacie',
										label: 'Pharmacie',
									}}
									disabled
								/>
							</td> */}
							<td>
								<Controller
									control={control}
									name={`accounts.${ele.index}.administratorId`}
									rules={{ required: true }}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<InputSelect
											invalid={error !== undefined}
											placeholder={t('Manager')}
											size="sm"
											menuPosition="fixed"
											value={value}
											options={
												users
													? users?.map((us) => ({
															value: us?.id,
															label: `${us?.firstName} ${us?.lastName}`,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`accounts.${ele.index}.resourceId`}
									rules={{ required: true }}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<AsyncSelect
											noOptionsMessage="Aucun résultat"
											invalid={error !== undefined}
											placeholder={t('Pharmacy')}
											menuPosition="fixed"
											size="sm"
											isSearchable
											value={value}
											defaultOptions={
												ressources
													? Array.from(ressources, (ress) => ({
															value: ress.id,
															label: `${ress?.name}${
																ress.city?.name !== '-' ? ` (${ress.city?.name})` : ''
															}`,
														}))
													: []
											}
											loadOptions={getRessources}
											onChange={(e: any) => {
												onChange(e);
											}}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`accounts.${ele.index}.type`}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											size="sm"
											menuPosition="fixed"
											placeholder="Type de client"
											value={value}
											options={[
												{
													value: 'Client',
													label: 'Client',
												},
												{
													value: 'Prospect',
													label: 'Prospect',
												},
											]}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
							</td>
							<td>
								<Controller
									control={control}
									name={`accounts.${ele.index}.code`}
									rules={{ required: true }}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Input
											invalid={error !== undefined}
											placeholder="Code client"
											size="sm"
											type="text"
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</td>
							<td>
								<IconButton color="tertiary" onClick={() => remove(ele?.index)}>
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M15.8335 5.83333L15.1107 15.9521C15.0484 16.8243 14.3227 17.5 13.4483 17.5H6.55203C5.67763 17.5 4.9519 16.8243 4.8896 15.9521L4.16683 5.83333M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M12.5002 5.83333V3.33333C12.5002 2.8731 12.1271 2.5 11.6668 2.5H8.3335C7.87326 2.5 7.50016 2.8731 7.50016 3.33333V5.83333M3.3335 5.83333H16.6668"
											stroke="#E2515A"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</IconButton>
							</td>
						</tr>
					))}
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={10}>
							<ReactPaginate
								breakLabel=""
								nextLabel=">"
								onPageChange={handlePageClick}
								pageRangeDisplayed={0}
								marginPagesDisplayed={0}
								pageCount={pageCount}
								// forcePage={pageNumber}
								previousLabel="<"
								renderOnZeroPageCount={null}
								activeClassName="sob-v2-TablePage"
								previousClassName="sob-v2-TablePage__btn"
								nextClassName="sob-v2-TablePage__btn"
								disabledClassName="sob-v2-TablePage__disabled"
								containerClassName="orderPropos-pagination-container sob-v2-table-pagination"
							/>
						</td>
					</tr>
				</tfoot>
			</Table>
		</TableContainer>
	);
};

export default AccountsTable;
