import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import './CheckboxSelect.scss';
import { createPortal } from 'react-dom';

const CheckboxSelect = ({
	placeholder,
	value,
	invalid,
	children,
	onSearch,
}: {
	placeholder?: string;
	value?: string | number | boolean | object | null;
	invalid?: boolean;
	children: ReactNode;
	onSearch: (term: string) => void;
}) => {
	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const listRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as Node) &&
				listRef.current &&
				!listRef.current.contains(event.target as Node)
			) {
				setOpen(false);
				setIsFocused(false);
			}
			if (listRef.current && listRef.current.contains(event.target as Node)) setIsFocused(false);
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [listRef]);

	useEffect(() => {
		if (open && containerRef.current && listRef.current) {
			const containerRect = containerRef.current.getBoundingClientRect();
			listRef.current.style.width = `${containerRect.width}px`;
			listRef.current.style.left = `${containerRect.left}px`;
			listRef.current.style.top = `${containerRect.bottom + 5}px`;
		}
	}, [open]);

	useEffect(() => {
		onSearch(searchTerm);
	}, [searchTerm, onSearch]);

	const handleInputFocus = () => {
		setOpen(true);
		setIsFocused(true);
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.select();
				inputRef.current.focus();
			}
		}, 100);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	return (
		<div className="sob-v2-inputContainer checkbox-select" ref={containerRef}>
			<div className={`sob-v2-form-control${invalid ? ' sob-v2-is-invalid' : ''}`} onClick={handleInputFocus}>
				{isFocused || !value ? (
					<input
						ref={inputRef}
						type="text"
						value={searchTerm}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						placeholder={placeholder}
						className="checkbox-select-input"
					/>
				) : (
					<div>{value as string}</div>
				)}
				<MdKeyboardArrowDown size={22} color="hsl(0, 0%, 80%)" />
			</div>
			{open &&
				createPortal(
					<div className="checkbox-select-list" ref={listRef} onClick={(e) => e.stopPropagation()}>
						{children}
					</div>,
					document.querySelector('#sob-v2-portal')!
				)}
		</div>
	);
};

export default CheckboxSelect;

export const CheckboxSelectListTitle: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => (
	<div className="checkbox-select-list--title" {...props} />
);

export const CheckboxSelectListUl: React.FC<React.HTMLProps<HTMLUListElement>> = (props) => (
	<ul className="checkbox-select-list--list" {...props} />
);

export const CheckboxSelectListItem: React.FC<React.HTMLProps<HTMLLIElement>> = (props) => <li {...props} />;
