import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
	Button,
	Card,
	CardTitle,
	Col,
	Container,
	FormFeedback,
	FormGroup,
	FormStep,
	FormSteps,
	InputSelect,
	Label,
	Row,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPatch } from 'api/API';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import { UserContext } from 'context/userContext';

import { useImportContext } from './ImportAccounts';

const ImportAccountsStep2 = () => {
	const [users, setUsers] = useState<any[] | null>(null);
	const [categories, setCategories] = useState<any[] | null>(null);
	const [contactCategories, setContactCategories] = useState<any[] | null>(null);
	const [submitting, setSubmitting] = useState(false);
	const { headerKeys, setHeaderKeys } = useImportContext();
	const navigate = useNavigate();
	const { id } = useParams();
	const { state }: any = useLocation();

	const { user }: any = useContext(UserContext);
	const { t } = useTranslation();
	const {
		reset,
		setValue,
		watch,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({
		defaultValues: {
			administratorId: user?.id,
			relationType: 'Pharmacie',
			accountCategoryId: null,
			contactCategoryId: null,
			account: {
				name: '',
				code: '',
				ice: '',
				city: '',
			},
			contact: {
				firstName: '',
				lastName: '',
				mobile: '',
				email: '',
				position: '',
				service: '',
			},
		},
	});

	const onSubmit = (values: any) => {
		if (!submitting) {
			setSubmitting(true);
			axiosPatch(`accounts/imports/${id}`, values).then((res: any) => {
				if (res?.data) {
					setSubmitting(false);
					navigate(`/accounts/import/step3/${res?.data?.id}`, { state: { data: res?.data } });
				} else {
					setSubmitting(false);
				}
			});
		}
	};

	useEffect(() => {
		if (!state) {
			axiosGet(`accounts/imports/${id}`).then((res: any) => {
				setHeaderKeys(res?.data?.fields?.file);
			});
		} else setHeaderKeys(state?.data?.fields?.file);
	}, []);

	useEffect(() => {
		if (!users) {
			axiosGet(`users?limit=10000`).then((res: any) => {
				setUsers(res?.data?.users);
			});
		}
	}, []);

	useEffect(() => {
		if (!categories) {
			axiosGet(`accounts/categories`).then((res: any) => {
				setCategories(res?.data);
			});
		}
	}, []);

	useEffect(() => {
		if (!contactCategories) {
			axiosGet(`contacts/categories?limit=10000`).then((res: any) => {
				setContactCategories(res?.data);
			});
		}
	}, []);

	return (
		<>
			<div className="labs-header">
				<BackwardTitle title={t('Importer')} subTitle={t('Accounts')} to={-1} isBackward />
				<div className="labs-header-btns">
					<Button
						color="tertiary"
						onClick={() => {
							setHeaderKeys([]);
							navigate('/accounts/import/step1');
						}}
					>
						Réinitialiser
					</Button>
					<Button color="primary" onClick={() => handleSubmit(onSubmit)()} loading={submitting}>
						Suivant
					</Button>
				</div>
			</div>
			<Card>
				<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
					<FormSteps style={{ width: 500 }}>
						<FormStep text="Upload" number={1} active succes />
						<FormStep text="Mapping" number={2} active />
						<FormStep text="Vérification" number={3} last />
					</FormSteps>
				</div>
				<CardTitle style={{ marginBottom: 0 }}>Configuration générale</CardTitle>
				<p style={{ marginBottom: 20, color: '#0D2339' }}>
					Ces valeurs seront appliquées à tous les comptes et contacts à importer
				</p>
				<Container>
					<Row>
						<Col>
							<FormGroup>
								<Label>{t('Manager')}*</Label>
								<Controller
									control={control}
									name="administratorId"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.administratorId !== undefined}
											placeholder={t('Manager')}
											value={value}
											options={
												users
													? users?.map((ele) => ({
															value: ele?.id,
															label: `${ele?.firstName} ${ele?.lastName}`,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.administratorId !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Catégorie compte*</Label>
								<Controller
									control={control}
									name="accountCategoryId"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.accountCategoryId !== undefined}
											placeholder="Catégorie compte"
											value={value}
											options={
												categories
													? categories?.map((ele) => ({
															value: ele?.id,
															label: ele?.name,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.accountCategoryId !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label>{t('Relationship Type')}*</Label>
								<Controller
									control={control}
									name="relationType"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.relationType !== undefined}
											placeholder={t('Relationship Type')}
											value={value}
											options={[
												{ value: 'Pharmacie', label: 'Pharmacie' },
												{
													value: 'Etablissement de santé',
													label: 'Etablissement de santé',
												},
												{ value: 'Association', label: 'Association' },
											]}
											onChange={(e: any) => onChange(e?.value)}
											disabled
										/>
									)}
								/>
								<FormFeedback invalid={errors.relationType !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Catégorie contact*</Label>
								<Controller
									control={control}
									name="contactCategoryId"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.contactCategoryId !== undefined}
											placeholder="Catégorie contact"
											value={value}
											options={
												contactCategories
													? contactCategories?.map((ele) => ({
															value: ele?.id,
															label: ele?.name,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.contactCategoryId !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
				</Container>
				<hr style={{ margin: '30px 0' }} />
				<CardTitle>Mapping de colonnes</CardTitle>
				<CardTitle style={{ fontSize: 14, marginTop: '30px' }}>Compte</CardTitle>
				<Container>
					<Row>
						<Col>
							<FormGroup>
								<Label>{t('Account Name')}*</Label>
								<Controller
									control={control}
									name="account.name"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.account?.name !== undefined}
											placeholder={t('Account Name')}
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.account?.name !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>{t('Client Code')}*</Label>
								<Controller
									control={control}
									name="account.code"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.account?.code !== undefined}
											placeholder={t('Client Code')}
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.account?.code !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label>ICE*</Label>
								<Controller
									control={control}
									name="account.ice"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.account?.ice !== undefined}
											placeholder="ICE"
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.account?.ice !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Ville*</Label>
								<Controller
									control={control}
									name="account.city"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.account?.city !== undefined}
											placeholder="Ville"
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.account?.ice !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
				</Container>
				<CardTitle style={{ fontSize: 14, marginTop: '30px' }}>Contact</CardTitle>
				<Container>
					<Row>
						<Col>
							<FormGroup>
								<Label>Nom</Label>
								<Controller
									control={control}
									name="contact.firstName"
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.contact?.firstName !== undefined}
											placeholder="Nom"
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.contact?.firstName !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Prénom</Label>
								<Controller
									control={control}
									name="contact.lastName"
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.contact?.lastName !== undefined}
											placeholder="Prénom"
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.contact?.lastName !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label>Portable</Label>
								<Controller
									control={control}
									name="contact.mobile"
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.contact?.mobile !== undefined}
											placeholder="Portable"
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.contact?.mobile !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>E-mail</Label>
								<Controller
									control={control}
									name="contact.email"
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.contact?.email !== undefined}
											placeholder="E-mail"
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.contact?.email !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label>Position</Label>
								<Controller
									control={control}
									name="contact.position"
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.contact?.position !== undefined}
											placeholder="Position"
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.contact?.position !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Service</Label>
								<Controller
									control={control}
									name="contact.service"
									render={({ field: { value, onChange } }) => (
										<InputSelect
											invalid={errors.contact?.service !== undefined}
											placeholder="Service"
											value={value}
											options={
												headerKeys
													? headerKeys?.map((ele) => ({
															value: ele,
															label: ele,
														}))
													: []
											}
											onChange={(e: any) => onChange(e?.value)}
										/>
									)}
								/>
								<FormFeedback invalid={errors.contact?.service !== undefined}>
									{t('This field is required')}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
				</Container>
			</Card>
		</>
	);
};

export default ImportAccountsStep2;
