import {
	Button,
	Card,
	CardSubTitle,
	CardTitle,
	Col,
	Container,
	FormFeedback,
	FormGroup,
	InputSelect,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	SwitchButton,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPatch } from 'api/API';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, UseFieldArrayUpdate, UseFormHandleSubmit, useFieldArray, useForm } from 'react-hook-form';

interface formValues {
	marketplace: {
		notifId: number;
		recipients: any[];
		status: boolean;
		label: string;
	}[];
}

const NotifsEmail = () => {
	const [users, setUsers] = useState<any[] | null>(null);
	const [openPopUp, setOpenPopUp] = useState(false);

	const currTarget = useRef<{
		notifId: number;
		index: number;
		recipients: any[];
		status: boolean;
		label: string;
	} | null>(null);

	const { control, getValues, handleSubmit } = useForm<formValues>({
		defaultValues: {
			marketplace: [],
		},
	});
	const { fields, replace, update } = useFieldArray({
		control,
		name: 'marketplace',
	});

	useEffect(() => {
		axiosGet(`notifications/email-params`).then((res: any) => {
			replace(res?.data?.marketplace?.map((ele: any) => ({ ...ele, notifId: ele?.id })));
		});
	}, []);

	useEffect(() => {
		if (!users) {
			axiosGet(`users?limit=10000`).then((res: any) => {
				setUsers(res?.data?.users);
			});
		}
	}, []);

	const onSubmit = (values: formValues) => {
		const currData = getValues(`marketplace.${currTarget.current!.index}`);

		axiosPatch(`notifications/email-params/${currTarget.current!.notifId}`, {
			status: currData.status,
			recipients: currData.recipients?.map((ele: any) => ele?.value ?? ele?.id),
		}).then((res: any) => {
			if (res?.data) {
				notify({
					position: 'top-center',
					type: 'success',
					msg: "L'email a bien été envoyé",
					delay: 5000,
				});
			} else {
				notify({
					position: 'top-center',
					type: 'danger',
					msg: 'Error ' + res?.response?.data?.message,
					delay: 5000,
				});
			}
		});
	};

	return (
		<section>
			<CardTitle>Notifications via email</CardTitle>
			<CardSubTitle>Marketplace</CardSubTitle>
			{fields &&
				fields?.map((ele, index) => (
					<Card shadow="md" key={ele?.id} style={{ marginTop: '20px' }}>
						<Container>
							<Row>
								<Col xs="1" style={{ display: 'flex', alignItems: 'center' }}>
									<Controller
										control={control}
										name={`marketplace.${index}.status`}
										render={({ field: { value, onChange } }) => (
											<SwitchButton
												checked={value}
												onChange={(e: any) => {
													if (e) {
														setOpenPopUp(true);
														currTarget.current = { ...ele, index: index };
													} else {
														onChange(e);
														currTarget.current = { ...ele, index: index };
														handleSubmit(onSubmit)();
													}
												}}
												labelDir="left"
											></SwitchButton>
										)}
									/>
								</Col>
								<Col xs="10" style={{ display: 'flex', alignItems: 'center' }}>
									<CardSubTitle style={{ margin: 0 }}>{ele?.label}</CardSubTitle>
								</Col>
								<Col xs="1">
									<Button
										color="ghost"
										style={{ color: '#2693D1', fontSize: 13, fontWeight: 600 }}
										onClick={() => {
											setOpenPopUp(true);
											currTarget.current = { ...ele, index: index };
										}}
									>
										Détails
									</Button>
								</Col>
							</Row>
						</Container>
					</Card>
				))}
			{openPopUp && (
				<SendEmailPopUp
					users={users}
					setModelOpen={setOpenPopUp}
					currTarget={currTarget.current}
					update={update}
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
				/>
			)}
		</section>
	);
};

interface props {
	users: any[] | null;
	setModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
	currTarget: {
		index: number;
		notifId: number;
		recipients: any[];
		status: boolean;
		label: string;
	} | null;
	update: UseFieldArrayUpdate<formValues, 'marketplace'>;
	handleSubmit: UseFormHandleSubmit<formValues, undefined>;
	onSubmit: (values: formValues) => void;
}

const SendEmailPopUp: React.FC<props> = ({ setModelOpen, users, currTarget, update, handleSubmit, onSubmit }) => {
	const [selectedUsers, setSelectedUsers] = useState<any[] | undefined>(
		currTarget?.recipients?.map((ele) => ({
			value: ele.id ?? ele?.value,
			label: ele?.firstName ? `${ele?.firstName} ${ele?.lastName}` : ele?.label,
		}))
	);
	const [submitted, setSubmitted] = useState(false);

	const save = () => {
		setSubmitted(true);

		if (selectedUsers && selectedUsers?.length > 0) {
			setModelOpen(false);
			update(currTarget!.index, { ...currTarget!, status: true, recipients: selectedUsers });
			handleSubmit(onSubmit)();
		}
	};

	return (
		<Modal setOpen={() => setModelOpen(false)} size={'md'} className="sendEmail-popup-container">
			<ModalHeader setOpen={() => setModelOpen(false)} title="Détails de notification" />
			<ModalBody>
				<Container>
					<Row>
						<Col>
							<FormGroup>
								<Label style={{ marginBottom: 12 }}>Destinataires</Label>
								<InputSelect
									invalid={submitted && selectedUsers && selectedUsers?.length <= 0}
									noOptionsMessage="Aucun résultat"
									placeholder="Destinataires"
									menuPosition="fixed"
									isSearchable
									isMulti
									value={selectedUsers}
									options={
										users
											? users.map((ele) => ({
													value: ele.id,
													label: `${ele?.firstName} ${ele?.lastName}`,
												}))
											: []
									}
									onChange={(e: any) => setSelectedUsers(e)}
								/>
								<FormFeedback invalid={submitted && selectedUsers && selectedUsers?.length <= 0}>
									Ce champ est requis
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
				</Container>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={save}>
					Sauvegarder
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default NotifsEmail;
