import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AsyncSelect,
	Avatar,
	Button,
	CardTitle,
	Col,
	Container,
	FormFeedback,
	FormGroup,
	InputSelect,
	Label,
	notify,
	Row,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPost } from 'api/API';
import * as yup from 'yup';

import EmailMarketplace from './rapportemail/EmailMarketplace';
import SendEmailPopUp from './SendEmailPopUp';

const schema = (t) =>
	yup.object({
		// recipients: yup
		//     .array()
		//     .of(
		//         yup.object().shape({
		//             value: yup.string(),
		//             label: yup.string(),
		//         })
		//     )
		//     .min(1, "Ce champ est requis")
		//     .required("Ce champ est requis"),
		frequency: yup.object().required(t('This field is required')),
		status: yup.object().required(t('This field is required')),
	});

function MarketPlaceEmail() {
	const [users, setUsers] = useState(null);
	const [params, setParams] = useState(null);
	const [isSending, setIsSending] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const isSubmitting = useRef(false);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const {
		reset,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({
		resolver: yupResolver(schema(t)),
		defaultValues: {
			recipients: '',
			frequency: '',
			status: '',
		},
	});

	const onSubmit = async (values) => {
		// alert(JSON.stringify(values));
		if (!isSubmitting.current) {
			isSubmitting.current = true;
			const data = {
				recipients: values.recipients.map((ele) => ele.value),
				frequency: values.frequency.value,
				status: values.status.value,
			};

			axiosPost('marketplace-params', data).then((res, err) => {
				if (res?.data) {
					notify({
						position: 'top-center',
						type: 'success',
						msg: 'Le rapport a bien été activé',
						delay: 5000,
					});
				} else {
					notify({ position: 'top-center', type: 'danger', msg: 'Error', delay: 5000 });
				}
				isSubmitting.current = false;
			});
		}
	};

	useEffect(() => {
		if (!users) {
			axiosGet(`users?limit=10000`).then((res) => {
				setUsers(res?.data?.users);
			});
		}
	}, []);

	useEffect(() => {
		axiosGet(`marketplace-params`).then((res) => {
			setParams(res?.data);
			reset({
				recipients: res?.data?.recipients?.map((ele) => ({
					value: ele.id,
					label: (
						<div>
							<Avatar className="" image="" name={`${ele?.firstName} ${ele?.lastName}`} size="sm">
								Statut
							</Avatar>{' '}
							{`${ele?.firstName} ${ele?.lastName}`}
						</div>
					),
				})),
				frequency:
					res?.data?.frequency === 'daily'
						? { value: 'daily', label: t('Daily') }
						: res?.data?.frequency === 'weekly'
							? { value: 'weekly', label: t('Weekly') }
							: res?.data?.frequency === 'monthly'
								? { value: 'monthly', label: t('Monthly') }
								: null,
				status:
					res?.data?.status === 'active'
						? { value: 'active', label: t('Active') }
						: { value: 'inactive', label: t('Inactive') },
			});
		});
	}, []);

	return (
		<form className="labs-table fade" id="postForm" onSubmit={handleSubmit(onSubmit)}>
			<div className="labs-settings-btns">
				<Button
					type="button"
					color="secondary"
					onClick={() => navigate('/settings/rapportMarketplace/history')}
				>
					{t('History')}
				</Button>
				<Button
					type="button"
					onClick={() => setOpenModal(true)}
					loading={isSending}
					color="secondary"
					style={{ marginLeft: 20 }}
				>
					{t('Send test email')}
				</Button>
				<Button form="postForm" type="submit" style={{ marginLeft: 20 }}>
					{t('Save')}
				</Button>
				{openModal && <SendEmailPopUp setModelOpen={setOpenModal} users={users} isMarketPlace />}
			</div>
			<Container>
				<Row>
					<Col>
						<h2 className="sob-v2-card-title" style={{ marginBottom: '1rem' }}>
							{t('Marketplace report')}
						</h2>
						<Container>
							<Row>
								<Col>
									<FormGroup>
										<Label>{t('Recipient')}</Label>
										<Controller
											control={control}
											name="recipients"
											render={({ field: { value, onChange, name } }) => (
												<InputSelect
													noOptionsMessage="Aucun résultat"
													placeholder={t('Recipient')}
													isSearchable
													isMulti
													value={value}
													options={
														users
															? users.map((ele) => ({
																	value: ele.id,
																	label: (
																		<div>
																			<Avatar
																				className=""
																				image=""
																				name={`${ele?.firstName} ${ele?.lastName}`}
																				size="sm"
																			>
																				Statut
																			</Avatar>{' '}
																			{`${ele?.firstName} ${ele?.lastName}`}
																		</div>
																	),
																}))
															: []
													}
													onChange={onChange}
												/>
											)}
										/>
										{/* <FormFeedback invalid>Ce champ est requis</FormFeedback> */}
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label>{t('Frequency')}*</Label>
										<Controller
											control={control}
											name="frequency"
											render={({ field: { value, onChange, name } }) => (
												<InputSelect
													invalid={errors.frequency}
													placeholder={t('Frequency')}
													value={value}
													options={[
														{ value: 'daily', label: t('Daily') },
														{ value: 'weekly', label: t('Weekly') },
														{ value: 'monthly', label: t('Monthly') },
													]}
													onChange={onChange}
												/>
											)}
										/>
										<FormFeedback invalid={errors.frequency}>
											{t('This field is required')}
										</FormFeedback>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label>{t('Status')}*</Label>
										<Controller
											control={control}
											name="status"
											render={({ field: { value, onChange, name } }) => (
												<InputSelect
													invalid={errors.status}
													placeholder={t('Status')}
													value={value}
													options={[
														{ value: 'active', label: t('Active') },
														{ value: 'inactive', label: t('Inactive') },
													]}
													onChange={onChange}
												/>
											)}
										/>
										<FormFeedback invalid={errors.status}>
											{t('This field is required')}
										</FormFeedback>
									</FormGroup>
								</Col>
							</Row>
						</Container>
					</Col>
					<Col className="marketplace-email-preview" xs="8">
						<div>
							<h2 className="sob-v2-card-title" style={{ marginBottom: '1rem' }}>
								{t('Report Preview')}
							</h2>
							<EmailMarketplace />
						</div>
					</Col>
				</Row>
			</Container>
		</form>
	);
}

export default MarketPlaceEmail;
// {
//     "frequency": "daily",
//     "status": "active",
//     "recipients": [
//         {
//             "id": 25066,
//             "firstName": "Omar",
//             "lastName": "SEFIANI"
//         },
//         {
//             "id": 638186,
//             "firstName": "NISS",
//             "lastName": "M"
//         },
//         {
//             "id": 638160,
//             "firstName": "samy",
//             "lastName": "akhouad"
//         }
//     ]
// }
//* ********** Post */
// {
//     "recipients": [
//         25066,
//         638186,
//         638160
//     ],
//     "status": "active",
//     "frequency": "daily"
// }
