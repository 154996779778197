import {
	Button,
	CardSubTitle,
	CardTitle,
	Col,
	CompositeInput,
	Container,
	FileInput,
	FormFeedback,
	FormGroup,
	Input,
	InputSelect,
	Label,
	Row,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPost } from 'api/API';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface SupplierInfo {
	supplierName: string;
	payableTo: string;
	supplierLogo: File[];
	deliveryTransporters: string[];
	paymentMethods: number[];
	deliveryTimeframe: string;
	deliveryTimeframeUnit: string;
	paymentTimeframe: string;
	paymentTimeframeUnit: string;
}

const SupplierDetails = () => {
	const [payTypes, setPayTypes] = useState<any[]>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		reset,
		setValue,
		watch,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm<SupplierInfo>({
		defaultValues: {
			supplierName: '',
			payableTo: '',
			supplierLogo: [],
			deliveryTransporters: [],
			paymentMethods: [],
			deliveryTimeframe: '',
			deliveryTimeframeUnit: 'days',
			paymentTimeframe: '',
			paymentTimeframeUnit: 'days',
		},
	});

	const onSubmit = (values: SupplierInfo) => {
		if (!isSubmitting) {
			setIsSubmitting(true);
			const data = {
				supplierName: values?.supplierName,
				payableTo: values?.payableTo,
				// supplierLogo: values?.supplierLogo,
				deliveryTransporters: values?.deliveryTransporters
					? values?.deliveryTransporters.map((ele: any) => ele?.value)
					: undefined,
				paymentMethods: values?.paymentMethods
					? values?.paymentMethods.map((ele: any) => ele?.value)
					: undefined,
				deliveryTimeframe: values?.deliveryTimeframe || undefined,
				deliveryTimeframeUnit: values?.deliveryTimeframe ? values?.deliveryTimeframeUnit : undefined,
				paymentTimeframe: values?.paymentTimeframe || undefined,
				paymentTimeframeUnit: values?.paymentTimeframe ? values?.paymentTimeframeUnit : undefined,
			};

			axiosPost(`supplier-settings`, data).then((res: any) => {
				if (res?.data) {
					notify({
						position: 'top-center',
						type: 'success',
						msg: 'Les details fournisseur sont enregistrée avec succès',
						delay: 5000,
					});
				} else {
					notify({
						position: 'top-center',
						type: 'danger',
						msg: res?.response?.data?.message,
						delay: 5000,
					});
				}
				setIsSubmitting(false);
			});
		}
	};

	useEffect(() => {
		axiosGet(`supplier-settings`).then((res: any) => {
			reset({
				supplierName: res?.data?.supplierName,
				payableTo: res?.data?.payableTo,
				supplierLogo: res?.data?.supplierLogo,
				deliveryTransporters: res?.data?.deliveryTransporters
					? res?.data?.deliveryTransporters?.map((ele: any) => ({ value: ele, label: ele }))
					: [],
				paymentMethods: res?.data?.paymentMethods
					? res?.data?.paymentMethods?.map((ele: any) => ({ value: ele?.id, label: ele?.label }))
					: [],
				deliveryTimeframe: res?.data?.deliveryTimeframe,
				deliveryTimeframeUnit: res?.data?.deliveryTimeframeUnit || 'days',
				paymentTimeframe: res?.data?.paymentTimeframe,
				paymentTimeframeUnit: res?.data?.paymentTimeframeUnit || 'days',
			});
		});
	}, []);

	useEffect(() => {
		if (payTypes.length <= 0) {
			axiosGet(`payment-methods`).then((res: any) => {
				setPayTypes(res?.data?.payment_methods);
			});
		}
	}, []);

	return (
		<>
			<div className="labs-settings-btns">
				<Button onClick={() => handleSubmit(onSubmit)()} loading={isSubmitting}>
					Enregistrer
				</Button>
			</div>
			<CardTitle>Détails fournisseur</CardTitle>
			<Container>
				<Row>
					<Col>
						<FormGroup>
							<Label>Nom du laboratoire*</Label>
							<Controller
								control={control}
								name="supplierName"
								rules={{ required: true }}
								render={({ field: { value, onChange, name }, fieldState: { error } }) => (
									<>
										<Input
											invalid={error !== undefined}
											id={name}
											name={name}
											placeholder="Nom du laboratoire"
											type="text"
											value={value}
											onChange={onChange}
										/>
										<FormFeedback invalid={error !== undefined}>Ce champ est requis</FormFeedback>
									</>
								)}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>À l&apos;ordre de*</Label>
							<Controller
								control={control}
								name="payableTo"
								rules={{ required: true }}
								render={({ field: { value, onChange, name }, fieldState: { error } }) => (
									<>
										<Input
											invalid={error !== undefined}
											id={name}
											name={name}
											placeholder="À l'ordre de"
											type="text"
											value={value}
											onChange={onChange}
										/>
										<FormFeedback invalid={error !== undefined}>Ce champ est requis</FormFeedback>
									</>
								)}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label>Logo du laboratoire</Label>
							<Controller
								control={control}
								name="supplierLogo"
								render={({ field: { value, onChange, name }, fieldState: { error } }) => (
									<>
										<FileInput
											accept="image/png, image/jpeg, image/webp"
											maxSize={5500000}
											onFileUpload={(data) => onChange(data)}
											preview
											// progressValue={0}
											selectedFiles={value}
											errorMessage="Votre image dépasse la taille de fichier maximale de 5 Mo autorisée"
											text={
												<div style={{ fontSize: 13, fontWeight: 500 }}>
													Glissez et déposez une photo ici
													<p style={{ fontWeight: 400, color: '#627282' }}>
														Dimensions recommandées : 1000 x 1000 px
													</p>
												</div>
											}
										/>
										<FormFeedback invalid={error !== undefined}>Ce champ est requis</FormFeedback>
									</>
								)}
							/>
						</FormGroup>
					</Col>
				</Row>
			</Container>
			<CardSubTitle>Paiement et livraison</CardSubTitle>
			<Container>
				<Row>
					<Col>
						<FormGroup>
							<Label>Mode de règlement</Label>
							<Controller
								control={control}
								name="paymentMethods"
								render={({ field: { value, onChange, name } }) => (
									<InputSelect
										placeholder="Modes de paiement acceptés"
										isMulti
										value={value}
										options={
											payTypes
												? Array.from(payTypes, (type) => ({
														value: type.id,
														label: type.label,
													}))
												: []
										}
										onChange={onChange}
									/>
								)}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Délai de paiement*</Label>
							<CompositeInput>
								<Controller
									control={control}
									name="paymentTimeframe"
									rules={{
										validate: (value) =>
											typeof +value === 'number' && +value >= 0
												? true
												: 'Veuillez saisir un nombre entier',
									}}
									render={({ field: { value, onChange, name } }) => (
										<Input
											invalid={errors?.paymentTimeframe !== undefined}
											id={name}
											name={name}
											placeholder="Délai de paiement"
											type="text"
											value={value}
											onChange={(e: any) =>
												onChange(
													e.target.value !== '' ? e.target.value.replace(/[^0-9]/g, '') : null
												)
											}
										/>
									)}
								/>
								<Controller
									control={control}
									name="paymentTimeframeUnit"
									render={({ field: { value, onChange, name } }) => (
										<InputSelect
											value={value}
											onChange={(e: any) => onChange(e?.value)}
											options={[
												{ value: 'hours', label: 'Heures' },
												{ value: 'days', label: 'Jours' },
												{ value: 'weeks', label: 'Semaines' },
												{ value: 'months', label: 'Mois' },
											]}
										/>
									)}
								/>
							</CompositeInput>
							<FormFeedback invalid={errors.paymentTimeframe !== undefined}>
								{errors.paymentTimeframe?.message}
							</FormFeedback>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label>Transporteurs</Label>
							<Controller
								control={control}
								name="deliveryTransporters"
								render={({ field: { value, onChange } }) => (
									<InputSelect
										value={value}
										isMulti
										placeholder="Transporteurs"
										onChange={(e: any) => onChange(e || null)}
										options={[
											{ value: 'CTM', label: 'CTM' },
											{ value: 'SDTM', label: 'SDTM' },
											{ value: 'LOGIPHAR', label: 'LOGIPHAR' },
											{ value: 'MAROC EXPRESS', label: 'MAROC EXPRESS' },
											{ value: 'SAT', label: 'SAT' },
											{ value: 'GHAZALA', label: 'GHAZALA' },
											{ value: 'AMANA', label: 'AMANA' },
											{ value: 'DHL', label: 'DHL' },
											{ value: 'ARAMEX', label: 'ARAMEX' },
											{ value: 'CARRE', label: 'CARRE' },
											{ value: 'MTR', label: 'MTR' },
										]}
									/>
								)}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Délai de livraison*</Label>
							<CompositeInput>
								<Controller
									control={control}
									name="deliveryTimeframe"
									rules={{
										validate: (value) =>
											typeof +value === 'number' && +value >= 0
												? true
												: 'Veuillez saisir un nombre entier',
									}}
									render={({ field: { value, onChange, name } }) => (
										<Input
											invalid={errors?.deliveryTimeframe !== undefined}
											id={name}
											name={name}
											placeholder="Délai de livraison"
											type="text"
											value={value}
											onChange={(e: any) =>
												onChange(
													e.target.value !== '' ? e.target.value.replace(/[^0-9]/g, '') : null
												)
											}
										/>
									)}
								/>
								<Controller
									control={control}
									name="deliveryTimeframeUnit"
									rules={{ required: true }}
									render={({ field: { value, onChange } }) => (
										<InputSelect
											value={value}
											onChange={(e: any) => onChange(e?.value)}
											options={[
												{ value: 'hours', label: 'Heures' },
												{ value: 'days', label: 'Jours' },
												{ value: 'weeks', label: 'Semaines' },
												{ value: 'months', label: 'Mois' },
											]}
										/>
									)}
								/>
							</CompositeInput>
							<FormFeedback invalid={errors.deliveryTimeframe !== undefined}>
								{errors.deliveryTimeframe?.message}
							</FormFeedback>
						</FormGroup>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default SupplierDetails;
