import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Input, Label, notify, TableHeaderTitle } from '@sobrus-com/sobrus-design-system-v2';
import { UserContext } from 'context/userContext';

import { useAcl } from 'ACL/acl';
import { axiosGet, axiosPost } from 'api/API';
import InputPopUp from 'components/inputPopUp/InputPopUp';

function EmailIn() {
	const [open, setOpen] = useState(false);
	const [emailInput, setEmailInput] = useState('');
	const { user } = useContext(UserContext);
	const acl = useAcl();
	const { t } = useTranslation();

	useEffect(() => {
		axiosGet('emailconfig').then((res) => {
			setEmailInput(user?.emailConfig);
		});
	}, []);

	const addEmail = () => {
		axiosPost(`emailconfig`, { email: emailInput }).then((res) => {
			if (res?.data) {
				notify({ position: 'top-center', type: 'success', msg: "L'email' a bien été enregistré", delay: 5000 });
			} else {
				notify({ position: 'top-center', type: 'danger', msg: "L'email' n'a pas été enregistré", delay: 5000 });
			}
		});
	};

	return (
		<section className="email-in-container fade">
			<TableHeaderTitle>Email de réception</TableHeaderTitle>
			{!emailInput && (
				<div className="email-in-body">
					<img src={require('assets/email-b-icon.svg').default} alt="" />
					<h5>{t('No Email Available')}</h5>
					{acl?.demand?.addEmailAddress && <Button onClick={() => setOpen(true)}>Ajouter un email</Button>}
				</div>
			)}
			{emailInput && (
				<div className="email-in-exist">
					<FormGroup>
						<Label>{t('Company Email')}</Label>
						<Input
							id="emailin"
							name="emailin"
							placeholder={t('Company Email')}
							type="email"
							value={emailInput}
							onChange={(e) => setEmailInput()}
							disabled
						/>
					</FormGroup>
					<p>
						<img src={require('assets/notice-icon.svg').default} alt="" />
						{t('To update your email address, send an email to the address')} <i>support@sobrus.com</i>
					</p>
				</div>
			)}
			{open && (
				<InputPopUp
					isOpen={open}
					setIsOpen={setOpen}
					title="Ajouter un email entrant"
					answerCallback={addEmail}
				>
					<FormGroup>
						<Label>{t('Company Email')}</Label>
						<Input
							id="emailin"
							name="emailin"
							placeholder={t('Company Email')}
							type="email"
							// value={email}
							onChange={(e) => setEmailInput(e.target.value)}
						/>
					</FormGroup>
				</InputPopUp>
			)}
		</section>
	);
}

export default EmailIn;
