import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AsyncSelect,
	Button,
	Card,
	CardItem,
	CheckBox,
	Col,
	Container,
	FormFeedback,
	FormGroup,
	Input,
	InputSelect,
	Label,
	notify,
	Row,
} from '@sobrus-com/sobrus-design-system-v2';
import * as yup from 'yup';

import { useAcl } from '../../../ACL/acl';
import { axiosGet, axiosPatch, axiosPost } from '../../../api/API';
import BackwardTitle from '../../../components/backwardTitle/BackwardTitle';

const schema = (t) =>
	yup.object({
		roleId: yup.object().required(t('This field is required')),
		accessType: yup.object().required(t('This field is required')),
	});

function EditUser() {
	const [user, setUser] = useState(null);
	const [users, setUsers] = useState(null);
	const [roles, setRoles] = useState(null);
	const { id } = useParams();
	const navigate = useNavigate('');
	const isSubmitting = useRef(false);
	const { t } = useTranslation();

	const acl = useAcl();
	const {
		reset,
		setValue,
		watch,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({
		resolver: yupResolver(schema(t)),
		defaultValues: {
			accessType: '',
			roleId: '',
			name: '',
			administratorId: '',
			isSupervisor: false,
		},
	});

	const isSuper = watch('isSupervisor');

	const onSubmit = async (values) => {
		if (!isSubmitting.current) {
			isSubmitting.current = true;
			const data = {
				accessType: values.accessType?.value,
				roleId: values.roleId?.value,
				name: values.name,
				administratorId: !values.isSupervisor ? values.administratorId?.value : null,
				isSupervisor: values.isSupervisor,
			};
			axiosPatch(`users/${id}/accessTypes`, data).then((res, err) => {
				if (res?.data) {
					notify({
						position: 'top-center',
						type: 'success',
						msg: 'Le rôle a été affecté avec succès',
						delay: 5000,
					});
					navigate(`/settings/users`);
				} else {
					notify({
						position: 'top-center',
						type: 'danger',
						msg: `Le rôle n'a pas été affecté, ${res?.data?.msg}`,
						delay: 5000,
					});
					isSubmitting.current = false;
				}
			});
		}
	};

	useEffect(() => {
		if (!users) {
			axiosGet(`users?limit=10000`).then((res) => {
				setUsers(res?.data?.users);
			});
		}
	}, []);

	useEffect(() => {
		if (!roles) {
			axiosGet(`roles`).then((res) => {
				setRoles(res?.data.roles);
			});
		}
	}, []);

	useEffect(() => {
		if (!user && id) {
			axiosGet(`users/${id}/accessTypes`).then((res) => {
				setUser(res?.data);
				reset({
					accessType:
						res?.data?.accessType === 'self'
							? { value: 'self', label: t('Access Only Own Data') }
							: res?.data?.accessType === 'team'
								? { value: 'team', label: t('Access Team Data') }
								: { value: 'full', label: t('Access All Data') },
					roleId: {
						value: res?.data?.role?.id,
						label: res?.data?.role?.name,
					},
					name: `${res?.data?.user?.firstName} ${res?.data?.user?.lastName}`,
					administratorId: res?.data?.supervisor
						? {
								value: res?.data?.supervisor?.id,
								label: `${res?.data?.supervisor?.firstName} ${res?.data?.supervisor?.lastName}`,
							}
						: '',
					isSupervisor: res?.data?.isSupervisor,
				});
			});
		}
	}, []);

	return (
		<section className="user-edit-container fade">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="labs-settings-btns">
					<Button type="submit" plus={false}>
						{t('Save')}
					</Button>
				</div>
				<BackwardTitle title={`${t('Edit')} : ${user?.fullname}`} to="/settings/users" />
				<Card>
					<Container>
						<Row>
							<Col>
								<CardItem>
									<FormGroup>
										<Label>{t('Name')}</Label>
										<Controller
											control={control}
											name="name"
											render={({ field: { value, onChange, name } }) => (
												<Input
													invalid={errors.name}
													id={name}
													name={name}
													placeholder={t('Name')}
													type="text"
													value={value}
													onChange={onChange}
													disabled
												/>
											)}
										/>
										<FormFeedback invalid={errors.name}>{errors.name?.message}</FormFeedback>
									</FormGroup>
								</CardItem>
								{acl?.setting?.user?.affect_role && (
									<CardItem>
										<FormGroup>
											<Label>{t('Roles')}</Label>
											<Controller
												control={control}
												name="roleId"
												render={({ field: { value, onChange, name } }) => (
													<InputSelect
														noOptionsMessage="Aucun résultat"
														invalid={errors.roleId}
														placeholder={t('Roles')}
														isClearable
														isSearchable
														value={value}
														options={
															roles
																? Array.from(roles, (ele) => ({
																		value: ele.id,
																		label: ele.name,
																	}))
																: []
														}
														onChange={onChange}
													/>
												)}
											/>
											<FormFeedback invalid={errors.roleId}>
												{t('This field is required')}
											</FormFeedback>
										</FormGroup>
									</CardItem>
								)}
								<CardItem>
									<FormGroup>
										<Label>{t('Access Type')}</Label>
										<Controller
											control={control}
											name="accessType"
											render={({ field: { value, onChange, name } }) => (
												<InputSelect
													invalid={errors.accessType}
													placeholder={t('Access Type')}
													isClearable
													value={value}
													options={[
														{ value: 'self', label: t('Access Only Own Data') },
														{ value: 'full', label: t('Access All Data') },
														{ value: 'team', label: t('Access Team Data') },
													]}
													onChange={onChange}
												/>
											)}
										/>
										<FormFeedback invalid={errors.accessType}>
											{t('This field is required')}
										</FormFeedback>
									</FormGroup>
								</CardItem>
							</Col>
							<Col>
								{!isSuper && (
									<CardItem>
										<FormGroup>
											<Label>{t('Manager')}</Label>
											<Controller
												control={control}
												name="administratorId"
												render={({ field: { value, onChange, name } }) => (
													<InputSelect
														// invalid={submitted && !gestionnaire ? true : false}
														placeholder={t('Manager')}
														isClearable
														value={value}
														options={
															users
																? Array.from(users, (ele) => ({
																		value: ele.id,
																		label: `${ele.firstName} ${ele.lastName}`,
																	}))
																: []
														}
														onChange={onChange}
													/>
												)}
											/>
											<FormFeedback>{t('This field is required')}</FormFeedback>
										</FormGroup>
									</CardItem>
								)}
								<CardItem>
									<FormGroup>
										<Label for="">{t('Is Supervisor')}</Label>
										<div
											style={{
												height: '54px',
												display: 'flex',
												alignItems: 'flex-start',
												justifyContent: 'center',
												flexDirection: 'column',
											}}
										>
											<Controller
												control={control}
												name="isSupervisor"
												render={({ field: { value, onChange, name } }) => (
													<CheckBox id="superviser" checked={value} onChange={onChange} />
												)}
											/>
										</div>
									</FormGroup>
								</CardItem>
							</Col>
						</Row>
					</Container>
				</Card>
			</form>
		</section>
	);
}

export default EditUser;
