import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
	AnimatedTr,
	Button,
	EmptyData,
	IconButton,
	notify,
	Table,
	TableContainer,
	TableHeader,
	TableHeaderTitle,
	TableLoader,
	TableNextPage,
	TablePage,
	TablePreviousPage,
	Th,
} from '@sobrus-com/sobrus-design-system-v2';
import { useAcl } from 'ACL/acl';
import { axiosGet, axiosPatch } from 'api/API';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import ConfirmationPopUp from 'components/ConfirmationPopUp';
import { format, parseISO } from 'date-fns';
import usePagination from 'utils/usePagination';
import useTitle from 'utils/useTitle';

function ImportsHistory() {
	const [isFetching, setIsFetching] = useState(true);
	const [data, setData] = useState<any[]>([]);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const acl = useAcl();
	const { t } = useTranslation();

	useTitle(t('Accounts'));

	const { pageNumber, pageChange, setStoredPage } = usePagination(setIsFetching);

	useEffect(() => {
		if (isFetching) {
			const searchTerm = { page: pageNumber, limit: 15 };
			axiosGet(`accounts/imports`, searchTerm).then((res: any) => {
				setData(res?.data);
				setIsFetching(false);
			});
		}
	}, [isFetching]);

	const handleRevok = () => {
		axiosPatch('accounts/imports/rollback').then((res: any) => {
			if (res?.data)
				notify({
					type: 'success',
					msg: 'Retour en arrière est effectuée avec succès',
				});
			else
				notify({
					type: 'danger',
					msg: res?.response?.data?.message,
				});
		});
	};

	return (
		<section className="products-container labs-container fade">
			<div className="products-header">
				<BackwardTitle title="Historique des mappings" subTitle={t('Accounts')} to="/accounts" isBackward />
				<Button color="danger-tertiary" onClick={() => setOpen(true)}>
					Retour en arrière
				</Button>
				{open && <ConfirmationPopUp answerCallback={handleRevok} isOpen={open} setIsOpen={setOpen} hasTitle />}
			</div>
			<TableContainer>
				<TableHeader>
					<TableHeaderTitle>Liste des mappings</TableHeaderTitle>
				</TableHeader>
				<Table>
					<thead>
						<tr>
							<Th hideOrder>Nom du fichier</Th>
							<Th hideOrder>Comptes déjà mappés</Th>
							<Th hideOrder>Nombre de succès</Th>
							<Th hideOrder>Nombre d&apos;échecs</Th>
							<Th hideOrder>Importé par</Th>
							<Th hideOrder>Importé le</Th>
						</tr>
					</thead>
					{isFetching && (
						<TableLoader
							loading
							columns={[
								{ type: 'extra-small' },
								{ type: 'large' },
								{ type: 'extra-small' },
								{ type: 'extra-small' },
								{ type: 'extra-small' },
								{ type: 'extra-small' },
								{ type: 'dot' },
							]}
						/>
					)}
					<tbody>
						{!isFetching &&
							data &&
							data.map((ele, index) => (
								<AnimatedTr index={index} duration={20} key={`${ele.id}compteArch`}>
									<th scope="row">{ele.file ?? '--'}</th>
									<td>{ele.code ?? '--'}</td>
									<td>{ele.name?.length > 50 ? `${ele.name.substring(0, 50)}...` : ele.name}</td>
									<td>{ele.accountCategory?.name ?? '--'}</td>
									<td>{`${ele.updatedBy?.firstName} ${ele.updatedBy?.lastName}` ?? '--'}</td>
									<td>{ele.updatedAt ? format(parseISO(ele.updatedAt), 'dd-MM-yyyy') : '--'}</td>
									<td>
										{ele?.status === 'pending' && (
											<IconButton
												title="Suspendre"
												style={{ marginLeft: '8px' }}
												color="tertiary"
												size="sm"
												onClick={() =>
													ele?.step === 1
														? navigate(`/accounts/import/step2/${ele.id}`)
														: ele?.step === 2
															? navigate(`/accounts/import/step3/${ele.id}`)
															: false
												}
											>
												<svg
													width="15"
													height="15"
													viewBox="0 0 15 15"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M9.59979 3.49452L11.6622 5.55691M10.4748 2.61952C11.0443 2.05 11.9677 2.05 12.5372 2.61952C13.1067 3.18903 13.1067 4.1124 12.5372 4.68191L4.50602 12.7131H2.46436V10.63L10.4748 2.61952Z"
														stroke="#185754"
														strokeWidth="1.4"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</IconButton>
										)}
									</td>
									<td width="75px" />
								</AnimatedTr>
							))}
					</tbody>
					<tfoot>
						{data?.length > 0 && (
							<tr>
								<td colSpan={10}>
									<div className="sob-v2-table-pagination">
										<TablePreviousPage
											onClick={() => pageChange('prev')}
											disabled={(pageNumber as number) <= 1}
										/>
										<TablePage>{pageNumber}</TablePage>
										<TableNextPage
											onClick={() => pageChange('next')}
											disabled={data?.length < 15}
										/>
									</div>
								</td>
							</tr>
						)}
						{!isFetching && data?.length < 1 && (
							<tr>
								<td colSpan={20}>
									<EmptyData title={t('No results match your search')} />
								</td>
							</tr>
						)}
					</tfoot>
				</Table>
			</TableContainer>
		</section>
	);
}

export default ImportsHistory;
