import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CardTitle, notify } from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPost } from 'api/API';
import DragDrop from 'components/DragDrop/DragDrop';

const menuItems = [
	{
		label: 'Accueil',
		link: '#home',
		linkType: 'InternalLink',
		order: 1,
	},
	{
		label: 'À propos du docteur',
		link: '#about',
		linkType: 'InternalLink',
		order: 2,
	},
	{
		label: 'Cursus professionnel',
		link: '#cursus',
		linkType: 'InternalLink',
		order: 3,
	},
	{
		label: 'Soins et actes',
		link: '#careServices',
		linkType: 'InternalLink',
		order: 4,
	},
	{
		label: 'Images du cabinet',
		link: '#pictures',
		linkType: 'InternalLink',
		order: 5,
	},
	{
		label: 'Vidéothèque',
		link: '#videos',
		linkType: 'InternalLink',
		order: 6,
	},
	{
		label: 'Détails d’accès',
		link: '#details',
		linkType: 'InternalLink',
		order: 7,
	},
	// {
	//     label: "Prendre RDV",
	//     link: "#appointments",
	//     linkType: "InternalLink",
	//     order: 8
	// },
	{
		label: 'Avant/Après',
		link: '#transformations',
		linkType: 'InternalLink',
		order: 9,
	},
];

function CustomReportdnd() {
	const [selectedItems, setSelectedItems] = useState({
		selected: [],
		menuItems: [],
	});
	const [isFetching, setIsFetching] = useState(true);
	const isSubmitting = useRef(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (isFetching) {
			axiosGet('displayed-fields/events').then((displayed) => {
				const displayedFields = displayed?.data.map((ele) => {
					if (ele.fieldName === 'Gestionnaire' || ele.fieldName === "Objectif de l'événement")
						ele.lock = true;
					return ele;
				});

				axiosGet(`events/fields`).then((res) => {
					const fields = res?.data?.filter(
						(ele) => displayedFields?.find((f) => ele.id === f.id)?.id !== ele.id
					);
					setSelectedItems({
						selected: displayedFields,
						menuItems: fields,
						dataIsChanged: true,
					});
					setIsFetching(false);
				});
			});
		}
	}, [isFetching]);

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!isSubmitting.current) {
			isSubmitting.current = true;
			const data = {
				fields: selectedItems.selected.map((ele) => ele.id),
			};
			axiosPost(`displayed-fields/events`, data).then((res, err) => {
				if (res?.data) {
					notify({
						position: 'top-center',
						type: 'success',
						msg: 'Les modifications sont enregistrées avec succès',
						delay: 5000,
					});
				} else {
					notify({
						position: 'top-center',
						type: 'danger',
						msg: `Les modifications ne sont pas enregistrées, ${res?.response?.data?.message}`,
						delay: 5000,
					});
					isSubmitting.current = false;
				}
			});
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<div className="labs-settings-btns">
				<Button type="submit">{t('Save')}</Button>
			</div>
			<CardTitle>Composition compte rendu</CardTitle>
			<DragDrop
				firstTable={selectedItems.menuItems}
				secondTable={selectedItems.selected}
				setSelectedItems={setSelectedItems}
			/>
		</form>
	);
}

export default CustomReportdnd;
