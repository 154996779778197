import {
	Button,
	EmptyData,
	IconButton,
	Table,
	TableHeader,
	TableHeaderTitle,
	Th,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';
import React, { useEffect, useState } from 'react';
import DelegateAddPopUp from './DelegateAddPopUp';
import { axiosDelete, axiosGet } from 'api/API';

const subRegions = [
	{ id: 1, name: 'wersdf' },
	{ id: 2, name: 'dsf' },
	{ id: 3, name: 'twesd sdf' },
	{ id: 4, name: 'wtwer' },
	{ id: 5, name: 'sdf try' },
	{ id: 6, name: 'sfsw' },
];

const DelegateList = () => {
	const [open, setOpen] = useState(false);
	const [curr, setCurr] = useState<any>(null);
	const [data, setData] = useState<any[]>([]);
	// const [assignedUsers, setAssignedUsers] = useState<any[]>([]);
	const [isFetching, setIsFetching] = useState(true);

	useEffect(() => {
		if (isFetching) {
			axiosGet(`delegates`).then((res: any) => {
				setData(res?.data?.delegates);
				setIsFetching(false);
			});
		}
	}, [isFetching]);

	const handleDelete = (delegate: any) => {
		axiosDelete(`delegates/${delegate?.id}`).then((res: any) => {
			if (!res?.data)
				notify({
					position: 'top-center',
					type: 'danger',
					msg: res?.response?.data?.message,
					delay: 5000,
				});
			setIsFetching(true);
		});
	};

	return (
		<>
			<div className="labs-settings-btns">
				<Button
					onClick={() => {
						setOpen(true);
						setCurr(null);
					}}
				>
					Nouveau délégué
				</Button>
			</div>
			<TableHeader>
				<TableHeaderTitle>Délégués</TableHeaderTitle>
			</TableHeader>
			<Table>
				<thead>
					<tr>
						<Th hideOrder>Prénom</Th>
						<Th hideOrder>Nom</Th>

						<Th hideOrder>Secteurs affectés</Th>
						<Th hideOrder></Th>
					</tr>
				</thead>
				<tbody>
					{!isFetching &&
						data?.length > 0 &&
						data?.map((ele) => (
							<tr key={ele?.id}>
								<td>{ele?.delegate?.firstName ?? '--'}</td>
								<td>{ele?.delegate?.lastName ?? '--'}</td>
								{/* <td>{ele?.salesCommission ? +ele?.salesCommission + '%' : '--'}</td> */}
								<td>
									{ele?.subRegions ? ele?.subRegions?.map((sub: any) => sub.name).join(', ') : '--'}
								</td>
								<td style={{ width: '5%' }}>
									<div style={{ display: 'flex' }}>
										<IconButton
											color="tertiary"
											size="sm"
											onClick={() => {
												setCurr(ele);
												setOpen(true);
											}}
										>
											<svg
												width="20"
												height="21"
												viewBox="0 0 20 21"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M12.6935 4.8016L15.6398 7.74788M13.9435 3.5516C14.7571 2.73801 16.0762 2.73801 16.8898 3.5516C17.7034 4.36519 17.7034 5.68429 16.8898 6.49788L5.41673 17.971H2.50006V14.9951L13.9435 3.5516Z"
													stroke="#292929"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</IconButton>
										<IconButton
											style={{ marginLeft: 10 }}
											color="tertiary"
											size="sm"
											onClick={() => handleDelete(ele)}
										>
											<svg
												width="20"
												height="21"
												viewBox="0 0 20 21"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M15.8333 6.27572L15.1106 16.3945C15.0483 17.2666 14.3225 17.9424 13.4481 17.9424H6.55186C5.67746 17.9424 4.95173 17.2666 4.88943 16.3945L4.16666 6.27572M8.33333 9.60905V14.609M11.6667 9.60905V14.609M12.5 6.27572V3.77572C12.5 3.31548 12.1269 2.94238 11.6667 2.94238H8.33333C7.87309 2.94238 7.49999 3.31548 7.49999 3.77572V6.27572M3.33333 6.27572H16.6667"
													stroke="#E2515A"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</IconButton>
									</div>
								</td>
							</tr>
						))}
				</tbody>
				<tfoot>
					{/* {data?.length > 0 && (
						<tr>
							<td colSpan={10}>
								<div className="sob-v2-table-pagination">
									<TablePreviousPage onClick={prevHandler} disabled={page <= 1} />
									<TablePage>{page}</TablePage>
									<TableNextPage onClick={nextHandler} disabled={data?.length < 15} />
								</div>
							</td>
						</tr>
					)} */}
					{!isFetching && data?.length <= 0 && (
						<tr>
							<td colSpan={20}>
								<EmptyData
									style={{
										minHeight: 300,
									}}
									title="Aucun résultat trouvé pour l'instant"
									desc="Veuillez réessayer plus tard"
								/>
							</td>
						</tr>
					)}
				</tfoot>
			</Table>
			{open && (
				<DelegateAddPopUp
					setOpen={setOpen}
					currentDelegate={curr}
					setIsFetching={setIsFetching}
					assignedUsers={data?.map((ele) => ele?.delegate?.id)}
				/>
			)}
		</>
	);
};

export default DelegateList;
