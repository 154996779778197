import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sobrus-com/sobrus-design-system-v2';

import './InputPopUp.scss';

function InputPopUp({ answerCallback, isOpen, setIsOpen, title, hideHeader, removeMargin, children }) {
	const { t } = useTranslation();

	return (
		<Modal
			className={`inputPopUp-container${removeMargin ? ' inputPopUp-removeMargin' : ''}`}
			setOpen={() => setIsOpen(false)}
			isOpen={isOpen}
			hideCloseBtn={hideHeader}
			style={{ marginTop: 0 }}
		>
			{!hideHeader && <ModalHeader setOpen={() => setIsOpen(false)} title={title} />}
			<ModalBody onScrollCapture={() => answerCallback}>{children}</ModalBody>
			<ModalFooter>
				<Button onClick={() => setIsOpen(false)} color="danger-tertiary" outline>
					{t('Cancel')}
				</Button>
				<Button
					onClick={() => {
						setIsOpen(false);
						answerCallback();
					}}
					color="primary"
					style={{ margin: '15px 0' }}
				>
					{t('Validate')}
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default InputPopUp;
