/* eslint-disable comma-dangle */
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppBar,
	Button,
	Card,
	CheckBox,
	FormGroup,
	FormStep,
	FormSteps,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	notify,
	Tab,
	TabPanel,
	Tabs,
	TabsContainer,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPatch, axiosPost } from 'api/API';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import * as yup from 'yup';

import AccountsTable from './AccountsTable';
import ContactsTable from './ContactsTable';

export interface defaultValues {
	override: boolean;
	accounts: {
		name: string;
		categoryId: number | null;
		city: string | null;
		administratorId: number | null;
		resourceId: { value: number; label: string } | null;
		type: string;
		code: string;
		ref: string | number;
	}[];
	contacts: {
		ref: string;
		firstName: string;
		lastName: string;
		title: string;
		email: string;
		mobile: string;
		position: string;
		service: string;
		categoryId: number | null;
	}[];
}

const ImportAccountsStep3 = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState<any>(null);
	const [users, setUsers] = useState<any[] | null>(null);
	const [categories, setCategories] = useState<any[] | null>(null);
	const [contactCategories, setContactCategories] = useState<any[] | null>(null);
	const [ressources, setRessources] = useState<any[] | null>(null);
	const [open, setOpen] = useState(false);
	const [existAccounts, setExistAccounts] = useState(0);

	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const { state }: any = useLocation();
	const {
		reset,
		getValues,
		setValue,
		watch,
		control,
		formState: { errors },
		handleSubmit,
	} = useForm<defaultValues>({
		resolver: yupResolver(
			yup.object({
				accounts: yup.array().of(
					yup.object().shape({
						name: yup.string().required(),
						code: yup.string().required(),
						resourceId: yup.object().required(),
					})
				),
				contacts: yup.array().of(
					yup.object().shape({
						firstName: yup.string().required(),
						lastName: yup.string().required(),
					})
				),
			})
		) as any,
		// .required(t('This field is required')!)
		defaultValues: {
			override: undefined,
			accounts: state?.data?.details?.accounts?.map((acc: any) => ({
				...acc,
				resourceId: acc?.resourceId ? { value: acc.resourceId, label: acc?.resourceName } : null,
			})),
			contacts: state?.data?.details?.contacts?.map((cont: any) => ({
				...cont,
				resourceId: cont?.resourceId ? { value: cont.resourceId, label: cont?.resourceName } : null,
			})),
		},
	});

	const { fields, replace, remove } = useFieldArray({
		control,
		name: 'accounts',
	});

	const { fields: contactFields, replace: replaceContact } = useFieldArray({
		control,
		name: 'contacts',
	});

	const watchFieldArray = watch('accounts');
	const accountsFields = fields.map((field, index) => {
		return {
			...field,
			...watchFieldArray[index],
			index,
		};
	});

	const watchContactArray = watch('contacts');
	const contactsFields = contactFields.map((field, index) => {
		return {
			...field,
			...watchContactArray[index],
			index,
		};
	});

	useEffect(() => {
		if (!data && !state) {
			axiosGet(`accounts/imports/${id}`).then((res: any) => {
				setData(res?.data);
				reset({
					accounts: res?.data?.details?.accounts?.map((acc: any) => ({
						...acc,
						resourceId: acc?.resourceId ? { value: acc.resourceId, label: acc?.resourceName } : null,
					})),
					contacts: res?.data?.details?.contacts?.map((cont: any) => ({
						...cont,
						resourceId: cont?.resourceId ? { value: cont.resourceId, label: cont?.resourceName } : null,
					})),
				});
			});
		}
	}, []);

	useEffect(() => {
		if (!contactCategories) {
			axiosGet(`contacts/categories`).then((res: any) => {
				setContactCategories(res?.data);
			});
		}
	}, []);

	useEffect(() => {
		if (!categories) {
			axiosGet(`accounts/categories`).then((res: any) => {
				setCategories(res?.data);
			});
		}
	}, []);

	useEffect(() => {
		if (!users) {
			axiosGet(`users?limit=10000`).then((res: any) => {
				setUsers(res?.data?.users);
			});
		}
	}, []);

	useEffect(() => {
		axiosGet(`pharmacies?limit=20`).then((res: any) => {
			setRessources(res?.data?.response);
		});
	}, []);

	const onSubmit = async (values: defaultValues, shouldIgnore?: boolean) => {
		if (!shouldIgnore) {
			let existAccounts = false;
			await axiosPost('accounts/mapping/check', {
				accounts: values.accounts.map((acc) => ({
					resourceId: acc?.resourceId?.value,
					code: acc?.code,
				})),
			}).then((res: any) => {
				if (res?.data?.mappedAccounts > 0) {
					setExistAccounts(res.data?.mappedAccounts);
					existAccounts = true;
				}
			});
			if (existAccounts) {
				setOpen(true);
				return false;
			}
		}

		const submitData = {
			override: values.override,
			accounts: values.accounts.map((acc) => ({
				name: acc?.name,
				categoryId: acc?.categoryId,
				administratorId: acc?.administratorId,
				resourceId: acc?.resourceId?.value,
				type: acc?.type,
				code: acc?.code,
				ref: acc?.ref,
			})),
			contacts: values.contacts.map((cont) => ({
				ref: cont?.ref,
				firstName: cont?.firstName,
				lastName: cont?.lastName,
				title: cont?.title,
				email: cont?.email,
				mobile: cont?.mobile,
				position: cont?.position,
				service: cont?.service,
				categoryId: cont?.categoryId,
			})),
		};
		console.log('submitData', submitData);
		if (!submitting) {
			setSubmitting(true);
			axiosPost(`accounts/imports/${id}/mapping`, submitData).then((res: any) => {
				if (res?.data) {
					navigate(`/accounts/import/results/${id}`, { state: res?.data });
				} else {
					notify({ type: 'danger', msg: res?.response?.data?.message });
				}
				setSubmitting(false);
			});
		}
	};

	const onError = (values: any) => {
		axiosPost('accounts/mapping/check', {
			accounts: getValues('accounts')
				.filter((acc) => acc?.resourceId?.value)
				.map((acc) => ({
					resourceId: acc?.resourceId?.value,
					code: acc?.code,
				})),
		}).then((res: any) => {
			if (res?.data) {
				setExistAccounts(res.data?.mappedAccounts);
			}
			setOpen(true);
		});
	};

	const handleReset = () => {
		axiosPatch(`accounts/imports/${id}/reset`).then((res: any) => {
			navigate('/accounts/import/step1');
		});
	};

	console.log('errors', errors);

	return (
		<>
			<div className="labs-header">
				<BackwardTitle title={t('Importer')} subTitle={t('Accounts')} to={-1} isBackward />
				<div className="labs-header-btns">
					<Button color="tertiary" onClick={() => handleReset()}>
						Réinitialiser
					</Button>
					<Button
						color="primary"
						onClick={() => handleSubmit(onSubmit as (values: defaultValues) => Promise<boolean>, onError)()}
					>
						Suivant
					</Button>
				</div>
			</div>
			<Card>
				<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
					<FormSteps style={{ width: 500 }}>
						<FormStep text="Upload" number={1} active succes />
						<FormStep text="Mapping" number={2} active succes />
						<FormStep text="Vérification" number={3} active last />
					</FormSteps>
				</div>
				<TabsContainer>
					<AppBar>
						<Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)} aria-label="simp">
							<Tab label="Liste des comptes à importer" index={0} />
							{contactsFields?.length > 0 && <Tab label="Liste des contacts à importer" index={1} />}
						</Tabs>
					</AppBar>
					<TabPanel value={tabIndex} index={0}>
						<AccountsTable
							control={control}
							remove={remove}
							accountsFields={accountsFields}
							categories={categories}
							users={users}
							ressources={ressources}
						/>
					</TabPanel>
					{contactsFields?.length > 0 && (
						<TabPanel value={tabIndex} index={1}>
							<ContactsTable
								control={control}
								contactsFields={contactsFields}
								contactCategories={contactCategories}
							/>
						</TabPanel>
					)}
				</TabsContainer>
				{open && (
					<Modal setOpen={() => setOpen(false)} size="md">
						{/* <ModalHeader setOpen={setOpen} hideCloseBtn title="Confirmation de l'importation" /> */}
						<ModalBody
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<h2
								style={{
									fontSize: 18,
									fontWeight: 600,
									margin: '0px 0 8px',
								}}
							>
								Confirmation de l&apos;importation
							</h2>
							<ul style={{ margin: 0 }}>
								{errors?.accounts && Object.keys(errors?.accounts)?.length > 0 && (
									<li>
										<strong>{Object.keys(errors?.accounts)?.length ?? 0}</strong> comptes non mappés
									</li>
								)}
								{existAccounts > 0 && (
									<li>
										<strong>{existAccounts}</strong> comptes déjà existants
									</li>
								)}
							</ul>
							{existAccounts > 0 && (
								<FormGroup style={{ marginTop: 28, paddingBottom: 0 }}>
									<Controller
										control={control}
										name="override"
										render={({ field: { value, onChange } }) => (
											<CheckBox
												label="Réimporter et remplacer les comptes déjà existants"
												checked={value}
												onChange={onChange}
											/>
										)}
									/>
								</FormGroup>
							)}
						</ModalBody>
						<ModalFooter>
							<Button color="tertiary" onClick={() => setOpen(false)} loading={submitting}>
								Annuler
							</Button>
							<Button
								color="primary"
								onClick={() => {
									onSubmit(getValues(), true);
								}}
								loading={submitting}
							>
								Continuer
							</Button>
						</ModalFooter>
					</Modal>
				)}
			</Card>
		</>
	);
};

export default ImportAccountsStep3;
