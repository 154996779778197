/* eslint-disable @typescript-eslint/no-var-requires */
import {
	Button,
	Card,
	CardItem,
	CardTitle,
	Col,
	Container,
	Row,
	Tag,
	notify,
} from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import React, { useEffect } from 'react';
import { useOffer } from './OrderAdd';
import { format, parseISO } from 'date-fns';
import payMethodsTranslator from 'utils/paymentMethods';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import { useLocation, useNavigate } from 'react-router';
import { errorHandler } from '@sobrus-com/sobrus-marketplace-helper-package';
import OrderProducts from './OrderProducts';

const OrderAddStep1 = () => {
	const { offer, setOffer } = useOffer();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { search } = useLocation();
	console.log('first', search);

	const handleSubmit = () => {
		const { errors, errorIds, errorPacks } = errorHandler(offer);
		setOffer({ ...offer });
		console.log('errors', errorPacks);
		if (errors.length > 0 || errorIds?.length > 0 || errorPacks?.length > 0) {
			if (errorIds?.length > 0)
				errorIds.forEach((prod, indx) => {
					const prodElem = document.getElementById(`prod-${prod}`);
					if (prodElem) prodElem.scrollIntoView({ behavior: 'smooth', block: 'center' });
				});
			else if (errorPacks?.length > 0) {
				errorPacks.forEach((pack) => {
					const packElem = document.getElementById(`pack-${pack}`);
					if (packElem) packElem.scrollIntoView({ behavior: 'smooth', block: 'center' });
				});
			}
			errors.map(
				(el) =>
					el === 'code2' &&
					notify({
						type: 'warning',
						msg: "Vous n'avez pas encore atteint le palier globale minimum, veuillez réessayer.",
						delay: 5000,
					})
				// notify({
				// 	type: 'warning',
				// 	msg:
				// 		el === 'code1'
				// 			? 'Veuillez ajouter les produits obligatoires avec leur palier minimum.'
				// 			: el === 'code2'
				// 				? "Vous n'avez pas encore atteint le palier globale minimum, veuillez réessayer."
				// 				: el === 'code3'
				// 					? 'Veuillez atteindre la quantité minimale des produits commandés.'
				// 					: 'Quantité minimum du pack non atteinte.',
				// 	delay: 5000,
				// })
			);
		} else navigate('/orders/create/step2' + search);
	};

	return (
		<>
			<div className="labs-header">
				<BackwardTitle to="/orders" title="Nouveau bon de commande" subTitle="Ventes" isBackward />
			</div>
			<Card>
				<Container>
					<Row>
						<Col xs="4">
							<CardItem style={{ marginBottom: 0 }}>
								<img
									src={
										offer?.imageRef
											? `${offer.imageRef}?width=1280&format=webp`
											: require('assets/product-img-placeholder.svg').default
									}
									style={{
										maxHeight: '400px',
										width: '100%',
										height: '100%',
										objectFit: 'contain',
										borderRadius: '12px',
									}}
									alt=""
								/>
							</CardItem>
							<OrderTimer deadline={offer?.deadline} />
						</Col>
						<Col>
							<Container>
								<Row>
									<Col>
										<CardTitle>
											<div>
												{offer?.name ?? '-'}
												<div style={{ fontSize: 14, fontWeight: 500 }}>
													Expire le :{' '}
													{offer?.deadline
														? format(parseISO(offer?.deadline), 'yyyy-MM-dd')
														: '-'}
												</div>
											</div>
										</CardTitle>
									</Col>
									<Col style={{ justifySelf: 'flex-end', flexGrow: 0 }}>
										<Tag size="sm" bgColor="#EEF2F7" textColor="#2B4660">
											Fournisseur : {offer?.supplier?.name ?? '-'}
										</Tag>
									</Col>
								</Row>
								<hr style={{ margin: '8px -12px 16px', opacity: 0.5 }} />
							</Container>
							<Container>
								<Row>
									<Col>
										<CardItem label={t('Payment Methods')}>
											{offer?.acceptedPaymentMethods?.map((pay: any) => (
												<Tag
													key={pay?.id}
													bgColor="#E2EFFC"
													textColor="#123E68"
													size="sm"
													minWidth={20}
													style={{ marginRight: 5, marginBottom: 5 }}
												>
													{pay.label}
												</Tag>
											))}
										</CardItem>
									</Col>
									<Col>
										<CardItem label={t('Payment Terms')}>
											{offer?.paymentTimeframe
												? offer?.paymentTimeframe > 0
													? `Règlement Classique avec délai : ${offer?.paymentTimeframe} ${
															offer?.paymentTimeframeUnit
																? JSON.parse(offer?.paymentTimeframeUnit)?.label
																: ''
														}`
													: 'Règlement immédiat à la livraison'
												: '--'}
										</CardItem>
									</Col>
								</Row>
								<hr style={{ margin: '8px -12px 16px', opacity: 0.5 }} />
								<Row>
									<Col>
										<CardItem label={t('Delivery Terms')}>
											{offer?.deliveryTimeframe
												? `${offer?.deliveryTimeframe} ${
														offer?.deliveryTimeframeUnit
															? JSON.parse(offer?.deliveryTimeframeUnit)?.label
															: '--'
													}`
												: '--'}
										</CardItem>
									</Col>
									<Col>
										<CardItem label="Transporteurs">{offer?.deliveryTransporter ?? '--'}</CardItem>
									</Col>
								</Row>
								<hr style={{ margin: '8px -12px 16px', opacity: 0.5 }} />
								<Row>
									<Col>
										<CardItem label="Qté min globale">
											{offer?.minQuantity ? offer?.minQuantity + ' unités' : 'Pas de minimum'}
										</CardItem>
									</Col>
									<Col>
										<CardItem label="Montant min global">
											{offer?.minAmount ? offer?.minAmount + ' Dhs' : 'Pas de minimum'}
										</CardItem>
									</Col>
								</Row>
								<hr style={{ margin: '8px -12px 16px', opacity: 0.5 }} />
								<Row>
									<Col>
										<CardItem label="Paliers globaux">
											{offer?.globalDiscountThresholds?.length > 0
												? offer?.globalDiscountThresholds.map((ele: any, idx: number) => (
														<Tag
															key={idx}
															bgColor="#E2EFFC"
															textColor="#123E68"
															size="sm"
															minWidth={20}
															style={{ marginLeft: idx > 0 ? 5 : 0, marginTop: 5 }}
														>
															{`${ele?.minQuantity ? ele?.minQuantity + ' U' : ''}`}
															{ele?.minQuantity && ele?.minAmount ? ', ' : ''}
															{ele?.minAmount && +ele?.minAmount > 0
																? ele?.minAmount + ' Dhs'
																: ''}{' '}
															= {ele?.discountValue}%
														</Tag>
													))
												: '--'}
										</CardItem>
									</Col>
								</Row>
								<Row>
									<Col>
										<CardItem label="Termes et modalités">
											{offer?.termsAndConditions ?? '=='}
										</CardItem>
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>
			</Card>
			<OrderProducts offer={offer} setOffer={setOffer} />
			<div className="order-info">
				<div className="order-info-card">
					<div className="order-info-card--title">Qté totale commandée</div>
					<div className="order-info-card--content">
						<strong>{offer?.totalQuantity ?? 0}</strong>
						{offer?.minQuantity && (
							<>
								{offer?.totalQuantity >= offer?.minQuantity ? (
									<div className="order-info-card--notice" style={{ backgroundColor: '#0EA45F' }}>
										Qté min atteinte
									</div>
								) : (
									<div className="order-info-card--notice">
										Reste {(offer?.minQuantity ?? 0) - (offer?.totalQuantity ?? 0)} U
									</div>
								)}
							</>
						)}
					</div>
				</div>
				<div className="order-info-card">
					<div className="order-info-card--title">Total avant remise</div>
					<div className="order-info-card--content">
						<strong>{offer?.totalPPH ?? 0}</strong>
					</div>
				</div>
				<div className="order-info-card">
					<div className="order-info-card--title">Total U.G</div>
					<div className="order-info-card--content">
						<strong>{offer?.totalFu ?? 0}</strong>
					</div>
				</div>
				<div className="order-info-card" style={{ color: '#25B8AA' }}>
					<div className="order-info-card--title">Montant économisé</div>
					<div className="order-info-card--content">
						<strong>
							{offer?.economie ?? 0} Dhs ({offer?.totalDiscount ? +offer?.totalDiscount : 0}%)
						</strong>
					</div>
				</div>
				<Button color="primary" onClick={() => handleSubmit()}>
					Total <strong>{offer?.totalAmount ?? 0} Dhs</strong> | Enregistrer
				</Button>
			</div>
		</>
	);
};

export default OrderAddStep1;

const OrderTimer = ({ deadline }: { deadline: string }) => {
	const { t } = useTranslation();
	const { seconds, minutes, hours, days, restart } = useTimer({
		expiryTimestamp: new Date(),
		// eslint-disable-next-line no-console
		onExpire: () => console.warn('onExpire called'),
	});
	useEffect(() => {
		if (deadline) {
			restart(parseISO(deadline), true);
		}
	}, [deadline]);

	function minTwoDigits(n: number) {
		return (n < 10 ? '0' : '') + n;
	}

	return (
		<div className="order-stopwatch">
			<div className="offer-time-card">
				<h1>{minTwoDigits(days)}</h1>
				<p>{t('Days')}</p>
			</div>
			<strong> : </strong>
			<div className="offer-time-card">
				<h1>{minTwoDigits(hours)}</h1>
				<p>{t('Hours')}</p>
			</div>
			<strong> : </strong>
			<div className="offer-time-card">
				<h1>{minTwoDigits(minutes)}</h1>
				<p>{t('Minutes')}</p>
			</div>
			<strong> : </strong>
			<div className="offer-time-card">
				<h1>{minTwoDigits(seconds)}</h1>
				<p>{t('Seconds')}</p>
			</div>
		</div>
	);
};
