import { axiosGet } from 'api/API';
import React, { useEffect, useState } from 'react';
import { useOutletContext, Outlet, useSearchParams } from 'react-router-dom';

type ContextType = { offer: any; setOffer: React.Dispatch<React.SetStateAction<any>> };

const OrderAdd = () => {
	const [offer, setOffer] = useState(null);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (!offer) {
			axiosGet(`offers/${searchParams.get('offerId')}`).then((res: any) => {
				setOffer(res?.data);
			});
		}
	}, []);

	return (
		<section className="labs-container order-creation" style={{ paddingBottom: 80 }}>
			<div>
				<Outlet context={{ offer, setOffer } satisfies ContextType} />
			</div>
		</section>
	);
};

export function useOffer() {
	return useOutletContext<ContextType>();
}

export default OrderAdd;
